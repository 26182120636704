import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getPartyMaster(partyMasterId) {
    let response = await NetworkService.getData(ApiUrl.getPartymasterUrl(partyMasterId));
    return response;
}

export async function getActiveFarms(farmsUUID, status) {
    let response = await NetworkService.getData(ApiUrl.getActiveFarmsUrl(farmsUUID, status));
    return response;
}