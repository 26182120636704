import * as types from './actionTypes';

export const saveTermsAndVersion = (docContent, docVersion) => {
  return { type: types.SAVE_TERMS_CONTENT_VERSION, payload: { docContent, docVersion } }
}

export const saveRulesAndVersion = (docContent, docVersion) => {
    return { type: types.SAVE_RULES_CONTENT_VERSION, payload: { docContent, docVersion } }
}
export const saveTermsAndConditionsCheck = (checked) => {
  return { type: types.SAVE_TERMS_CONDITIONS_CHECK, checked }
}