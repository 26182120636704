import React from "react";
import CheckboxLabels from "../components/RulesAndEngagement/rulesAndEngagementCheckBox";
import { useHistory } from 'react-router-dom';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { postUserConsent } from "../common/services/userconsent.service";
import PublicLogoBoxes from "../components/PublicLogoBoxes/PublicLogoBoxes";
import { useSelector } from "react-redux";
import { handleError } from '../utils/errorUtil';
import { validateToken } from "../utils/tokenUtil";

//Todo: We need to create a commont component which can be used on term and condition and rules of engagement page 
const RulesandEngagementPage = () => {
  const history = useHistory();
  const lastModificationByUserIdentifier = useSelector(state => state?.contacts?.id);
  const rawHtmlContent = useSelector(state => state?.termsRules?.rulesContent);
  const rawHtmlVersion = useSelector(state => state?.termsRules?.rulesVersion);
  const { userId } = validateToken();
  const isRulesAndEngagementChecked = useSelector(state => state?.rulesEngagement?.checked);
  const submit = () => {

    const userConsent = {
      "userConsent": {
        "identifier": "",
        "identifierSet": { "identifier": userId },
        "effectiveTimePeriod": { "startDateTime": "" },
        "usageConsentIndicator": { "content": true, "typeCode": "isAccepted" },
        "title": "Marketplace Rules of Engagement", "systemIdentifier": "Marketplace",
        "versionIdentifier": rawHtmlVersion,
        "lastModificationByUserIdentifier": lastModificationByUserIdentifier,
        "lastModificationDateTime": ""
      }
    }

    postUserConsent(userConsent).then((response) => {
      if (isRulesAndEngagementChecked) {
        history.push('/home-page');
      }
    }).catch(error => {
      handleError(error, history);
    })
  }
  const handleBackClick =async () => {
    localStorage.clear()
    await  history.push('/auth')

  }
  return (
    <div className="public-view">
      <PublicLogoBoxes />
      <div className="content term-and-condition">
        <h1>Rules of Engagement</h1>
        <Box className="box-container">
          <div className="login-legal-container">
            <div dangerouslySetInnerHTML={{ __html: rawHtmlContent }}></div>
            <Box className="legal-acceptance-box">
              <CheckboxLabels />
            </Box>
          </div>
        </Box>
        <Grid container className="form-button-container">
          <Grid item xs={11} sm={5}>
            <Button variant="contained" className="button" onClick={handleBackClick}>Back</Button>
          </Grid>
          <Grid item xs={11} sm={4}>
          </Grid>
          <Grid item xs={11} sm={2} className="form-button-container-right">
            <Button disabled={!isRulesAndEngagementChecked} variant="contained" className="button" onClick={submit}>Submit</Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default RulesandEngagementPage;