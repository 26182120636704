const transformFarmDetailsFromStore = (farmDetailsFromStore) => {
		return farmDetailsFromStore?.map(farm => {
			return {...farm,
				label: `${farm.data[0].partyMaster.party.identifier} - ${farm.farmname}`,
				value: farm.farmPartyID,
				currentbase: farm.currentbase,
				farmguid: farm.farmPartyID,
				farmName: farm.farmname,
				partyIdentifier: farm.partyIdentifier,
			};

		});
		
	};

export default transformFarmDetailsFromStore