import { ENVIRONMENT, REQUIRED_PERCENTILE_FOR_AVAILABLE_BASE } from '../common/constants/constants';

export const getEnvironment = () => {
    const host = window.location.host;
    let environment = '';
    if (host.indexOf('marketplace.landolakesinc.com') > -1) {
        environment = ENVIRONMENT.PROD;
    } else if (host.indexOf('marketplacedv.landolakesinc.com') > -1) {
        environment = ENVIRONMENT.DEV;
    } else if (host.indexOf('marketplaceqa.landolakesinc.com') > -1) {
        environment = ENVIRONMENT.QA;
    } else if (host.indexOf('webapp-marketplace-dv.azurewebsites.net') > -1){
        environment = ENVIRONMENT.DEV;
    } else if (host.indexOf('webapp-marketplace-qa.azurewebsites.net')> -1){
        environment = ENVIRONMENT.QA;
    } 
    else {
        environment = ENVIRONMENT.LOCAL;
    }
    return environment;
}


export const farmHasasRequiredPerenctileForAvilableBase = (availableBase, productQuantity) => {
    return productQuantity <= (availableBase * REQUIRED_PERCENTILE_FOR_AVAILABLE_BASE)
}
