import React from "react";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import PublicLogoBoxes from "../../PublicLogoBoxes/PublicLogoBoxes";

const SignInHelp = () => {
      return (      
            <div className="public-view">
                  <PublicLogoBoxes />

                  <div className="content sign-in-help">
                        <Button to="/auth" component={Link} className="back-to-login">
                              <i className="ag-icon ag-icon-chevron-left ag-icon-fw font-20"></i>Back to Sign In</Button>
                        <h1>Sign In Help</h1>
                        <p>If you don't know your username and password, it is the same as your BusinessLink account.</p>
                        
                        <div className="options">
                              <p> If you don't have an account, please register.</p>
                              <Button onClick={() => window.open("https://landolakesinc.force.com/ems/EMSSelfRegistrationPage", "_blank")}
                                    variant="contained"
                                    className="button"
                                    size="small">New User Registration</Button>
                        </div>
                        <div className="options">
                              <p> Need to look up your BusinessLink User ID?</p>
                              <Button onClick={() => window.open("https://credentials.lolportals.com/UserAccess/ForgotUsername", "_blank")}
                                    variant="contained"
                                    className="button"
                                    size="small">Forgot User ID</Button>
                        </div>
                        <div className="options">
                        <p> Forgot your password?</p>
                        <Button onClick={() => window.open("https://credentials.lolportals.com/UserAccess/ForgotPassword", "_blank")}
                              variant="contained"
                              className="button"
                              size="small">Forgot Password</Button>
                        </div>
                        <div className="options">
                        <p> Need to contact us?</p>
                        <Button
                              variant="contained"
                              className="button"
                              to="/contact-us-signin"
                              component={Link}
                              size="small">Contact Info</Button>
                        </div>
                  </div>
            </div>
      )

}
export default SignInHelp;

