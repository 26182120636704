import axios from 'axios';
import { appConstants } from '../constants/constants';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});


let authorizationHeader = '';

const onSuccess = (res) => Promise.resolve(res.data);
const onError = (response) => Promise.reject(response);

httpClient.interceptors.request.use((request) => {
  let token="";
  if(localStorage.getItem(appConstants.LoginSessionKey) && localStorage.getItem(appConstants.LoginSessionKey).length !== 0)
  {
    token=localStorage.getItem(appConstants.LoginSessionKey).replace(/^"(.*)"$/, '$1');
  }
  request.headers.Authorization= "bearer " + token;
  if (request.options && request.options['iscontentSet']) {
    delete request.headers['Content-Type'];
  } else if (request.options) {
    request.headers['Content-Type'] = request.options && request.options['contentType'];
  }
  return request;
});

httpClient.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const { config: originalReq, response } = error;
  if (!originalReq.isRetryAttempt && response && response.status === 401) {
    throw error;
  } else {
    throw error;
  }
});

httpClient.interceptors.response.use(onSuccess, onError);

export default {
  getData: (url, options, responseType, queryParams) =>
    httpClient({
      method: "GET",
      url: url,
      params: queryParams,
      responseType: responseType
    }),
  postData: (url, requestBody, options) =>
    httpClient({
      method: "POST",
      url: url,
      data: requestBody,
      options: options,
    }),
  searchData: (url, requestBody, options) =>
    httpClient({
      method: "SEARCH",
      url: url,
      data: requestBody,
    }),
  putData: (url,requestBody,queryParams) =>
    httpClient({
      method: "PUT",
      url: url,
      data: requestBody,
      params: queryParams      
    }),

  /**
   * Method will set authorization header 
   * @param {*} requestToken 
   */
   setAccessTokenAuthHeader: (requestToken) => {
    if (requestToken) {
      authorizationHeader = "bearer " + requestToken;
    }
  },

  /**
   * Set id_token in request headers.
   * @param {*} requestToken 
   */
  setIdTokenAuthHeader: (requestToken) => {
    if (requestToken && requestToken.id_token) {
      authorizationHeader = "bearer " + requestToken.id_token;
    }
  },
  deleteData: (url, responseType, queryParams) =>
    httpClient({
      method: "DELETE",
      url: url,
      params: queryParams,
      responseType: responseType
    }),
};