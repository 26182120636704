import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js';

const TermsAndConditionsSidebar = () => {
  const { document, isUserError, isApiError } = useDocument("Marketplace Terms and Conditions");
  
  if (isUserError) {
    return <Redirect to={{ pathname: '/auth' }} />
  }

  if (isApiError) {
    return (      
        <section className="content">
          <div>Unable to retreive information,please try again</div>
        </section>      
    )
  }

  return (    
      <section className="content page-terms-conditions">
        <h1>Terms and Conditions</h1>
         {
            document?.date ? <div>
              <p><i>{'Agreed on ' + document.date}</i></p>
              <div dangerouslySetInnerHTML={{ __html: document.content }}></div>
              </div> : 
            <div> Loading... </div>
         }
         
      </section>    
  )
}

export default TermsAndConditionsSidebar;