import { SSO_BASE_PATH, LOGOUT_REDIRECT_URI, ENVIRONMENT } from '../constants/constants';
import { getEnvironment } from '../../utils'
const { REACT_APP_SSO_CLIENTID } = process.env;
const AUTH_CONFIG = {
	AUTHORIZATION: `${SSO_BASE_PATH()}/as/authorization.oauth2`,
	AUTH_TOKEN_URL: `${SSO_BASE_PATH()}/as/token.oauth2`,
	LOGOUT: `${SSO_BASE_PATH()}/idp/startSLO.ping?TargetResource=${LOGOUT_REDIRECT_URI()}`,
	CLIENT_ID: REACT_APP_SSO_CLIENTID,
	GRANT_TYPE: 'authorization_code',
	RESPONSE_TYPE: 'code',
	SCOPE: 'openid',
	CLIENT_SECRET: '',
	REDIRECT_URI_DEV: 'https://webapp-marketplace-dv.azurewebsites.net/auth',
	REDIRECT_URI_QA: 'https://webapp-marketplace-qa.azurewebsites.net/auth',
	REDIRECT_URI_PROD: 'https://marketplace.landolakesinc.com/auth',
	REDIRECT_URI_LOCAL: 'http://localhost:3000/auth',
};

const ENV = getEnvironment();
let enviromenturl = '';
if (ENV === ENVIRONMENT.PROD) {
	enviromenturl = 'https://api.digitalag.farm/';
} else if (ENV === ENVIRONMENT.QA) {
	enviromenturl = 'https://apiqa.digitalag.farm/';
} else if (ENV === ENVIRONMENT.DEV) {
	enviromenturl = 'https://apidv.digitalag.farm/';
} else {
	enviromenturl = 'https://apidv.digitalag.farm/';
}

const APP_BASE_URL = enviromenturl;

export { AUTH_CONFIG, APP_BASE_URL };
