export const formfields = {
  farm_name: '',
  amount_to_transfer: '',
  split_transfer: false,
  proposed_transfer_date: new Date(),
  primary_contact: '',
  type_code: '',
  phone_number: '',
  email: '',
  other_contact: '',
  other_phone_number: '',
  other_email: '',
  accept_term: false,
  milkshed: '',
  status: ''
}