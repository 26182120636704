import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string';
import { getEnvironment } from '../utils';
import { AUTH_CONFIG } from '../common/configs/auth.config';
import { ENVIRONMENT, appConstants } from '../common/constants/constants';
import NetworkService from '../common/services/networkService';
import { getTokenId } from '../common/services/login.service';
import { validateToken } from '../utils/tokenUtil';
import {
	saveUserId,
	saveUserName
} from "../store/actions/userActions";
import { getContact } from "../common/services/contact.service";
import { getActiveFarms } from "../common/services/partyMaster.service";
import { getContactFarmAuthorizations } from "../common/services/contact.service";
import { getUserConsent } from "../common/services/userconsent.service";
import { getRequiredDocuments } from "../common/services/dynamicPages.service";
import { saveContacts } from "../store/actions/contactsAction";
import saveFarmWithGUID from "../store/actions/farmWithGUIDAction";
import {
	saveTermsAndVersion,
	saveRulesAndVersion
} from "../store/actions/termsAndRulesActions";
import { saveRefreshLastDateTime } from '../store/actions/refreshDataAction';
import { handleError } from "../utils/errorUtil";
import { appInsights } from '../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { diffInMinutes } from '../utils/dateUtil';
import { TIME_TO_REFRESH } from './HooksConstants';

import { getListingsValidations } from '../common/services/getValidations';

import * as types from '../store/actions/actionTypes';

export const usePopulateData = ({ searchStr }) => {

	const [loading, setLoading] = useState(true);
	const [errorMsg, setErrorMsg] = useState('');
	const [pathToRoute, setPathToRoute] = useState('');

	const history = useHistory();
	const dispatch = useDispatch();
	const farmDetailsFromStore =
		useSelector(state => state?.farmDetails?.farmDetails) || [];
	const lastRefreshDateTime =
		useSelector(state => state?.lastRefreshDateTime?.lastRefreshDateTime) ||
		new Date();
	const populateData = userId => {
		let contactID;
		let routeToPath = '';
		dispatch(saveRefreshLastDateTime(new Date()));
	
		getContact(userId)
			.then(contacts => {
				if (contacts.length === 0) {
					localStorage.clear();
					return history.push('/authorize');
				}
				appInsights.trackException({
					usrId: userId,
					userName: contacts[0]?.contact?.personName?.formattedName,
				});
				contactID = contacts[0]?.contact?.identifier;
				dispatch(saveContacts(contacts[0]));
				dispatch(saveUserName(contacts[0]?.contact?.personName?.formattedName));
				getContactFarmAuthorizations(contactID)
					.then(farmsWithGUID => {
						let farms = farmsWithGUID[0];
						if (farms.length === 0) {
							localStorage.clear();
							return history.push('/authorize');
						}
						dispatch(saveFarmWithGUID(farmsWithGUID));
						const authorizedFarms = farmsWithGUID[0].filter((farm) => farm.authorized).map((authorizedFarm) => {
							return authorizedFarm.identifier.content;
						  });
						getActiveFarms(authorizedFarms, 'true').then
						((response) => {
							if (response?.length < 1) {
								localStorage.clear();
								return history.push('/authorize');
							}
						})
						getUserConsent(userId)
							.then(userconsentresponse => {
								let termssign = false;
								let rulessign = false;
								getRequiredDocuments(userconsentresponse)
									.then(docsresponse => {
										docsresponse.forEach(doc => {
											if (doc.title === 'Marketplace Terms and Conditions') {
												termssign = true;
												dispatch(
													saveTermsAndVersion(
														doc.content,
														doc.versionIdentifier
													)
												);
											} else if (
												doc.title === 'Marketplace Rules of Engagement'
											) {
												rulessign = true;
												dispatch(
													saveRulesAndVersion(
														doc.content,
														doc.versionIdentifier
													)
												);
											}
										});

										if (termssign) {
											routeToPath = '/term-and-condition';
										} else if (rulessign) {
											routeToPath = '/rules-engagement';
										} else {
											routeToPath = '/home-page';
										}
									})
									.catch(error => {
										handleError(error, history);
										setLoading(false);
									})
									.finally(() => {
										setPathToRoute(routeToPath);
										setLoading(false);
									});
							}) //end of userconsent
							.catch(error => {
								handleError(error, history);
								setLoading(false);
							}); //end of userconsent catch
					}) //end of farmauthorizations
					.catch(error => {
						handleError(error, history);
						setLoading(false);
					}); //end of farmauthorizations catch
			}) //end of contact
			.catch(error => {
				appInsights.trackException({
					error: new Error(error),
					severityLevel: SeverityLevel.Error,
				});
				
				handleError(error, history);
				localStorage.clear();
				setLoading(false);
				return history.push('/authorize');
				
			}); //end of contact catch
	};


	/**
	 * Method will return auth code coming from server.
	 * @param {*} props
	 */
	const getAuthCode = searchStr => {
		if (searchStr) {
			let params = queryString.parse(searchStr);
			return params.code;
		}
	};

	/**
	 * SSO login
	 */
	const ssoLogin = () => {
		const ENV = getEnvironment();
		let REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_LOCAL;
		if (ENV === ENVIRONMENT.PROD) {
			REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_PROD;
		} else if (ENV === ENVIRONMENT.QA) {
			REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_QA;
		} else if (ENV === ENVIRONMENT.DEV) {
			REDIRECT_URI = AUTH_CONFIG.REDIRECT_URI_DEV;
		}
		const url = `${AUTH_CONFIG.AUTHORIZATION}?client_id=${AUTH_CONFIG.CLIENT_ID}&response_type=${AUTH_CONFIG.RESPONSE_TYPE}&scope=${AUTH_CONFIG.SCOPE}&redirect_uri=${REDIRECT_URI}`;
		window.open(url, '_self');
	};

	useEffect(() => {
		const { isTokenValid, userId } = validateToken();
		if (isTokenValid) {
			if (
				farmDetailsFromStore?.length === 0 ||
				diffInMinutes({ date1: lastRefreshDateTime, date2: new Date() }) >
				TIME_TO_REFRESH
			) {
				
				populateData(userId);
			}
		} else {
			let code = getAuthCode(searchStr);
			if (code) {
				getTokenId(code).then(
					response => {
						NetworkService.setAccessTokenAuthHeader(response);
						let token = JSON.stringify(response);
						//Login Session Key will be in local storage
						localStorage.setItem(appConstants.LoginSessionKey, token);
						const { userId } = validateToken();
						dispatch(saveUserId(userId));
						populateData(userId);

					},
					error => {
						appInsights.trackException({
							error: new Error(error),
							severityLevel: SeverityLevel.Error,
						});
						setErrorMsg(
							'Error: ' +
							error +
							'\n Error Description: ' +
							error +
							'\n Status Code: ' +
							error
						);
						handleError(error, history);
					}
				);
			} else {
				ssoLogin();
			}
		}
	}, []);

	return { errorMsg, pathToRoute, loading };
};