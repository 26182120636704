import React from 'react';

const ResourcesPagePage = () => {
    return <h2>Rules of Management page</h2>
}

function ResourcesPage() {
    return <>
        <ResourcesPagePage>
        </ResourcesPagePage>
    </>
}


export default ResourcesPage;