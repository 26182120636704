import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";

export default function FormCheckbox({
  name,
  label = name,
  placeholder,
  margin = "normal",
  variant = "outlined",
  color = "default",
  options,
  disabled,
  ...props
}) {

	const getDisabled = val => {
		if (val) return { disabled: true };
		return {};
	}; 

  return <FormControlLabel className="checkbox-form-control" label={label} control={<Checkbox color={color} variant={variant} {...getDisabled(disabled)} onChange={event => {
            let temp = { ...props.data.formdata };
            temp["accept_term"] = event.target.checked;
            props.data.setformdata(temp);
            props.onChange(event.target.checked);
          }} checked={props.data.formdata[props.data.id] || false} />} />;
}
