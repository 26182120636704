import React from 'react';
import { Typography, Card, Grid } from '@mui/material';
import { getFormattedDate } from '../../utils/dateUtil';
import { numberWithCommas } from "../../utils/numberFormat";


export const TransferSubmitPage = ({
	content,
	contactName,
	phoneNo,
	email,
	other_email,
	effectiveTransferDate,
	phoneNumber,
	otherNumber,
	sender,
	receiver
}) => {
	return (
		<>
			<Typography
				variant="caption"
				component="div"
				style={{ fontSize: '34px', fontWeight: 'bold', fontFamily: 'DIN 2014' }}
			>
				Transfer Submitted & Under Review
			</Typography>
			<Typography
				variant="caption"
				component="p"
				style={{ fontFamily: 'Inter', fontSize: '17px' }}
			>
				Your Transfer Request has been submitted.
			</Typography>
			<Typography
				variant="caption"
				component="p"
				style={{ fontFamily: 'Inter', fontSize: '17px', paddingTop: '10px' }}
			>
				Both the sending and receiving parties will receive an email from
				DocuSign with the details of the Transfer Agreement.
				<b>
					{' '}
					Both parties must sign and submit the Transfer Agreement document
					within 7 days or the Transfer Agreement will lapse
				</b>
				.
			</Typography>
			<Typography
				variant="caption"
				component="p"
				style={{
					fontFamily: 'Inter',
					fontSize: '17px',
					paddingTop: '10px',
					paddingBottom: '10px',
				}}
			>
				Once both parties have digitally signed and submitted the Transfer
				Agreement, your allocated Base will be updated in the Land O’Lakes
				systems and will show on your next pay statement.
			</Typography>
			<Card style={{ width: '550px', padding: '20px' }}>
				<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							component="span"
							gutterBottom
							style={{
								fontFamily: 'Inter',
								fontSize: '17px',
								fontWeight: 'bold',
							}}
						>
							Transfering
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							component="span"
							gutterBottom
							style={{ fontFamily: 'Inter', fontSize: '17px' }}
						>
							{numberWithCommas(content)} base lbs.
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							component="span"
							gutterBottom
							style={{
								fontFamily: 'Inter',
								fontSize: '17px',
								fontWeight: 'bold',
							}}
						>
							Sender
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Typography
								variant="body2"
								component="span"
								gutterBottom
								style={{ fontFamily: 'Inter', fontSize: '17px' }}
							>
								{sender}
							</Typography>
						</div>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							component="span"
							gutterBottom
							style={{
								fontFamily: 'Inter',
								fontSize: '17px',
								fontWeight: 'bold',
							}}
						>
							Receiver
						</Typography>
					</Grid>
					<Grid item xs={6}>
					<Typography
							variant="body2"
							component="div"
							gutterBottom
							style={{ fontFamily: 'Inter', fontSize: '17px' }}
						>
							{receiver}
						</Typography>
						<Typography
							variant="body2"
							component="div"
							gutterBottom
							style={{ fontFamily: 'Inter', fontSize: '12px' }}
						>
							{contactName}
						</Typography>
						<Typography
							variant="body2"
							component="div"
							gutterBottom
							style={{ fontFamily: 'Inter', fontSize: '12px' }}
						>
							{phoneNo === 'Other' ? otherNumber : phoneNumber}
						</Typography>{' '}
						<Typography
							variant="body2"
							component="div"
							gutterBottom
							style={{ fontFamily: 'Inter', fontSize: '12px' }}
						>
							{email === 'Other'
								? other_email
								: email}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							component="span"
							gutterBottom
							style={{
								fontFamily: 'Inter',
								fontSize: '17px',
								fontWeight: 'bold',
							}}
						>
							Transfer Date
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							variant="body2"
							component="span"
							gutterBottom
							style={{ fontFamily: 'Inter', fontSize: '12px' }}
						>
							{getFormattedDate(effectiveTransferDate)}
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</>
	);
};
