export const createListCardPayload = ({
    id,
    today,
    values,
    contactId,
    farmName,
    farmGuid,
    lastModificationByUserIdentifier,
    typeCode
  }) => {
  const requestPayload =  {
    "id": id,
    "catalogLine": {
      "typeCode": typeCode,
      "lineNumberIdentifier":  id,
      "item": {
        "revisionIdentifier": "1",
        "description": "",
        "note": "",
        "specification": {
          "property": {
            "effectiveTimePeriod": {
              "startDateTime": values["proposed_transfer_date"],
              "endDateTime": values["proposed_transfer_date"],
            },
            "extension": {
              "indicator": [
                {
                  "content": values['split_transfer'],
                  "typeCode": "Split Transfer"
                },
                {
                  "content": true,
                  "typeCode": "Accept Terms"
                }
              ]
            }
          }
        }
      },
      "party": {
        "identifier": farmGuid,
        "name": farmName,
        "location": {
          "physicalAddress": [{
              "countrySubDivisionCode": values["countrySubDivisionCode"]
           }],
          "classification": [
            {
              "codes": {
                "code": [
                  {
                    "content": values["milkshed"],
                    "typeCode": "Milkshed"
                  }
                ]
              }
            }
          ]
        },
        "contact": {
          "identifier": contactId,
          "personName": {
            "formattedName": values['primary_contact']
          },
          "telephoneCommunication": [{
            "typeCode": values['type_code'], //TODO: typecode needs to be revisited
            "identifier": values['phone_number']
          }],
          "emailAddressCommunication": {
            "identifier": values['email']
          }
        }
      },
      "quantity": {
        "content": +values['amount_to_transfer'],
        "typeCode": "BASE",
        "unitCode": "LB"
      },
      "lastModificationDateTime": today,
      "extension": [{
          "status": {
            "code": "Open"
          },
          "lastModificationByUserIdentifier": lastModificationByUserIdentifier
      }]
    }
  }
  return requestPayload;
}