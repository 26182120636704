import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Box, Button, TextareaAutosize } from "@mui/material";

import { sendEmail } from "../../../common/services/contact.service";
import PublicLogoBoxes from "../../PublicLogoBoxes/PublicLogoBoxes";
import { handleError } from '../../../utils/errorUtil';
import { useHistory } from "react-router-dom";
import { FormSelect, FormTextField, FormTypography, Maskinput } from "../form";
import { validateToken } from "../../../utils/tokenUtil";

export const ContactUsSignin = () => {
  const formfields = {
    name: "",
    email: "",
    phoneno: "",
    message: "",
    userinfo: "",
  };
  const { REACT_APP_CONTACTUS_FROM_ADDRESS, REACT_APP_CONTACTUS_TO_ADDRESS } = process.env;
  const history = useHistory();
  const { isTokenValid } = validateToken();
  const [formdata, setformdata] = useState({ ...formfields });
  const [formerror, setformerror] = useState({});
  const Userinfo = ["Contact Information Incorrect", "Need Application Assistance", "Other"];

  const validationSchema = () => {
    let errors = {}
    if (formdata.userinfo === "")
      errors.userinfo = "Please select a User Info.";
    if (!formdata.name)
      errors.name = "Full Name is required.";
    
    if (!formdata.phoneno && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.email)) {
      errors.email = 'Please enter a valid email address.';
    }else if(formdata.phoneno && formdata.phoneno.length !== 14){
      errors.phoneno = "Please enter the full phone number, with no formatting"
    }


    if (!formdata.email && formdata.phoneno.length !== 14) {
      errors.phoneno = "Please enter the full phone number, with no formatting"
    }else if(formdata.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.email)){
      errors.email = 'Please enter a valid email address.';
    }
    if (formdata.message === "")
      errors.message = "Message is required.";
    return errors;
  }
  
  const order = {
    name: 'Submitter: ',
    phoneno: 'Phone Number: ',
    email: 'Email: ',
    userinfo: 'Reason For Message: ',
    message: 'Message: '
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    let errors = validationSchema();

    if (!(Object.keys(errors).length === 0 && errors.constructor === Object)) {
      setformerror(errors);
    }else {
     setformerror({});

      let forval = JSON.stringify(formdata, null, 2);
      let myObject = JSON.parse(forval);
      let template = ``;
      myObject.message = myObject.message.replace(/\n/g, "<br />");
      const emailTemp = {
        fromAddress: REACT_APP_CONTACTUS_FROM_ADDRESS,
        toAddress: REACT_APP_CONTACTUS_TO_ADDRESS,
        // ccAddress: REACT_APP_CONTACTUS_CC_ADDRESS,
        subject: myObject.userinfo + " - " + myObject.name + " - MarketPlace",
        message: "",
        isHtml: true
      }

      Object.keys(order).forEach(key => {
        template += `<p>${order[key]}${myObject[key] ? '<b>"' + myObject[key] + '"</b>' : '&nbsp'}</p>`;
      });
      emailTemp.message = template;
      sendEmail(emailTemp).then(res => {
        if (res) {
          isTokenValid ? history.push('/message-sent'):history.push('/message-sent-signin');
        }
       
      }).catch(error => handleError(error, history));
    }
  };

  const handleOtherPhoneNoChange = (event) => {
    setformdata({ ...formdata, phoneno: event.target.value });
  };

  const handleChangeUserInfo = (event) => {
    setformdata({ ...formdata, userinfo: event.target.value });
  };

  const productOptions = Userinfo.map((userinfo, key) => (
    <option value={userinfo} key={key}>
      {userinfo}
    </option>
  ));

  return (
    <div className={`${!isTokenValid ? "public-view login-container" : ""}`}>
      {!isTokenValid &&
        <PublicLogoBoxes />
      }
      <div className={`content ${!isTokenValid ? "contact-us-signin-section" : "contact-us-section"}`} >

        <Container component="main" className="content-container">
          {!isTokenValid &&
            <Button to="/auth" component={Link} className="back-to-login">
              <i className="ag-icon ag-icon-chevron-left"></i>Back to Sign In
            </Button>
          }
          <h1>Contact Us </h1>
          <p>
          Need assistance? Email us at <a href="mailto:marketplace@landolakes.com">marketplace@landolakes.com</a> and we will respond within 1-2 business days. 
If this is an urgent request call 800-328-1341 and leave a detailed message and we will get back to you.
          </p>

          {/* {isTokenValid &&
            <p>Need assistance? Complete the form below and we will respond within 1-2 business days. If this is an urgent request call 800-328-1341 and leave a detailed message and we will get back to you.</p> ||
              <p>Need assistance? Complete the form below and we will respond within 1-2 business days. If this is an urgent request call 800-328-1341 and leave a detailed message and we will get back to you.</p>
          } */}
            
          {/* <form onSubmit={onSubmit} noValidate>
            <Box className="box-container" >

              <h3> Send a Message</h3>
              <Grid container className="nested-box-container" alignItems="flex-start" spacing={2}  >
                <Grid item xs={12} md={6}>
                  <FormTextField
                    name="Name"
                    type="text"
                    maxLength={80}
                    className="text-field"
                    placeholder="First & Last Name"
                    data={{ formdata, setformdata, id: "name" }}

                  />
                  {
                    formerror.name && (
                      <FormTypography
                        error={{ formerror, id: "name" }}
                        color={"#B00020"} />
                    )
                  }
                </Grid>
                <Grid item xs={12} md={6}>
                  <Maskinput
                    name="Contact Phone Number"
                    onChange={handleOtherPhoneNoChange}
                    data={{ formdata, setformdata, id: "phoneno" }}
                  />
                  {
                    formerror.phoneno && (
                      <FormTypography
                        error={{ formerror, id: "phoneno" }}
                        color={"#B00020"} />
                    )
                  }
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormTextField
                    name="Email"
                    type="text"
                    maxLength={330}
                    className="text-field"
                    placeholder="Email address"
                    data={{ formdata, setformdata, id: "email" }}
                  />
                  {
                    formerror.email && (
                      <FormTypography
                        error={{ formerror, id: "email" }}
                        color={"#B00020"} />
                    )
                  }
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormSelect
                    data={{ formdata, setformdata, id: "userinfo" }}
                    label="Reason for message"
                    onChange={handleChangeUserInfo}
                    name="userinfo"
                  >
                    <option value=""></option>
                    {productOptions}
                  </FormSelect>
                  {
                    formerror.userinfo && (
                      <FormTypography
                        error={{ formerror, id: "userinfo" }}
                        color={"#B00020"} />
                    )
                  }
                </Grid>

                <Grid item xs={12} className="text-area-container">
                  <TextareaAutosize
                    className="text-area"
                    placeholder="Message"
                    id="message"
                    minRows={5}
                    maxRows={5}
                    maxLength={800}
                    onChange={(event) => {
                      let temp = { ...formdata };
                      temp["message"] = event.target.value;
                      setformdata(temp);
                    }}
                    value={formdata["message"]} />
                  {
                    formerror.message && (
                      <FormTypography
                        error={{ formerror, id: "message" }}
                        color={"#B00020"} />
                    )
                  }
                </Grid>
              </Grid>


            </Box>

            <Grid container className="form-button-container">
              <Grid item xs={12} sm={6}>

                <Button type="submit" className="button">
                  Send
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className="form-button-container-right">

              </Grid>
            </Grid>
          </form> */}

        </Container>
      </div>
    </div>

  );
}
export default ContactUsSignin;