import React from "react";
import { ContactUsSignin } from "./ContactUsSignin";

export const ContactUs = () => {
    return (
      <section className="content page-contact-us">
        <ContactUsSignin />
      </section>
    )
}
export default ContactUs;