import TextField from "@mui/material/TextField";
import React from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function FormDatepicker({
	name,
	label = name,
	minDate,
	variant = 'outlined',
	onChange,
	disabled,
	value,
	id,
	...props
}) {
	const getDisabled = val => {
		if (val) return { disabled: true };
		return {};
	}; 

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePicker
				id={id}
				label={label}
				minDate={minDate}
				onChange={onChange}
				{...getDisabled(disabled)}
				value={value}
				renderInput={params => (
					<TextField {...params} size="small" className="text-field" />
				)}
			/>
		</LocalizationProvider>
	);
}
