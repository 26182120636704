
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SAVE_USER_ID = "SAVE_USER_ID";
export const SAVE_USER_NAME = "SAVE_USER_NAME";
export const SAVE_CONTACTS = "SAVE_CONTACTS";
export const SAVE_USER_MILK_SHED_NAME = "SAVE_USER_MILK_SHED_NAME";
export const SAVE_FARM_WITH_GUID = "SAVE_FARM_WITH_GUID";
export const SAVE_MILK_SHEDS = "SAVE_MILK_SHEDS";
export const SAVE_CONTACTS_LIST = "SAVE_CONTACTS_LIST";
export const SAVE_FARM_DETAILS = "SAVE_FARM_DETAILS";
export const SAVE_SENDER_FARM_DETAILS = "SAVE_SENDER_FARM_DETAILS";
export const CREATE_INITIATE_TRANSFER = "CREATE_INITIATE_TRANSFER";

//For Rules and Terms Actions
export const SAVE_TERMS_CONTENT_VERSION = 'SAVE_TERM_CONTENT_VERSION';
export const SAVE_RULES_CONTENT_VERSION = 'SAVE_RULES_CONTENT_VERSION';
export const SAVE_TERMS_CONDITIONS_CHECK= 'SAVE_TERMS_CONDITIONS_CHECK';

//For rules and Engagement

export const SAVE_RULES_ENGAGEMENTC_CHECK ='SAVE_RULES_ENGAGEMENTC_CHECK';
export const REFRESH_DATA_ACTION ='REFRESH_DATA_ACTION';


export const SAVE_ALL_CATALOG = "SAVE_ALL_CATALOG";

export const RECEIVING_PARTY_VERIFICATION = "RECEIVING_PARTY_VERIFICATION";

export const SENDING_PARTY_VERIFICATION = "SENDING_PARTY_VERIFICATION";

