export const handleError = (error, history) => {  
  const { response } = error;
  if(response?.status === 401) {
    localStorage.clear();  
    return history.push('/authorize');
  }
  else {
    //TODO - Need to find a way to handle other errors
    console.error("Not able to handle error", error);
  }
}