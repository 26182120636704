import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRequiredDocuments } from '../common/services/dynamicPages.service'; 
import { getUserConsentVersion } from '../common/services/userconsent.service';
import { getFormattedDateAndTime } from '../utils/dateUtil';
import { handleError } from '../utils/errorUtil';
import { validateToken } from '../utils/tokenUtil';

export const useDocument = (title) => {
  const history = useHistory();
  const [document, setDocument] = useState('');
  const [isUserError, setIsUserError] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const { isTokenValid , userId } = validateToken()
    
   useEffect(() => {
    let document = { content:'', date:'' };
    let versionIdentifier = '';
    if (isTokenValid && userId) {
      getRequiredDocuments([]).then((docsresponse) => {
        docsresponse.forEach((doc) => {
          if (doc.title === title) {
            document.content = doc.content;
            versionIdentifier = doc.versionIdentifier;
          }
        });
        getUserConsentVersion(userId, title, versionIdentifier).then((userconsentresponse) => {
            let effectiveDate = userconsentresponse[0]?.userConsent?.effectiveTimePeriod?.startDateTime;
            document.date = getFormattedDateAndTime(effectiveDate);
            setDocument(document);
        }).catch((error) => {
          setIsApiError(true);
          handleError(error,history);
        });
      }).catch((error) => {
        setIsApiError(true);
        handleError(error,history);
      });;
    } else {
      setIsUserError(true);
    }
      
   }, []);
   return { document, isUserError, isApiError };
}