import React from "react";
import PublicLogoBoxes from "../components/PublicLogoBoxes/PublicLogoBoxes";
import ContactUsSuccess from "./ContactUsSuccess";

export const ContactUsSuccessSignin = () => {
    return (

        <section className="public-view full-width page-contact-us">
            <PublicLogoBoxes />
            <ContactUsSuccess />
        </section>

    )
}
export default ContactUsSuccessSignin;