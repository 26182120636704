import React from "react";
import { ListingCard } from "./ListingCard"

const VIEW_ALL = "View All";

export const CurrentListing = (props) => {
      return (
            props.userMilkSheds.map((item, index) => {
               return <ListingCard 
                  id={`${index}${item?.id}${item.catalogLine.party.identifier}`} 
                  userMilkShed={item} 
                  milkShedDesc={props.milkShedDesc}
                  baseType={item?.catalogLine?.typeCode}
                   />
            })
         )
 
   
}

export default CurrentListing;
