import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	getPartyMaster,
	getActiveFarms,
} from '../common/services/partyMaster.service';
import { saveMilkSheds } from '../store/actions/saveMilkShedsAction';
import { saveMilkShedName } from '../store/actions/saveMilkShedName';
import { getContactFarm } from '../common/services/contact.service';
import { saveContactsList } from '../store/actions/contactsListAction';
import { getCatalogAndOrderStatus } from '../common/services/orderOnline.service';
import { saveFarmDetails } from '../store/actions/farmDetailsAction';
import { handleError } from '../utils/errorUtil';
import { usePopulateData } from './usePopulateData';

import * as types from '../store/actions/actionTypes';

import { getListingsValidations } from '../common/services/getValidations';

export const useFarmDetails = reload => {
	const { errorMsg } = usePopulateData({ searchStr: '' });
	const farmsWithGUIDFromStore = useSelector(state => state?.farmsWithGUID);
	const farmDetailsFromStore =
		useSelector(state => state?.farmDetails?.farmDetails) || [];
	const [farmDetails, setFarmDetails] = useState(farmDetailsFromStore);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const farmsWithGUID = farmsWithGUIDFromStore?.farmsWithGUID;
	const farmIds = farmsWithGUIDFromStore?.authorizedFarms;

	const routeToAuthorize = () => {
		localStorage.clear();
		return history.push('/authorize');
	};

	const handleOrderItem = (item, updatedFarms) => {
		const onlineOrder = item?.onlineOrder?.onlineOrderLine;
		const content = onlineOrder?.quantity?.content;
		handleParty(
			onlineOrder?.party,
			item?.id,
			updatedFarms,
			'receiverParty',
			content
		);
		handleParty(
			onlineOrder?.supplierParty,
			item?.id,
			updatedFarms,
			'supplier',
			content
		);
	};

	const handleParty = (
		party,
		partyIdentifier,
		updatedFarms,
		whichParty,
		content
	) => {
		const pendingSubmittedQuantity =
			whichParty === 'receiverParty' ? content : -content;
		const identifier = party?.identifier;
		const matchingIndex = updatedFarms.findIndex(
			item => item.farmPartyID === identifier
		);
		if (matchingIndex !== -1) {
			updatedFarms[matchingIndex] = {
				...updatedFarms[matchingIndex],
				pendingtransfer: updatedFarms[matchingIndex]?.pendingtransfer + 1,
				pendingSubmittedTransferBase:
					updatedFarms[matchingIndex]?.pendingSubmittedTransferBase +
					pendingSubmittedQuantity,
			};
		} else {
			updatedFarms.push({
				farmPartyID: identifier,
				farmname: party?.name,
				activelisting: 0,
				pendingtransfer: 1,
				partyIdentifier,
				indicators: [],
				listingslessThan180Days: 0,
				pendingSubmittedTransferBase: pendingSubmittedQuantity,
			});
		}
	};

	const handleCatalogItem = (catalogItem, updatedFarms) => {
		const identifier = catalogItem?.catalogLine?.party?.identifier;
		const matchingIndex = updatedFarms.findIndex(
			item => item.farmPartyID === identifier
		);
		if (matchingIndex !== -1) {
			updatedFarms[matchingIndex] = {
				...updatedFarms[matchingIndex],
				activelisting: updatedFarms[matchingIndex].activelisting + 1,
				listingslessThan180Days:
					updatedFarms[matchingIndex].listingslessThan180Days +
					listingLessThan180Days(
						catalogItem?.catalogLine?.lastModificationDateTime
					),
			};
		} else {
			updatedFarms.push({
				farmPartyID: identifier,
				farmname: catalogItem.catalogLine.party.name,
				activelisting: 1,
				pendingtransfer: 0,
				partyIdentifier: catalogItem?.id,
				indicators:
					catalogItem?.catalogLine?.item?.specification?.property?.extension
						?.indicator,
				listingslessThan180Days: listingLessThan180Days(
					catalogItem?.catalogLine?.lastModificationDateTime
				),
				pendingSubmittedTransferBase: 0,
			});
		}
	};

	const listingLessThan180Days = lastModificationDate => {
		const sixMonthsEarlyDate = new Date();
		sixMonthsEarlyDate.setDate(sixMonthsEarlyDate.getDate() - 180);
		sixMonthsEarlyDate.setHours(0, 0, 0, 0);
		const lastModificationDateTime = new Date(lastModificationDate);
		lastModificationDateTime.setHours(0, 0, 0, 0);
		if (sixMonthsEarlyDate >= lastModificationDateTime) {
			return 1;
		}
		return 0;
	};

	const handleNoActiveListedItems = (idsAndCurrentBase, updatedFarms) => {
		idsAndCurrentBase.forEach(activeFarm => {
			const matchingIndex = updatedFarms.findIndex(
				item => item.farmPartyID === activeFarm.id
			);
			if (matchingIndex !== -1) {
				updatedFarms[matchingIndex] = {
					...updatedFarms[matchingIndex],
					currentbase: activeFarm.currentBase,
					data: activeFarm.data,
				};
			} else {
				updatedFarms.push({
					farmPartyID: activeFarm.id,
					farmname: activeFarm.farmName,
					activelisting: 0,
					pendingtransfer: 0,
					partyIdentifier: activeFarm.identifier,
					indicators: activeFarm.indicator,
					currentbase: activeFarm.currentBase,
					data: activeFarm.data,
					listingslessThan180Days: listingLessThan180Days(
						activeFarm.data[0].partyMaster.lastModificationDateTime
					),
					pendingSubmittedTransferBase: 0,
				});
			}
		});
	};

	useEffect(() => {
		if (farmsWithGUIDFromStore?.farmsWithGUID && !loading) {
			setLoading(true);
			getPartyMaster(farmsWithGUID[0].identifier.content)
				.then(milkSheds => {
					dispatch(saveMilkSheds(milkSheds));
					dispatch(
						saveMilkShedName(
							milkSheds[0]?.partyMaster?.partyClassification?.codes?.code[0]
								?.content
						)
					);
				})
				.catch(error => {
					handleError(error, history);
				});

			Promise.all(
				farmIds.map(farmId => {
					return getContactFarm(farmId);
				})
			)
				.then(farms => {
					dispatch(saveContactsList(farms.map(contactDetail => contactDetail)));
				})
				.catch(error => {
					handleError(error, history);
				});

			getActiveFarms(farmIds, 'true')
				.then(response => {
					if (response?.length < 1) {
						routeToAuthorize();
					}

					const farmIdentifiers = response.map(e => e.id).join(';');
					getListingsValidations(
						response.map(farm => farm.id),
						'SendingPartyVerification'
					)
						.then(sendingPartyVerification => {
							dispatch({
								type: types.SENDING_PARTY_VERIFICATION,
								payload: sendingPartyVerification,
							});
						})
						.catch((error) => console.log(error));

					getListingsValidations(
						response.map(farm => farm.id),
						'ReceivingPartyVerification'
					)
						.then(receivingPartyVerification => {
							dispatch({
								type: types.RECEIVING_PARTY_VERIFICATION,
								payload: receivingPartyVerification,
							});
						})
						.catch((error) => console.log(error));

					const idsAndCurrentBase = response.map(item => {
						return {
							id: item?.id,
							data: [item],
							farmName: item?.partyMaster?.party?.name,
							identifier: item?.partyMaster?.party?.identifier,
							indicator: item?.partyMaster?.party?.extension?.indicator,
							currentBase:
								item?.partyMaster?.party?.extension?.quantity?.content,
						};
					});

					getCatalogAndOrderStatus(farmIdentifiers, 'Pending;Submitted', 'Open')
						.then(resp => {
							let updatedFarms = [];
							if (resp?.length > 0) {
								resp.forEach(item => {
									if (item.catalogLine) {
										handleCatalogItem(item, updatedFarms);
									} else {
										handleOrderItem(item, updatedFarms);
									}
								});
							}
							handleNoActiveListedItems(idsAndCurrentBase, updatedFarms);
							const tempIds = idsAndCurrentBase.map(item => item.id);
							updatedFarms = updatedFarms.filter(item =>
								tempIds.includes(item.farmPartyID)
							);
							// Updating Farm Name from Party Master
							idsAndCurrentBase.forEach(activeFarm => {
								const matchingIndex = updatedFarms.findIndex(
									item => item.farmPartyID === activeFarm.id
								);
								if (matchingIndex !== -1) {
									updatedFarms[matchingIndex] = {
										...updatedFarms[matchingIndex],
										farmname: activeFarm.farmName,
									};
								}
							});
							setFarmDetails(updatedFarms);
							dispatch(saveFarmDetails(updatedFarms));
						})
						.catch(error => {
							handleError(error, history);
						})
						.finally(() => {
							setLoading(false);
						});
				})
				.catch(error => {
					handleError(error, history);
				});
		}
		//TODO: we need to use initiateTransferCounter from redux to add as dep in useEffect
		//when we have implemented it in create listing and initiate transfer
	}, [farmsWithGUIDFromStore, reload]);

	return { loading, farmDetails, errorMsg };
};
