import { RECEIVE_BASE } from '../../common/constants/constants';

export const THOUSAND = 1000;
export const PERCENTAGE_FOR_AVAILABLE_BASE = 0.75;

/**
 *
 * @param {amountToTransfer} providing details of the amount which needs to be transferred being entered in the form
 * @param {currentbase} providing details of the amount of the content of the farm
 * @param {listAmount} providing details of the amount of the content base of the farm with status Open;Pending;Signed;Submitted;Pending Cancel
 * @param {isEditListing} providing details if validation is for edit listing ot create listing
 * @param {editListingAmountTransfer} providing details of the amount which comes when a listing is edited
 * @returns {amountError} with string stating if any error needs to be shown after validation
 */

export const validateIntiateTransferAmount = ({
	amountToTransfer,
	currentbase,
	listAmount,
	baseType,
	availableamount,
	sumOfActiveOrderAmount
}
) => {

	let amountError = '';
	if (!amountToTransfer) {
		amountToTransfer === 0
			? (amountError = 'No base available to transfer')
			: (amountError = 'Required: Please enter only 0-9.');
	} else {
		if (currentbase >= 0) {
			let enteredAmount = parseInt(amountToTransfer);
			let percentValue = enteredAmount + listAmount + sumOfActiveOrderAmount;
			
			if (availableamount <= 0) amountError = 'No base available to transfer.';
			else if (availableamount >= THOUSAND && enteredAmount < THOUSAND)
				amountError = 'Transfers only allowed for 1,000 lbs/day or more.';
			else if (availableamount < THOUSAND && enteredAmount !== availableamount)
				amountError = 'Available base is less than the requested amount.';
			else if (enteredAmount > availableamount)
				amountError = 'Available base is less than the requested amount.';
			else if (percentValue > currentbase * PERCENTAGE_FOR_AVAILABLE_BASE)
				amountError =
					'Warning, your total listings are more than 75% of your base.';
		}
	}

	return amountError;
};




export const ValidateAmount = ({
	amountToTransfer,
	currentbase,
	listAmount,
	isEditListing,
	editListingAmountTransfer,
	baseType,
	availableamount,
	sumOfActiveOrderAmount
}) => {
	let amountError = '';
	if (!amountToTransfer) {
		amountToTransfer === 0
			? (amountError = 'No base available to transfer')
			: (amountError = 'Required: Please enter only 0-9.');
	} else {
		if (currentbase >= 0) {
			let enteredAmount = parseInt(amountToTransfer.replace(/,/g, ''));
			let percentValue = enteredAmount + listAmount + sumOfActiveOrderAmount;
			if (isEditListing) {
				let noCommaEditListingAmountTransfer = parseInt(
					editListingAmountTransfer.replace(/,/g, '')
				);
				availableamount += parseInt(noCommaEditListingAmountTransfer);
				percentValue -= parseInt(noCommaEditListingAmountTransfer);
			}
			if (amountToTransfer.match(/[.]/g)) {
				amountError = 'Please enter a valid base amount';
			}
			if (baseType === RECEIVE_BASE) {
				if (amountToTransfer < THOUSAND) {
					amountError = 'Transfers only allowed for 1,000 lbs/day or more';
					return { amountError };
				} else {
					return '';
				}
			}
			if (availableamount <= 0) amountError = 'No base available to transfer';
			else if (availableamount >= THOUSAND && enteredAmount < THOUSAND)
				amountError = 'Transfers only allowed for 1,000 lbs/day or more';
			else if (availableamount < THOUSAND && enteredAmount !== availableamount)
				amountError = 'Transfer must equal base if current less than 1,000';
			else if (enteredAmount > availableamount)
				amountError = 'Not allowed to list for more than available base.';
			else if (percentValue > currentbase * PERCENTAGE_FOR_AVAILABLE_BASE)
				amountError =
					'Warning, your total listings are more than 75% of your base.';
		}
	}

	return { amountError };
};

export const ValidateCreateListForm = ({
	proposedTransferDate,
	selectedFarmValue,
	selectedFarm,
	amountToTransfer,
	listAmount,
	email,
	otherEmail,
	phoneNumber,
	otherPhonenumber,
	farmDetailsFromStore,
	isEditListing,
	editListingAmountTransfer,
	exception,
}) => {
	let errors = {};
	const date = new Date();
	const validProposedTransferDate = new Date(proposedTransferDate);
	date.setDate(date.getDate() - 1);
	const getMonthForProposedTransferDate =
		validProposedTransferDate.getMonth() + 1;
	const getDateForProposedTransferDate = validProposedTransferDate.getDate();

	if (
		date > new Date(proposedTransferDate) ||
		!getMonthForProposedTransferDate ||
		!getDateForProposedTransferDate
	) {
		errors.proposed_transfer_date = 'Enter a valid Proposed Transfer Date';
	}

	const { farmError } = ValidateFarmError({
		selectedFarmValue,
		farmGuid: selectedFarm.farmPartyID,
		farmDetailsFromStore,
		exception,
	});

	const { amountError } = ValidateAmount({
		amountToTransfer,
		currentbase: selectedFarm.currentbase,
		listAmount,
		isEditListing,
		editListingAmountTransfer,
	});

	if (farmError !== '') {
		errors.farm_name = farmError;
	}
	if (amountError !== '') {
		errors.amount_to_transfer = amountError;
	}

	if (
		email === 'Other' &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(otherEmail)
	) {
		errors.other_email = 'Please enter a valid email address.';
	}

	if (phoneNumber === 'Other' && otherPhonenumber?.length !== 14) {
		errors.other_phone_number =
			'Phone number must be 10 digits, do not enter any formatting characters.';
	}

	return { errors };
};

export const ValidateFarmError = ({
	selectedFarmValue,
	farmGuid,
	farmDetailsFromStore,
	exception,
}) => {
	let farmError = '';

	if (!selectedFarmValue) {
		farmError = 'Required';
	} else {
		const currentFarm = farmDetailsFromStore.find(
			i => i.farmPartyID === farmGuid
		);
		if (currentFarm?.data[0]) {
			const indicators =
				currentFarm.data[0]?.partyMaster.party.extension.indicator;
			const isMMA = indicators.find(
				data => data?.typeCode === 'Non-annual MMA' && data?.content === true
			);

			if (isMMA && !exception) {
				farmError = 'Farm ineligible: non-annual MMA';
			}
		}
	}

	return { farmError };
};
