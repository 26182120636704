import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getUserConsent(userId) {
    let response = await NetworkService.getData(ApiUrl.getUserConsentURL(userId));
    return response;
}

export async function postUserConsent(payload) {
    let response = await NetworkService.postData(ApiUrl.postUserConsentURL(), payload);
    return response;
}

export async function getUserConsentVersion(userId, title, versionIdentifier) {
    let response = await NetworkService.getData(ApiUrl.getUserConsentVersion(userId, title, versionIdentifier));
    return response;
}
