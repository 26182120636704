import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getEnvelopeId(payload) {
    let response = await NetworkService.postData(ApiUrl.getEnvelopeIdURL(), payload);
    return response;
}

export async function sendEnvelop(requestBody) {
    let response = await NetworkService.postData(ApiUrl.postEnvelopURl(), requestBody);
    return response;
}

export async function updateEnvelop(envelopeId,status,reason) {
    let response = await NetworkService.putData(ApiUrl.postUpdateEnvelopURl(envelopeId,status,reason));
    return response;
}