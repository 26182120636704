import * as types from "../actions/actionTypes";

export default function userReducer(state = {}, action) {
  switch (action.type) {
    case types.SAVE_USER_ID:
      return { ...state, userId: action.userId }
    case types.SAVE_USER_NAME:
      return { ...state, userName: action.userName }
    default:
      return state;
  }
}
