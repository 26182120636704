import React, { useMemo } from "react";

function RenderLoading({loading}) {
   if(loading) {
   return (<main className="create-listing-page">
		<section className="content">
		    <h1>Loading...</h1>
		</section>
	</main>)
   } else
    return null;

}

export default RenderLoading;