import React from 'react';
import { Box, Grid, IconButton, Drawer, Button, Link } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveSenderFarmDetails } from '../../../store/actions/senderFarmDetailsAction';
import { RECEIVE_BASE, SEND_BASE } from '../../../common/constants/constants';


export const InitiateTransferDrawer = ({open, data, anchor, toggleTransDrawer, baseType}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  /**
   * TODO: remove following after checking where senderFarmDetails 
   * is used from store or populate it 1 step up
   **/
  if (data) {    
    dispatch(saveSenderFarmDetails(data));
  }

  const handleClick = (event) => {
    event.preventDefault();
    history.push({
      pathname: '/initiate-transfer',
      state: {
        senderFarmDetails : data,
        baseType: baseType
      },
    })
  }

  const handleOnClickOnRules = () => {
    return history.push('/rules-of-engagement-sidebar');
  }

  const list = () => (
    <Box className="modal-flyout-body">
      <h1>Initiating a Transfer - {baseType == RECEIVE_BASE ? SEND_BASE : RECEIVE_BASE}</h1>
      <h2 className="h2-lite" >Transfer Agreement Reminder</h2>
      <p> 
        Before entering into an agreement both parties should reference the{' '}  
        <Link 
          onClick={handleOnClickOnRules}>
            Rules of Engagement
        </Link>
        {' '}document and agree to the terms of the transfer.
      </p>
      <p>By initiating this transfer, you will {baseType === RECEIVE_BASE && "decrease" || "increase"} your daily Base pounds. </p>     

      <Grid container className={"form-button-container"}>
        <Grid item xs={12} sm={6}>
          <Button
            className={"button"}
            disabled={data?.catalogLine?.extension[0]?.status?.code === 'Pending'}
            onClick={handleClick}>
            Continue to Transfer
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={"form-button-container-right"}>
        </Grid>
      </Grid>
    </Box>
  );

  return (
		<>
			<Drawer
				anchor={anchor}
				open={open}
				onClose={() => toggleTransDrawer(false)}
				className="modal-flyout-wrapper"
				disableEnforceFocus
			>
				<IconButton
					className="close"
					onClick={() => toggleTransDrawer(false)}
				>
					<i className="ag-icon ag-icon-close-x"></i>
				</IconButton>

				{list()}
			</Drawer>
		</>
	);
}
