import { Container, Grid, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PublicLogoBoxes from "../components/PublicLogoBoxes/PublicLogoBoxes";


export const ContactUsSuccess = () => {
  const userId = useSelector(state => state?.user?.userId);
    return (
			    <div className={`${!userId ? "public-view login-container" : ""}`}>

			
				<div className={`content ${!userId ? "contact-us-signin-section" : "contact-us-section"}`} >

					<Container component="main" className="content-container">
						{!userId &&
							<Button to="/auth" component={Link} className="back-to-login">
								<i className="ag-icon ag-icon-chevron-left"></i>Back to Sign In
							</Button>
						}
						<h1>Contact Us </h1>
						<p>
							Need assistance? Complete the form below and we will respond
							within 1-2 business days. If this is an urgent request call
							800-328-1341 and leave a detailed message and we will get back
							to you.
						</p>
						
						<Box container className="box-container" boxShadow={3}>
							<Grid item xs={12} className="success">
								<h3> Message Sent</h3>
								<p>
									Your message has been sent. We will respond within 1-2
									business days.
								</p>
							</Grid>
						</Box>
					</Container>
				</div>
				</div>
		);
}


export default ContactUsSuccess;