import { RECEIVE_BASE, SEND_BASE } from '../../common/constants/constants';

function InitiateTransferHeadings({ baseType }) {
	return (
		<>
			<h1>Initiate Transfer - {baseType === SEND_BASE ?  RECEIVE_BASE: SEND_BASE}</h1>
			<p>
				By initiating this transfer, you will{' '}
				{(baseType === RECEIVE_BASE && ' decrease ') || 'increase'} your daily Base
				pounds.
				If the pounds to transfer field does not reflect the correct pounds work with the listing member-owner to update the listing to the correct pounds before you initiate the transfer.
			</p>
		</>
	);
}


export default InitiateTransferHeadings;
