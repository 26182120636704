import React from "react";
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import AppRoute from "./App.route";
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from "./AppInsights";
import ErrorBoundary from "./hoc/error_boundary/ErrorBoundary";

const App = () => {

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Inter',
        'Helvetica',
        'Arial',
        'sans-serif'
      ],
    }
  });


  return (
    <MuiThemeProvider theme={theme}>
      <AppInsightsErrorBoundary onError={() => <ErrorBoundary/>} appInsights={reactPlugin}>
        <AppRoute />
      </AppInsightsErrorBoundary>
    </MuiThemeProvider>
  );
}

export default App;
