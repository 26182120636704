import { RECEIVE_BASE } from "../../common/constants/constants";

export const sendEnvelopPayload = (senderDetails,receiverDetails,transferDate)=>{
  let ReceivingBaseLbsReceived= (receiverDetails.lbs).toString();
  const { REACT_APP_DOCUSIGN_TEMPLATE_ID } = process.env;
  return {
  Signer1Email: senderDetails.email,
  Signer1Name: senderDetails.contactName,
  Signer1Role: "Sender",
  Signer2Email: receiverDetails.email,
  Signer2Name: receiverDetails.contactName,
  Signer2Role: "Receiver",
  TemplateId: REACT_APP_DOCUSIGN_TEMPLATE_ID,
  Fields: [
    {
      Name: "SendingFarmId",
      Value: senderDetails.farmId
    },
    {
      Name: "SendingFarmName",
      Value: senderDetails.farmName
    },
    {
      Name: "SendingContactName",
      Value: senderDetails.contactName
    },
    {
      Name: "SendingBaseLbsTransferred",
      Value: senderDetails.lbs+''
    },
    {
      Name: "ReceivingFarmId",
      Value: receiverDetails.farmId
    },
    {
      Name: "ReceivingFarmName",
      Value: receiverDetails.farmName
    },
    {
      Name: "ReceivingContactName",
      Value: receiverDetails.contactName
    },
    {
      Name: "ReceivingBaseLbsReceived",
      Value: ReceivingBaseLbsReceived+''
    },
    {
      Name: "EffectiveTransferDate",
      Value: transferDate
    }
  ]
}
}