import { useState, useReducer, useEffect } from 'react';
import MarketPostList from '../../components/common/currentListings/MarketPostList';
import { getCatalogData } from '../../common/services/catalog.service';
import { checkBlackOutDate } from '../../utils/dateUtil';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import { FormSelect } from '../../components/common/form';
import { RECEIVE_BASE, SEND_BASE, VIEW_ALL } from '../../common/constants/constants';
import { useDispatch } from 'react-redux';
import saveAllCatalogReducer from '../../store/reducers/saveAllCatalogReducer';
import { SAVE_ALL_CATALOG } from '../../store/actions/actionTypes';
import { saveAllCatalog } from '../../store/actions/saveAllCatalog';

export const CurrentLisingContainer = () => {
	const [userMilkSheds, setUserMilkSheds] = useState([]);
	const [showBlackOutWarnMsg, setShowBlackOutWarnMsg] = useState(false);
	const showBlackOutMsg = checkBlackOutDate();
	const userMilkShedTypeCode = 'Milkshed';
	const [loader, setLoader] = useState(true);
	const [activeMenu, setActiveMenu] = useState('View All');
	const [menuAnchorEl, setMenuAnchorEl] = useState(false);
	const dispatch = useDispatch();

	const [formdata, setformdata] = useState({ selectBase: '' });

	const getCatalog = async () => {
		const response = await getCatalogData();
		
		dispatch(saveAllCatalog(response));

		
		const REQUIRED_STATUS = ['Open', 'Pending', 'Submitted'];

		const milkSheds = response?.filter(milkShed => {
			return REQUIRED_STATUS.includes(
				milkShed?.catalogLine?.extension[0]?.status?.code
			);
		});


		let milkShedArr = milkSheds.filter(catalogItem => {
			let classificationArr =
				catalogItem.catalogLine.party.location.classification;
			return classificationArr.filter(classificationItem => {
				return classificationItem.codes.code.filter(codeItem => {
					if (codeItem.typeCode === userMilkShedTypeCode) {
						return catalogItem;
					}
				});
			});
		});
		setUserMilkSheds(milkShedArr);
		setLoader(false);
	};

	useEffect(() => {
		getCatalog();
	}, []);

	useEffect(() => {
		blackOutWarnMsg();
	}, []);

	//TODO: to revisit and check if following validation works
	const blackOutWarnMsg = () => {
		let today = new Date();
		// Get current date to compare against blackout period	
		let blackOutWarningToday = new Date(today.getFullYear(), today.getMonth(), today.getDay())

		// Create new date for July 15th and September 14th to compare against current date.
		// -1 because months are from 0 to 11
		let blackOutWarningStart = new Date(today.getFullYear(), 7 - 1, 15);
		let blackOutWarningEnd = new Date(today.getFullYear(), 9 - 1, 14);

		if (
			!showBlackOutMsg && (
				(blackOutWarningToday > blackOutWarningStart) && (blackOutWarningToday < blackOutWarningEnd)
			)
		) {
			setShowBlackOutWarnMsg(true);
		} else {
			setShowBlackOutWarnMsg(false);
		}
	};

	const onChangeBase = event => {
		const { value } = event.target;
		setActiveMenu(value);
    // Todo: We can also add the filter here and remove from he Current Listing when user selects the base
    
	};

	const baseSendAndRecieve = [
		{ value: VIEW_ALL, label: VIEW_ALL },
		{ value: SEND_BASE, label: SEND_BASE },
		{ value: RECEIVE_BASE, label: RECEIVE_BASE },
	];

	return (
		<section className="content page-current-listings full-width">
			<Box style={{ display: 'flex' }}>
				<h1 style={{marginRight: "20px"}}> Current Listings</h1>
				<Grid item xs={2} md={2}>
					<FormSelect
						data={{ formdata, setformdata, id: 'selectBase' }}
						disabled={false}
						onChange={onChangeBase}
					>
						{baseSendAndRecieve.map((item, index) => {
							return (
								<option key={item.value + index} value={item.value}>
									{item.label}{' '}
								</option>
							);
						})}
					</FormSelect>
				</Grid>
			</Box>
			{showBlackOutWarnMsg && (
				<Typography color="#e87722" className="warning-text">
					Reminder: Blackout will begin Sept 15 all <br />
					transfers need to be completed by that time.
				</Typography>
			)}
			{showBlackOutMsg ? (
				<div>
					<Typography color="#e87722" className="warning-text">
						Marketplace is currently in a blackout period to account for Annual
						<br />
						Base Reset. The blackout period runs from September 15 - October 31.
						<br />
						During this time, users are not allowed to transfer Base.
					</Typography>
				</div>
			) : (
				<>
					{loader ? (
						<p>Loading...</p>
					) : (
						<MarketPostList
							userMilkSheds={userMilkSheds}
							changedBase={activeMenu}
						/>
					)}
				</>
			)}
		</section>
	);
};

export default CurrentLisingContainer;
