import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import ManageTransfer from '../components/ManageTransfer/ManageTransfer';
import ManageTransferDrawer from '../components/ManageTransfer/ManageTransferDrawer'
import { getOrderFarms, postLisitingIds, getReceivingFarm } from '../common/services/orderOnline.service';
import { getAllCatalogFarmStatus } from '../common/services/catalog.service';
import { DateFormatter } from '../utils/dateUtil';
import { SENDER, RECEIVER, STATUS, SEND_BASE, RECEIVE_BASE } from '../common/constants/constants';
import { handleError } from '../utils/errorUtil';
import { numberWithCommas } from '../utils/numberFormat';

import { Box } from '@mui/material';
import {
	senderColumns,
	receiverColumns,
} from '../common/constants/manageTransfers';

const SOURCE_TABLE = {CATALOG: "CATALOG", ORDER: "ORDER"}

const ManageTransferPage = () => {
	const history = useHistory();
	const farmDetails = useSelector(state => state?.farmDetails?.farmDetails);

	const farmIds = useSelector((state) => {
		return state?.farmDetails?.farmDetails?.map((farm) => farm?.partyIdentifier);
	});

	const farmGuids = useSelector((state => state?.farmDetails?.farmDetails?.map((farm) => farm?.data[0]?.id)));

	const [sendingFarmDetails, setSendingFarmDetails] = useState([]);
	const [tab, setTab] = useState(SENDER);
	const [receivingFarmDetails, setReceivingFarmDetails] = useState([]);
	const [sidebarDetails, setSidebarDetails] = useState({
		state: false,
		farmDetails: {},
	});
	const [sendingLoader, setSendingLoader] = useState(true);
	const [receivingLoader, setReceivingLoader] = useState(true);
	const [catalogFarms, setCatalogFarms] = useState([]);
	const [orderFarms, setOrderFarms] = useState([])
	let dollarUSLocale = Intl.NumberFormat('en-US');


	useEffect(() => {
		if (farmDetails) {
			getFarmsListing();
		}
	}, [farmDetails]);

	let id = 1;
	const getFarmsListing = () => {
		

		getAllCatalogFarmStatus(farmGuids.join(';'), STATUS)
			.then(farm => {
				const payLoad = farm.map((item, i) => item.id);
				if (payLoad && payLoad.length > 0) {
					postLisitingIds(farm.map((item, i) => item.id))
						.then(receivingFarms => {
							setSendingLoader(true);
							const catalogData = farm.map(item => {
								const receivingFarm = receivingFarms.find(
									i =>
										i?.onlineOrder?.onlineOrderLine?.item?.identifier ===
										item?.id
								);

								const receiverFarmContact = receivingFarm?.onlineOrder?.onlineOrderLine?.party;
								const senderFarmContact = receivingFarm?.onlineOrder?.onlineOrderLine?.supplierParty;
								const receiverFarmId = receiverFarmContact?.identifier;
								
								let envelopId = '';
								let docuSign =
									receivingFarm?.onlineOrder?.onlineOrderHeader?.documentReference?.find(
										i => i?.typeCode === 'DocuSign Envelope ID'
									);
								envelopId = docuSign?.identifier;
								const senderContact = item?.catalogLine?.party?.contact;								
								const senderFarmId = item?.catalogLine?.party?.identifier;						
								const senderAddress = {
									senderFarmId,
									typecode: senderContact?.telephoneCommunication[0]?.typeCode,
									contactName: senderContact?.personName?.formattedName,
									mobile: senderContact?.telephoneCommunication[0]?.identifier,
									email: senderContact?.emailAddressCommunication?.identifier,
									contactId: item?.catalogLine?.party?.contact?.identifier
								}; 
								const indicator = {
									splitTransfer:
										item.catalogLine?.item?.specification?.property?.extension
											?.indicator[0]?.content,
									acceptTerms:
										item.catalogLine?.item?.specification?.property?.extension
											?.indicator[1]?.content,
								};
								const receiverAddress = {
									receiverFarmId,
									contactName:
										receiverFarmContact?.contact?.personName?.formattedName,
									mobile:
										receiverFarmContact?.contact?.telephoneCommunication
											?.identifier,
									email:
										receiverFarmContact?.contact?.emailAddressCommunication
											.identifier
								};
									let sender = item.catalogLine.party.name;
									let receiver = receiverFarmContact?.name;

									if(item.catalogLine.typeCode === RECEIVE_BASE) {
										sender = senderFarmContact?.name;
										receiver = item?.catalogLine?.party?.name;
										
									}
									return createData(
										id++, // id
										item.catalogLine.extension[0].status.code, // status
										item.catalogLine.lastModificationDateTime, // transferDate
										item.catalogLine.typeCode, //listingType
										numberWithCommas(item.catalogLine.quantity.content), //listingDetails
										receiver, //receiver
										sender, // sender
										senderAddress, 
										receiverAddress,
										item.id,
										item.catalogLine.party.identifier,
										indicator,
										receivingFarm?.id,
										item.catalogLine.item.specification.property
											?.effectiveTimePeriod?.startDateTime,
										envelopId,
										item.catalogLine.lastModificationDateTime,
										item.catalogLine.item.specification.property.extension
											.indicator[0]?.content
									);
								
							});
							setCatalogFarms(catalogData);
							setSendingLoader(false);
						})
						.catch(error => {
							handleError(error, history);
							setSendingLoader(false);
						});
				}
				else {
					setSendingLoader(false); // this is required to show the message when there is empty response from backend.

				}
			})
			.catch(error => {
				handleError(error, history);
				setSendingLoader(false);
			});

		let orderFarms = [];
		var receivingId = 1;
		getOrderFarms(farmGuids.join(';'), STATUS)
			.then(res => {
				let allOrderData;
				const orderData = res.map(item => {
					if (item) {
						const onlineOrderLine = item?.onlineOrder?.onlineOrderLine;
						const orderId = item?.id;
						let envelopId =
							item.onlineOrder.onlineOrderHeader.documentReference[1]
								?.identifier;
						const catalogId = onlineOrderLine?.item?.identifier;
						const senderContact = onlineOrderLine?.supplierParty?.contact;
						const senderFarmId = onlineOrderLine?.supplierParty?.identifier;
						const senderAddress = {
							senderFarmId,
							contactName: senderContact?.personName.formattedName,
							mobile: senderContact?.telephoneCommunication.identifier,
							email: senderContact?.emailAddressCommunication.identifier

						};
						const receiverFarmId = onlineOrderLine.party.identifier;
						const receiverContact = onlineOrderLine.party.contact;

						const receiverAddress = {
							receiverFarmId,
							contactName: receiverContact?.personName.formattedName,
							mobile: receiverContact?.telephoneCommunication.identifier,
							email: receiverContact?.emailAddressCommunication.identifier,

						};


						return createData(
								id++,
								item.onlineOrder.onlineOrderHeader.status.code,
								item.onlineOrder.onlineOrderLine.item.specification.property
									?.effectiveTimePeriod?.startDateTime,
									item?.onlineOrder?.typeCode.replace(' Transfer', ''),
								numberWithCommas(onlineOrderLine.quantity.content),
								onlineOrderLine.party.name,
								onlineOrderLine.supplierParty.name,
								senderAddress,
								receiverAddress,
								catalogId,
								'',
								'',
								orderId,
								'',
								envelopId,
								item.onlineOrder.onlineOrderHeader.lastModificationDateTime, 
								'',
								item.onlineOrder.onlineOrderLine.supplierParty.identifier,
								item.onlineOrder.onlineOrderLine.party.identifier,

							);
					}
				});

				setOrderFarms(orderData);
				setReceivingLoader(false);
			})
			.catch(error => {
				handleError(error, history);
				setReceivingLoader(false);
			});
	};

	useEffect(() => {
		if(catalogFarms.length> 0 || orderFarms.length> 0) {
			
			// Sending tabs
			const catalogForSendTab = catalogFarms.filter((catalog) => catalog.listingType === SEND_BASE);
			const orderForSendTab = orderFarms.filter((order) => (order.listingType === RECEIVE_BASE && 
				(farmGuids.findIndex((farmGuid) => farmGuid === order.supplierParty) !== -1))
			);
			setSendingFarmDetails([...catalogForSendTab, ...orderForSendTab ]);

			// Receiving Tab
			const catalogForReceiveTab = catalogFarms.filter((catalog) => catalog.listingType === RECEIVE_BASE);
			const orderForReceivTab = orderFarms.filter((order) => ((order.listingType === SEND_BASE) && 
				(farmGuids.findIndex((farmGuid) => farmGuid ===  order.party) !== -1))
			);
			setReceivingFarmDetails([...catalogForReceiveTab, ...orderForReceivTab])

		}	
		
	}, [catalogFarms, orderFarms]);


	const onChangeFarmStatus = data => {
		let currentStatus = 'Deleted';
		if (data.status === 'Pending') {
			currentStatus = 'Pending Cancel';
		}
		if (tab === SENDER) {
			const senderFarms = [...sendingFarmDetails];
			const currentFarm = senderFarms.find(i => i.id === data.id);
			currentFarm.status = currentStatus;
			setSendingFarmDetails(senderFarms);
		} else {
			const receiverFarms = [...receivingFarmDetails];
			const currentFarm = receiverFarms.find(i => i.id === data.id);
			currentFarm.status = currentStatus;
			setReceivingFarmDetails(receiverFarms);
		}
	};

	const createData = (
		id,
		status,
		transferDate,
		listingType,
		listingDetails,
		receiver,
		sender,
		senderAddress,
		receiverAddress,
		catalogId,
		senderId,
		indicator,
		orderId,
		proposedtransferDate,
		envelopId,
		effectivetransferDate,
		splitTransfer, 
		supplierParty, 
		party
	) => {
		return {
			id,
			catalogId,
			status,
			transferDate: DateFormatter(transferDate),
			listingType,
			listingDetails,
			receiver,
			sender,
			senderId,
			indicator,
			senderAddress,
			receiverAddress,
			orderId,
			proposedtransferDate,
			envelopId,
			effectivetransferDate,
			splitTransfer,
			postedDate: transferDate,
			supplierParty,
			party
		};
	};

	const showDetails = farm => {
		setSidebarDetails({ ...sidebarDetails, state: true, farmDetails: farm });
	};

	const closeDrawer = () => {
		setSidebarDetails({ ...sidebarDetails, state: false });
	};

	const switchTab = (e, tab) => {
		e.preventDefault();
		setTab(tab);
	};

	return (
		<>
			{sidebarDetails?.state && (
				<ManageTransferDrawer
					className={sidebarDetails?.state}
					open={sidebarDetails?.state}
					closeDrawer={closeDrawer}
					data={sidebarDetails.farmDetails}
					tab={tab}
					onChangeFarmStatus={onChangeFarmStatus}
				/>
			)}

			<section className="content page-manage-transfers full-width">
				<h1>Manage Transfers</h1>
				<Box className="manage-transfer-nav-items">
					<span
						className={
							(tab === SENDER && 'nav-tab-item active-tab') || 'nav-tab-item'
						}
						onClick={e => switchTab(e, SENDER)}
					>
						Sending
					</span>
					<span
						className={
							(tab === RECEIVER && 'nav-tab-item active-tab') || 'nav-tab-item'
						}
						onClick={e => switchTab(e, RECEIVER)}
					>
						Receiving
					</span>
				</Box>
				{sendingLoader || receivingLoader ? (
					<p>Loading...</p>
				) : (
					<ManageTransfer
						rows={tab === SENDER ? sendingFarmDetails : receivingFarmDetails}
						columns={tab === SENDER ? [...senderColumns] : [...receiverColumns]}
						tab={tab}
						showDetails={showDetails}
					/>
				)}
			</section>
		</>
	);
};

export default ManageTransferPage;