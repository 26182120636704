export const formatPhoneNumber = (str, typeCode) => {
  if (str === 'Other') {
      return str;
  }
  let cleaned = ('' + str).replace(/\D/g, '');

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    if (typeCode) {
      return `(${typeCode})(${match[1]}) ${match[2]}-${match[3]}`;
    }
    else {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  };

  return null
};


export const formatPhoneNumberWithoutTypeCode = (str) => {
  let cleaned = ('' + str).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };
  return null
};

