import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { Container } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';

import { formatPhoneNumberWithoutTypeCode } from '../utils/phoneNumberUtil';
import {
	getSubscriptions,
	postSubscriptions,
	updateSubscriptions,
} from '../common/services/subscription.service';
import { validateToken } from '../utils/tokenUtil';

const useStyles = makeStyles({
	switch: {
		'& .Mui-checked': {
			color: '#81B7C4',
			transform: 'translateX(25px) !important',
		},
		'& .MuiSwitch-track': {
			backgroundColor: '#010c2a !important',
		},
	},
});

function ProfilePage() {
	const [subscriptions, setSubscriptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fetchSubscriptionLoading, setFechSubscriptionLoading] = useState(true);
	const [listingNotification, setListingNotificationSubscription] = useState({
		enabledIndicator: false,
		message: '',
	});
	const { name, phoneNumbers, email, userIdentifier, userMilkShedName } =
		useSelector(state => {
			return {
				userMilkShedName: state?.userMilkShedName?.userMilkShedName,
				userIdentifier: state?.contacts?.contact?.identifier,
				name: state?.contacts?.contact?.personName?.formattedName,
				phoneNumbers: state?.contacts?.contact?.telephoneCommunication,
				email: state?.contacts?.contact?.emailAddressCommunication?.identifier,
			};
		});
	const { userId } = validateToken();

	const classes = useStyles();

	const handleSwitchListingNotification = e => {
		const isChecked = e?.target?.checked;
		const message = isChecked
			? 'You have successfully subscribed to receive Send Base Listing emails'
			: 'You have successfully unsubscribed from receiving Send Base Listing emails';

		setListingNotificationSubscription({
			message,
			enabledIndicator: isChecked,
			hasChecked: true,
		});
		setLoading(true);
	};


	useEffect(() => {
		const subscriptions = getSubscriptions(userId);
		subscriptions
			.then(availableSubscriptions => {
				setSubscriptions(availableSubscriptions);
				setFechSubscriptionLoading(false);
				setListingNotificationSubscription({
					...listingNotification,
					enabledIndicator:
						availableSubscriptions[0]?.subscription?.enabledIndicator,
				});
			})
			.catch(error => {
				setFechSubscriptionLoading(false);
			})
			.finally(() => setFechSubscriptionLoading(false));
	}, [listingNotification?.hasPosted]);

	useEffect(() => {

		const updateSwitch = () => {
			setListingNotificationSubscription({...listingNotification, enabledIndicator: false})
			return false;
		}
		let payload = {
			id: subscriptions[0]?.id || '',
			subscription: {
				identifier: subscriptions[0]?.id || '',
				channelCode: 'Email',
				user: {
					identifier: userIdentifier || '',
					userAccount: {
						accountIdentifier: {
							content: userMilkShedName || '',
							typeCode: 'Milkshed',
						},
					},
				},
				enabledIndicator: (!email && listingNotification?.enabledIndicator) ? updateSwitch() : listingNotification?.enabledIndicator ,
				topicIdentifier: 'Catalog / Listing Notification',
				transactionAudit: {
					creationDateTime: '2022-02-10T23:20:49.3812061Z',
					createdByUserIdentifier: '0033600000bPAf7AAG',
					lastModificationDateTime: '2022-02-10T23:20:49.3812079Z',
					lastModificationByUserIdentifier: '0033600000bPAf7AAG',
				},
			},
		};

		if (!fetchSubscriptionLoading) {
			if (
				email &&
				subscriptions?.length === 0 &&
				listingNotification?.enabledIndicator && listingNotification?.hasChecked
			) {
				postSubscriptions(payload)
					.then(subscriptionAdded => {
						setLoading(false);
						setListingNotificationSubscription({
							...listingNotification,
							hasChecked: false,
							hasPosted: true
						});
					})
					.finally(() => {
						setLoading(false);
					});
			} else if(subscriptions[0]?.id && (!email || listingNotification.hasChecked)){
				updateSubscriptions(payload?.id, payload)
					.then(updatedExistingSubscription => {
						setLoading(false);
						setListingNotificationSubscription({
							...listingNotification,
							hasChecked: false
							
						});
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	}, [listingNotification?.enabledIndicator]);

	return (
		<section className="content">
			<h1>Profile</h1>
			<p>Manage your profile and your email notification</p>
			<Container className="box-container">
				<h2>Contact Information</h2>
				<p>
					<Link
						key="contact-us-nav"
						className="contact-us-nav"
						to="/contact-us"
					>
						Contact Us{' '}
					</Link>{' '}
					if your information is not correct
				</p>
				<p>
					<strong>Name:</strong> {name}
				</p>
				<div>
					{(phoneNumbers?.length === 0 && (
						<p>
							<strong>Phone: </strong>None
						</p>
					)) ||
						phoneNumbers?.map((phoneNo, index) => {
							return (
								<p key={index + phoneNo?.identifier}>
									<strong>{phoneNo?.typeCode}: </strong>{' '}
									{formatPhoneNumberWithoutTypeCode(phoneNo?.identifier)}
								</p>
							);
						})}
				</div>
				<p>
					<strong>Email:</strong> {email || 'None'}
				</p>
			</Container>
			<Container className="box-container">
				<h1>Subscriptions</h1>

				<FormGroup>
					{fetchSubscriptionLoading || loading ? (
						<p>Loading...</p>
					) : (
						<>
							{!email && <p>{'Email address required for subscriptions'}</p>}
							<FormControlLabel
								control={
									<Switch
										defaultChecked={
											listingNotification.enabledIndicator
										}
										onChange={handleSwitchListingNotification}
										disabled={!email}
										checked={listingNotification?.enabledIndicator}
										className={classes.switch}
									/>
								}
								label="Listing Notifications – Send Base"
								labelPlacement="end"
							/>
							<p>
								{listingNotification?.enabledIndicator
									? 'Subscribed'
									: 'Unsubscribed'}
							</p>
							<p>{listingNotification?.message}</p>
						</>
					)}
				</FormGroup>
			</Container>
		</section>
	);
}

export default ProfilePage;
