import * as types from "../actions/actionTypes";

export default function saveMilkShedsReducer(state = {}, { type, milkSheds }) {
  switch (type) {
    case types.SAVE_MILK_SHEDS:
      return {
        ...state,
        milkSheds,
      }
    default:
      return state;
  }

}
