import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getContact(UserId) {
    let response = await NetworkService.getData(ApiUrl.getContact(UserId));
    return response;
}

export async function getContactFarm(farmId) {
    let response = await NetworkService.getData(ApiUrl.getContactFarm(farmId));
    return response;
}

export async function getContactFarmAuthorizations(contactId) {
    let response = await NetworkService.getData(ApiUrl.getContactFarmAuthorizations(contactId));
    return response;
}

export async function sendEmail(requestBody) {
    let response = await NetworkService.postData(ApiUrl.sendEmailURL(), requestBody);
    return response;
}


