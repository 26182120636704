import React from "react";
import { FormControlLabel, Switch,makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  switch: {
    "& .Mui-checked": {
      color: "#81B7C4",
     transform: "translateX(25px) !important"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#010c2a !important"
    }
  }
});

export default function FormSwitch({ 
  name,
  label = name,
  placeholder,
  margin = "normal",
  variant = "outlined",
  color = "#010c2a",
  options,
  ...props
}) { 
  const classes = useStyles();

  return (
    <FormControlLabel 
      label={label}
      control={         
        <Switch   
          className={classes.switch}
          color={color}
          onChange={(event) => {
            let temp = { ...props.data.formdata };
            temp[props.data.id] = event.target.checked; 
            props.data.setformdata(temp);
            props.onChange(event.target.checked)}
          }
          checked={props.data.formdata[props.data.id]}
        />       
      }
      
    ></FormControlLabel>
  );
}
