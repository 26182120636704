import { RECEIVE_BASE } from "../../common/constants/constants";


export const createInitiateTransferPayload = ({ senderFarmDetails, receivingFarmUID, 
    receivingFarmName, selectedContact,
    mobileTypeCode, formdata,
    otherNumber, phoneNumber,
    contactIdentifier,formatPhoneNumber,
    typeCode
   }) => {
  const catalogLine = senderFarmDetails?.catalogLine;

  let requestPayload = {
    "id": "",
    "onlineOrder": {
        "typeCode": typeCode + ' Transfer',
        "onlineOrderHeader": {
            "identifier": "",
            "revisionIdentifier": "",
            "lastModificationDateTime": new Date(),
            "documentDateTime": new Date(),
            "documentReference": [
              {
                "identifier": "",
                "typeCode": ""
              }
            ],
            "status": {
              "code": "Submitted"
            },
            "extension": [{
              "lastModificationByUserIdentifier": contactIdentifier
            }],
            "sessionIdentifier": {
                "content": "",
                "typeCode": ""
            }
        },
        "onlineOrderLine": {
            "description": "",
            "note": "",
            "item": {
                "identifier": catalogLine?.lineNumberIdentifier,
                "specification": {
                    "property": {
                        "effectiveTimePeriod": {
                          "startDateTime":formdata.effectiveTransferDate
                        }
                    },
                    "extension": [
                        {
                            "indicator": {
                                "content": true,
                                "typeCode": "Accept Terms"
                            }
                        }
                    ]
                }
            },
            "quantity": {
                "content": catalogLine?.quantity.content,
                "typeCode": "BASE",
                "unitCode": "LBS"
            },
            "supplierParty": {
                "identifier": catalogLine?.party?.identifier,
                "name": catalogLine?.party?.name.trim(),
                "location": {
                    "physicalAddress": [
                        {
                          "countrySubDivisionCode": [
                            {
                              "content": catalogLine?.party?.location?.physicalAddress[0]?.countrySubDivisionCode[0]?.content,
                              "typeCode": catalogLine?.party?.location?.physicalAddress[0]?.countrySubDivisionCode[0]?.typeCode
                            },
                            {
                              "content": catalogLine?.party?.location?.physicalAddress[0]?.countrySubDivisionCode[1]?.content,
                              "typeCode": catalogLine?.party?.location?.physicalAddress[0]?.countrySubDivisionCode[1]?.typeCode
                            }
                          ]
                        }
                    ],
                    "classification": [
                        {
                          "codes": {
                            "code": [
                                {
                                  "content": catalogLine?.party?.location?.classification[0]?.codes?.code[0]?.content,
                                  "typeCode": catalogLine?.party?.location?.classification[0]?.codes?.code[0]?.typecode,
                                }
                            ]
                          }
                        }
                    ]
                },
                "contact": {
                    "identifier": catalogLine?.party?.contact?.identifier,
                    "personName": {
                      "formattedName": catalogLine?.party?.contact?.personName?.formattedName
                    },
                    "telephoneCommunication": {
                      "typeCode": catalogLine?.party?.contact?.telephoneCommunication[0]?.typeCode,
                      "identifier": formatPhoneNumber(catalogLine?.party?.contact?.telephoneCommunication[0]?.identifier),
                    },
                    "emailAddressCommunication": {
                      "identifier": catalogLine?.party?.contact?.emailAddressCommunication?.identifier,
                    }
                }
            },
            "party": {
              "identifier": receivingFarmUID,
              "name": receivingFarmName,
              "contact": {
                  "identifier": selectedContact?.id,
                  "personName": {
                    "formattedName": formdata?.contactName
                  },
                  "telephoneCommunication": {
                    "typeCode": mobileTypeCode,
                    "identifier": formdata?.phoneNo === 'Other' ? otherNumber : phoneNumber,
                  },
                  "emailAddressCommunication": {
                    "identifier": formdata?.email === 'Other' ? formdata?.other_email : formdata?.email
                  }
              }
            }
        }
    }
  }

  let supplierParty = JSON.parse(JSON.stringify(requestPayload?.onlineOrder?.onlineOrderLine?.supplierParty))
  let party = JSON.parse(JSON.stringify(requestPayload?.onlineOrder?.onlineOrderLine?.party))


  if(typeCode === RECEIVE_BASE) {
    requestPayload = {...requestPayload,
       onlineOrder: {...requestPayload.onlineOrder,
         onlineOrderLine: {...requestPayload.onlineOrder.onlineOrderLine,
           supplierParty: party,
            party: supplierParty
          }
        }
      }
  }

  return requestPayload;
}