import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { getGeoCode } from "../../../common/services/geocodeService";
import { handleError } from "../../../utils/errorUtil";
import { useHistory } from "react-router-dom";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const BoxAlt = styled(Box)`border:0px`;
const containerStyle = {
    width: '100%',
    height: '280px'
}
function HeaderChild() {
    const history = useHistory();
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const senderFarmDetails = useSelector(state => state?.senderFarmDetails?.senderFarmDetails);

    let countyandState = ''
    

    const defaultMapOptions = {
        fullscreenControl: false,
        disableDefaultUI: true,
        gestureHandling: 'none',
        clickableIcons: false
        
      };

    countyandState = senderFarmDetails.catalogLine.party.location.physicalAddress[0].countrySubDivisionCode[0].content;
    countyandState = countyandState + ", " + senderFarmDetails.catalogLine.party.location.physicalAddress[0].countrySubDivisionCode[1].content;
    countyandState = countyandState + ", USA";
    useEffect(() => {
        getGeoCode(countyandState).then((geocodeLocation) => {
            try {
                setLat(geocodeLocation[0].lat);
                setLng(geocodeLocation[0].lng);
            } catch (error) {
                console.error();
            }
        }).catch(error => {
            handleError(error, history);
        });
    }, []);
    return (
        <>        
            <BoxAlt component="span" className="modal-flyout-map-wrapper">
                <LoadScript googleMapsApiKey={API_KEY}>
                    <GoogleMap
                        id="my-map"
                        zoom={11}
                        disableDefaultUI={true}
                        mapContainerStyle={containerStyle}
                        center={{
                            lat,
                            lng
                        }}
                        options={defaultMapOptions}
                    >
                    </GoogleMap>
                </LoadScript>
            </BoxAlt>
        </>
    );
}
export default HeaderChild;