import React from "react";
import { Typography } from "@mui/material";

export default function FormTypography({
  color,
  ...props
}) {
  return (
    <Typography
      style={{ fontSize: "14px", color: color, margin: "0px 0px 0px 0px" }}>
      {props.error.formerror[props.error.id]}
    </Typography>
  );
}
