import React from 'react';
import { GlobalHeader } from '../components/common/header/GlobalHeader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useHeader = () => {
  const userName = useSelector((state) => {
    return state?.user?.userName
  });

  const { userMilkShedName } = useSelector(state => {
    return state.userMilkShedName;
  });

  const location = useLocation();

  const withoutHeaderRoutes = ['/', '/auth', '/term-and-condition', '/contact-us-signin', '/authorize', '/sign-in-help','/rules-engagement','/message-sent-signin'];

  if (withoutHeaderRoutes.includes(location.pathname)) {
    return null;
  }

    return <GlobalHeader currentUser={userName} milkShedName={userMilkShedName} />
}