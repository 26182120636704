import React, { useEffect,  useState } from 'react';
import { getRequiredDocuments } from '../common/services/dynamicPages.service';
import { handleError } from '../utils/errorUtil';
import { useHistory } from "react-router-dom";

const FAQSideBar = () => {
  const history = useHistory();
  const [rawHTML, setrawHTML] = useState(`
    <div>
      Loading...
    </div>
  `);

  useEffect(() => {
    let doctitile = "Marketplace FAQs";
    getRequiredDocuments([]).then((docsresponse) => {
      docsresponse.forEach((doc) => {
        if (doc.title === doctitile) {
          setrawHTML(doc.content);
        }
      })
    }).catch(error => {
      handleError(error,history);
    });
  }, []);

  return (    
    <section className="content page-faq">
      <h1>Frequently Asked Questions</h1> 
      <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>        
    </section>    
  )
}

export default FAQSideBar;