import React from "react";
import { useSelector } from "react-redux";
import CheckboxLabels from "../components/TermAndCondition/TermAncConditionCheckbox";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { postUserConsent } from "../common/services/userconsent.service";
import PublicLogoBoxes from "../components/PublicLogoBoxes/PublicLogoBoxes";
import { handleError } from '../utils/errorUtil'
import { validateToken } from "../utils/tokenUtil";

const TermsAndConditionsPage = () => {
  const history = useHistory();
  const lastModificationByUserIdentifier = useSelector(state => state?.contacts?.id);
  const rawHtmlContent = useSelector(state => state?.termsRules?.termsContent);
  const rawHtmlVersion = useSelector(state => state?.termsRules?.termsVersion);
  const rawHtmlRulesVersion = useSelector(state => state?.termsRules?.rulesVersion);
  const { userId } = validateToken();
  const isTermsAndConditionsChecked = useSelector(state => state?.termsRules?.checked);
  const submit = () => {
    const userConsent = {
      "userConsent": {
        "identifier": "",
        "identifierSet": { "identifier": userId },
        "effectiveTimePeriod": { "startDateTime": "" },
        "usageConsentIndicator": { "content": true, "typeCode": "isAccepted" },
        "title": "Marketplace Terms and Conditions", "systemIdentifier": "Marketplace",
        "versionIdentifier": rawHtmlVersion,
        "lastModificationByUserIdentifier": lastModificationByUserIdentifier,
        "lastModificationDateTime": ""
      }
    }
    postUserConsent(userConsent).then((response) => {

      if (rawHtmlRulesVersion && isTermsAndConditionsChecked) {
         
         history.push('/rules-engagement')
      }
      else {
        history.push('/home-page')
      }

    }).catch(error => {
      handleError(error,history);
    })
  }
  const handleBackClick= async ()=>{
    localStorage.clear()
    await  history.push('/auth')

  
  }
  return (
    <div className="public-view term-and-condition-container">
      <PublicLogoBoxes />
      <div className="content term-and-condition">
        <h1> Terms and Conditions </h1>
        <Box className="box-container">
          <div className="login-legal-container">
          <div dangerouslySetInnerHTML={{ __html: rawHtmlContent }}></div>
            <Box className="legal-acceptance-box">
              <CheckboxLabels />
            </Box>
            
          </div>
        </Box>
        <Grid container className="form-button-container">
            <Grid item xs={11} sm={5}>      
            <Button  variant="contained" className="button" onClick={handleBackClick}>Back</Button>        
            </Grid>
            <Grid item xs={11} sm={4}>      
            </Grid>
            <Grid item xs={11} sm={2} className="form-button-container-right">
            <Button disabled={!isTermsAndConditionsChecked} variant="contained" className="button" onClick={submit}>Submit</Button>
            </Grid>
          
          </Grid>
        
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;