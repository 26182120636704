import React, { useState } from "react";
import { Card, CardContent } from "@mui/material";
import ListingDetails from "../ListingHeaderChild/ListingDetails";
import { numberWithCommas } from '../../../utils/numberFormat';
import { getFormattedDate } from '../../../utils/dateUtil';
import { ALL_MILKSHEDS, SEND_BASE } from "../../../common/constants/constants";


export const ListingCard = ({id, userMilkShed, milkShedDesc,  baseType}) => {

  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const [openListingDetails, setOpenListingDetails] = useState(false);

  const closeDrawer = () => {
    setOpenListingDetails(false);
  }
  const catalogLine = userMilkShed?.catalogLine;
  let state = catalogLine.party.location.physicalAddress[0].countrySubDivisionCode[1].content;
  let county = catalogLine.party.location.physicalAddress[0].countrySubDivisionCode[0].content;

  state = state.replace(/\s+/g, '+').toLowerCase();
  county = county.replace(/\s+/g, '+').toLowerCase();

  const baseClass = () => {
    return baseType == SEND_BASE ? "card-media base-send": "card-media base-receive";
  }
  return (
    <>
      <Card
        key={id}
        className={`listing-card ${milkShedDesc ? "hover-disabled disabled" : ""}`}
        elevation={0}
        onClick={() => {
          if (milkShedDesc !== ALL_MILKSHEDS) {
            setOpenListingDetails(true);
          }
        }}>

        {/*TEMP: Commenting out static image to reduce API requests --> <div className="card-media" style={{backgroundImage: 'url(https://maps.googleapis.com/maps/api/staticmap?center=' + county + "," + state + '&maptype=road&zoom=11&size=275x125&scale=2&key=' + API_KEY}} >          </div>*/}
        <div className={baseClass()}>{baseType}</div>
        <CardContent className="card-content">
          <h3>
            {numberWithCommas(catalogLine?.quantity?.content) + ' '}
            <span className="postfix">
              {catalogLine?.quantity?.unitCode?.toLowerCase() + 's.'}
            </span>
          </h3>
          <p className="farm-name">
            {catalogLine.party.name}
          </p>
          <p className="milkshed">
            {
              catalogLine?.party?.location?.classification[0]?.codes?.code[0]?.content + " " + catalogLine?.party?.location?.classification[0]?.codes?.code[0]?.typeCode
            }
          </p>

          {
            (catalogLine?.extension[0]?.status?.code !== 'Open') && (
              <p className="status status-pending">Transfer Pending</p>
            ) ||
            <p className="status">
              {getFormattedDate(catalogLine?.lastModificationDateTime || '')}
            </p>
          }
        </CardContent>
      </Card>
      <ListingDetails
        page="InitiateTransfer"
        open={openListingDetails}
        closeDrawer={closeDrawer}
        data={userMilkShed}
        date={getFormattedDate(catalogLine?.item?.specification?.property?.effectiveTimePeriod?.startDateTime || '')}
        baseType={baseType}
      >
      </ListingDetails>
    </>
  )
}

export default ListingCard;