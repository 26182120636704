import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CurrentListing } from './CurentListing';

import Box from '@mui/material/Box';
import {
	USER_MILKSHED,
	ALL_MILKSHEDS,
	SEND_BASE,
	VIEW_ALL,
	RECEIVE_BASE,
} from '../../../common/constants/constants';

export const MarketPostList = ({ userMilkSheds, changedBase }) => {
	const { userMilkShedName } = useSelector(state => state.userMilkShedName);
	const milkShedsData = useSelector(state => state?.milkSheds);

	const [user, setUser] = useState([]);
	const [all, setAll] = useState([]);
	const [milksheddesc, setMilksheddesc] = useState('');
	const [activeTab, setActiveTab] = useState(USER_MILKSHED);
	const [milkSheds, setMilkSheds] = useState(userMilkSheds);

	function isUserMilkShed(content) {
		return content === userMilkShedName;
	}
	const filterListings = () => {
		let milkShedDes;
		if (changedBase === VIEW_ALL) {
			if (activeTab === USER_MILKSHED) {
				return milkSheds.filter(milkShed => {
					let listingTypeCode =
						milkShed.catalogLine.party.location.classification[0].codes.code[0];
						if(isUserMilkShed(listingTypeCode?.content)) {
							milkShedDes = userMilkShedName + ' ' +listingTypeCode?.typeCode
						}
					return isUserMilkShed(listingTypeCode?.content)
				});
			} else if (activeTab === ALL_MILKSHEDS) {
				return milkSheds.filter(milkShed => {
					let listingTypeCode =
						milkShed.catalogLine.party.location.classification[0].codes.code[0];
					return !isUserMilkShed(listingTypeCode?.content);
				});
			}
		} else if(changedBase === SEND_BASE) {
			if(activeTab === USER_MILKSHED) {
				return milkSheds.filter((milkShed) => {
					let listingTypeCode = milkShed.catalogLine.party.location.classification[0].codes.code[0];
					return milkShed?.catalogLine?.typeCode === SEND_BASE && isUserMilkShed(listingTypeCode?.content)
				})
			} else if(activeTab === ALL_MILKSHEDS) {
				return milkSheds.filter((milkShed) => {
					let listingTypeCode = milkShed.catalogLine.party.location.classification[0].codes.code[0];
					return milkShed?.catalogLine?.typeCode === SEND_BASE && !isUserMilkShed(listingTypeCode?.content)

				})
			}
		} else if(changedBase === RECEIVE_BASE) {
			if(activeTab === USER_MILKSHED) {
				return milkSheds.filter((milkShed) => {
					let listingTypeCode = milkShed.catalogLine.party.location.classification[0].codes.code[0];
					return milkShed?.catalogLine?.typeCode === RECEIVE_BASE && isUserMilkShed(listingTypeCode?.content)
				})
			} else if(activeTab === ALL_MILKSHEDS) {
				return milkSheds.filter((milkShed) => {
					let listingTypeCode = milkShed.catalogLine.party.location.classification[0].codes.code[0];
					return milkShed?.catalogLine?.typeCode === RECEIVE_BASE && !isUserMilkShed(listingTypeCode?.content)

				})
			}
		}

		if (userMilkSheds.length < 1) {
				setMilksheddesc(
					`${userMilkShedName} ${milkShedsData?.milkSheds[0]?.partyMaster?.partyClassification?.codes.code[0].typeCode}`
				);
			} 
	};

	useEffect(() => {
		
		setUser(sortMilksheds(filterListings()));
	}, [changedBase, activeTab]);


	useEffect(() => {
		let userMilkSheds = [];
		let allMilkSheds = [];

		if (userMilkShedName && milkShedsData) {
			milkSheds.forEach(item => {
				// Todo: this is bad desgin / smell, changing the props, using push instead it should be imutablle
				let content =
					item.catalogLine.party.location.classification[0].codes.code[0]
						.content;
				if (content === userMilkShedName) {
					setMilksheddesc(
						userMilkShedName +
							' ' +
							item.catalogLine.party.location.classification[0].codes.code[0]
								.typeCode
					);
					userMilkSheds.push(item);
				} else allMilkSheds.push(item);
			});
			if (userMilkSheds.length < 1) {
				setMilksheddesc(
					`${userMilkShedName} ${milkShedsData?.milkSheds[0]?.partyMaster?.partyClassification?.codes.code[0].typeCode}`
				);
			} else setUser(sortMilksheds(userMilkSheds));

			setAll(sortMilksheds(allMilkSheds));
		}
	}, [userMilkShedName]);

	const switchTab = (e, tab) => {
		e.preventDefault();
		setActiveTab(tab);
	};

	const sortMilksheds = milkSheds => {
		return milkSheds.sort(
			(a, b) =>
				new Date(b.catalogLine.lastModificationDateTime) -
				new Date(a.catalogLine.lastModificationDateTime)
		);
	};

	const getNoListingError = () => {
		if (changedBase != VIEW_ALL) {
			if(user.length === 0) {
				if (activeTab === ALL_MILKSHEDS) {
				return (
					<p>{`There are no ${changedBase} listings within other milksheds.`}</p>
				);
			} else {
				return (
					<p>{`There are no ${changedBase} listings within your milkshed.`}</p>
				);
			}
			}
			
		} else if (activeTab === USER_MILKSHED && user.length===0) {
			return <p>There are no current listings within your milkshed.</p>
		} else if(user.length===0){
			return <p>There are no listings within other milksheds.</p>;
		}
	};

	return (
		<>
			<Box className="manage-transfer-nav-items">
				<span
					className={
						(activeTab === USER_MILKSHED && 'nav-tab-item active-tab') ||
						'nav-tab-item'
					}
					onClick={e => switchTab(e, USER_MILKSHED)}
				>
					{milksheddesc}
				</span>
				<span
					className={
						(activeTab === ALL_MILKSHEDS && 'nav-tab-item active-tab') ||
						'nav-tab-item'
					}
					onClick={e => switchTab(e, ALL_MILKSHEDS)}
				>
					{ALL_MILKSHEDS}
				</span>
			</Box>
			{activeTab === ALL_MILKSHEDS && (
				<p>
					This section is for reference only. You will not be able to view or
					transfer base from these members.
				</p>
			)}
			{getNoListingError() || (
				<section className="content-section">
					<div className="container">
						<CurrentListing
							userMilkSheds={user}
							milkShedDesc={activeTab === USER_MILKSHED ? '' : ALL_MILKSHEDS}
							changedBase={changedBase}
							tab={activeTab}
						></CurrentListing>
					</div>
				</section>
			)}
		</>
	);
};

export default MarketPostList;
