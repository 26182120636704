export const receiverColumns = [
	{
		key: 'status',
		field: 'status',
		headerName: 'STATUS',
		type: 'string',
		minWidth: 140,
		width: 140,
		renderCell: ({ row }) => {
			return (
				<div
					style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
					className={row?.status?.toLowerCase()}
				>
					{row?.status}
				</div>
			);
		},
	},
	{
		key: 'transferDate',
		field: 'transferDate',
		headerName: 'TRANSFER DATE',
		minWidth: 138,
		flex: 1,
		type: 'date',
	},
	{
		key: 'listingType',
		field: 'listingType',
		headerName: 'LISTING TYPE',
		minWidth: 138,
		flex: 1,
		type: 'string',
	},
	{
		key: 'listingDetails',
		field: 'listingDetails',
		headerName: 'LISTING DETAILS',
		minWidth: 200,
		flex: 1,
		type: 'string',
		renderCell: params => {
			return <span>Base Transfer {params.row.listingDetails} lbs/day</span>;
		},
	},
	{
		key: 'receiver',
		field: 'receiver',
		headerName: 'RECEIVER',
		minWidth: 150,
		flex: 1,
		type: 'string',
	},
	{
		key: 'sender',
		field: 'sender',
		headerName: 'SENDER',
		minWidth: 140,
		flex: 1,
		type: 'string',
	},
	{
		key: 'manage',
		field: 'manage',
		headerName: 'MANAGE',
		type: 'string',
		width: 140,
		minWidth: 140,
		disableClickEventBubbling: true,
		renderCell: params => {
			return (
				<div
					style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
				>
					Show Details{' '}
					<i
						style={{ fontSize: '24px' }}
						className="ag-icon ag-icon-chevron-right"
					></i>
				</div>
			);
		},
	},
];

export const senderColumns = [
	{
		key: 'status',
		field: 'status',
		headerName: 'STATUS',
		type: 'string',
		width: 140,
		renderCell: ({ row }) => {
			return (
				<div
					style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
					className={row?.status?.toLowerCase()}
				>
					{row?.status}
				</div>
			);
		},
	},
	{
		key: 'transferDate',
		field: 'transferDate',
		headerName: 'POSTED DATE',
		minWidth: 138,
		flex: 1,
		type: 'date',
	},
	{
		key: 'listingType',
		field: 'listingType',
		headerName: 'LISTING TYPE',
		minWidth: 138,
		flex: 1,
		type: 'string',
	},
	{
		key: 'listingDetails',
		field: 'listingDetails',
		headerName: 'LISTING DETAILS',
		minWidth: 200,
		flex: 1,
		type: 'string',
		renderCell: params => {
			return <span>Base Transfer {params.row.listingDetails} lbs/day</span>;
		},
	},
	{
		key: 'sender',
		field: 'sender',
		headerName: 'SENDER',
		minWidth: 140,
		flex: 1,
		type: 'string',
	},
	{
		key: 'receiver',
		field: 'receiver',
		headerName: 'RECEIVER',
		minWidth: 150,
		flex: 1,
		type: 'string',
	},
	{
		key: 'manage',
		field: 'manage',
		headerName: 'MANAGE',
		type: 'string',
		width: 140,
		minWidth: 140,
		disableClickEventBubbling: true,
		renderCell: params => {
			return (
				<div
					style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
				>
					Show Details{' '}
					<i
						style={{ fontSize: '24px' }}
						className={'ag-icon ag-icon-chevron-right'}
					></i>
				</div>
			);
		},
	},
];
