import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Box, Button, Link } from '@material-ui/core';

import {
	FormTextField,
	FormCheckbox,
	FormSelect,
	FormSwitch,
	FormTypography,
	FormDatepicker,
	Maskinput,
} from '../common/form';

function FormButtons({ disabled, updateListing, handleCancel }) {
	return (
		<Grid container className="form-button-container">
			<Grid item xs={12} sm={6}>
				<Button className="button" disabled={disabled} type="submit">
					{updateListing ? 'Save Changes' : 'Create Listing'}
				</Button>
			</Grid>
			<Grid item xs={12} sm={6} className="form-button-container-right">
				<Button className="button grey" onClick={handleCancel}>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);
}

export default FormButtons;
