import React from 'react';
import { SEND_BASE } from '../../common/constants/constants';
function ListingHeadings({ updateListing, baseType }) {
	return (
		<>
			<h1>
				{updateListing
					? 'Edit Listing'
					: 'Create a Listing - ' + baseType}
			</h1>
			<p>
				<span>
					{baseType == SEND_BASE &&
						' This listing type will result in your chosen farm decreasing its daily Base pounds.'
						|| ' This listing type will result in your chosen farm increasing its daily Base pounds.'
					
					}
				</span>
				<br></br>
				Please complete all fields below for your Marketplace listing.
			</p>
		</>
	);
}

export default ListingHeadings;
