import * as types from "../actions/actionTypes";

export default function saveTermsRulesContentAndVersion(state = {}, action) {
  switch (action.type) {
    case types.SAVE_TERMS_CONTENT_VERSION:
      return { 
        ...state,
        termsContent: action.payload.docContent,
        termsVersion: action.payload.docVersion
      }
    case types.SAVE_RULES_CONTENT_VERSION:
      return { 
        ...state,
        rulesContent: action.payload.docContent,
        rulesVersion: action.payload.docVersion
      }
      case types.SAVE_TERMS_CONDITIONS_CHECK:
      return { ...state, checked: action.checked }
    default:
      return state;
  }
}
