import { getEnvironment } from '../../utils/utility'

export const ENVIRONMENT = {
    DEV: 'DEV',
    QA: 'QA',
    LOCAL: 'LOCAL',
    PROD: 'PROD',
}

export const LOGOUT_REDIRECT_URI = () => {
    const ENV = getEnvironment();
    let url = '';
    if (ENV === ENVIRONMENT.PROD) {
        url = '';
    } else if (ENV === ENVIRONMENT.QA) {
        url = '';
    } else if (ENV === ENVIRONMENT.DEV) {
        url = '';
    } else {
        url = 'http://localhost:3001';
    }
    return url;
}

export const SSO_BASE_PATH = () => {
    const ENV = getEnvironment();
    let url = '';
    if (ENV === ENVIRONMENT.PROD) {
        url = 'https://sso.landolakesinc.com';
    } else if (ENV === ENVIRONMENT.QA) {
        url = 'https://ssoqa.landolakesinc.com';
    } else if (ENV === ENVIRONMENT.DEV) {
        url = 'https://ssoqa.landolakesinc.com';
    } else {
        url = 'https://ssoqa.landolakesinc.com';
    }
    return url;
}

export const SNACKBAR_TYPE = {
    'ERROR': 'error',
    'WARNING': 'warning',
    'DEFAULT': 'default',
    'SUCCESS': 'success'
}

export const appConstants = { LoginSessionKey: 'LoginSessionKey', userId: "userId" }


export const RECEIVER = 'RECEIVER';
export const SENDER = 'SENDER';
export const STATUS = 'Open;Pending;Signed;Cancelled;Completed;Submitted;Deleted;Pending Cancel;Expired;Reconciled';
export const REQUIRED_PERCENTILE_FOR_AVAILABLE_BASE = 0.8;
export const TIME_OUT_FOR_REDIRECT_TO_HOME_PAGE = 15 * 60 * 1000;
export const SEND_BASE = "Send Base";
export const RECEIVE_BASE = "Receive Base";
export const ALL_MILKSHEDS = "All Milksheds";
export const VIEW_ALL = "View All";
export const USER_MILKSHED = "USER_MILKSHED";
export const CREATE_NEW_LISTING = "Create New Listing";
export const RECEIVING_FARM = "Receiving Farm"
export const SENDING_FARM = "Sending Farm"

export const CREATE_LISTING_TERM_AND_CONDITION_TEXT = `By creating this listing, I agree the contact information
											provided is accurate and may be shared with those within
											my Milkshed on the Marketplace. All dialogue with other
											Land O’Lakes member-owners towards the details of this
											Base transfer will occur outside of the Marketplace. When
											creating this listing, I acknowledge it will remain active
											for 60 days before expiration, and I will have the ability
											to edit the listing to reflect the accurate amount of
											daily Base I am offering. I have read and understand the `;

 export const getCreateListingTermAndConditionText1 = (baseType) => {
     if(baseType === RECEIVE_BASE) {
         return `By creating this listing, I agree the contact information provided is accurate and may be shared with those within my Milkshed on the Marketplace. All dialogue with other Land O’Lakes member-owners towards the details of this Base transfer will occur outside of the Marketplace. When creating this listing, I acknowledge it will remain active for 60 days before expiration, and I will have the ability to edit the listing to reflect the accurate amount of daily Base I am looking to receive. I have read and understand the `
     } else {
         return CREATE_LISTING_TERM_AND_CONDITION_TEXT
     }
 }                                           



let dollarUSLocale = Intl.NumberFormat('en-US');

export const formatCurrentBase = (currentBase) => {
	return 'Current Base           ' + dollarUSLocale.format(currentBase) +' Lbs/Day'
}





