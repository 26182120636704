import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { RECEIVER, SENDER } from '../../common/constants/constants';
import { Link } from 'react-router-dom';

export default function ManageTransfer({ rows, columns, tab, showDetails }) {
	let data = rows?.map((row, index) => {
		return { ...row, key: index + row?.status, manage: 'Show Details' };
	});

	const onCellClick = colData => {
		if (colData.field === 'manage') {
			showDetails(colData?.row);
		}
	};

	const [sortModel, setSortModel] = React.useState([
		{
			field: 'transferDate',
			sort: 'desc',
		},
	]);

	const getTheTabText = () => {
		if (tab === SENDER) {
			return (
				<p>
					All listings will be displayed once they are created in the system. If
					you are experiencing a delay, please submit a{' '}
					<Link
						key="contact-us-nav"
						className="contact-us-nav"
						to="/contact-us"
					>
						Contact Us form.{' '}
					</Link>
				</p>
			);
		} else {
			return (
				<p>
					All transfers will be displayed once they are created in the system.
					If you are experiencing a delay, please submit a{' '}
					<Link
						key="contact-us-nav"
						className="contact-us-nav"
						to="/contact-us"
					>
						Contact Us form.{' '}
					</Link>
				</p>
			);
		}
	};

	return (
		<>
			{data?.length > 0 ? (
				<DataGrid
					className="data-grid-table full-width"
					rows={data}
					columns={columns}
					headerHeight={40}
					autoHeight
					disableSelectionOnClick
					pagination
					pageSize={10}
					showColumnRightBorder
					onCellClick={onCellClick}
					sortModel={sortModel}
					onSortModelChange={model => setSortModel(model)}
				/>
			) : (
				<Box>
					<h2>
						You have no {tab === SENDER ? ' outgoing' : ' inbound'}
						{' transfers'}
					</h2>
					{getTheTabText()}
				</Box>
			)}
		</>
	);
}

ManageTransfer.propTypes = {
	rows: PropTypes.array.isRequired,
	columns: PropTypes.array.isRequired,
	tab: PropTypes.string.isRequired,
	showDetails: PropTypes.func.isRequired,
};