import * as types from "../actions/actionTypes";

export default function refreshDataReducer(state = {}, { type, lastRefreshDateTime }) {
  switch (type) {
    case types.REFRESH_DATA_ACTION:
      return {
        ...state,
        lastRefreshDateTime
      }
    default:
      return state;
  }
}
