import * as types from "../actions/actionTypes";

export default function saveMilkShedNameReducer(state = {}, { type, userMilkShedName }) {
  switch (type) {
    case types.SAVE_USER_MILK_SHED_NAME:
      return { 
        ...state, 
        userMilkShedName 
      }
    default:
      return state;
  }
}
