import React from "react";
import FarmCard from "../../components/common/FarmCard";
import { useFarmDetails } from "../../hooks/useFarmDetails";

const FarmCardPage = () => {
  const { loading , farmDetails } = useFarmDetails();
  return (
		<>
			<h5 className="farm-details">FARM DETAILS</h5>
			<section className="farm-detail-section">
				{loading
					? '...Loading...'
					: farmDetails.map((item, index) => {
							return <FarmCard key={index} farms={item} />;
					  })}
			</section>
		</>
	);
}
export default FarmCardPage;