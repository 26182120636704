import React from "react";
import { FormControl, Select, InputLabel} from "@mui/material";

export default function FormSelect({  
  name,
  defaultValue,
  label = name,
  placeholder,
  option,
  disabled,
  variant = "outlined",
  ...props
}) {
     // const getDisabled = val => {
     //   if (val) return { disabled: true };
     //   return {};
     // };
     return <FormControl variant={variant} fullWidth className="form-control-select-menu">
         <InputLabel shrink={true}>
           {label}
         </InputLabel>
         <Select disabled={disabled} label={label} native placeholder={placeholder} defaultValue={defaultValue} variant={variant} MenuProps={{ getContentAnchorEl: null }} onChange={event => {
             if (event.target.value) {
               let temp = { ...props.data.formdata };
               temp[props.data.id] = event.target.value;
               props.data.setformdata(temp);
             }
             props.onChange(event);
           }} value={defaultValue || props.data.formdata[props.data.id]} className="select-menu">
           {props.children}
         </Select>
       </FormControl>;
   }
