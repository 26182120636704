import * as types from "../actions/actionTypes";

export default function saveFarmWithGUIDReducer(state = {}, { type, farmsWithGUID }) {

  switch (type) {
    case types.SAVE_FARM_WITH_GUID:
      const authorizedFarms = farmsWithGUID[0].filter((farm) => farm.authorized).map((authorizedFarm) => {
        return authorizedFarm.identifier.content;
      });
      return {
        ...state,
        farmsWithGUID: farmsWithGUID[0],
        authorizedFarms,
      }
  default:
      return state;
  }
}
