import { appConstants } from "../common/constants/constants";

export const getJWT = () => {
	let token = localStorage.getItem(appConstants.LoginSessionKey);
	let jwt;

	if (token) {
		token = JSON.parse(token);
		if (token) {
			jwt = JSON.parse(atob(token.split('.')[1]));
		}
	}

	return jwt;
};

export const getTokenExpiryTime = () => {
	return getJWT()?.exp && getJWT()?.exp * 1000;
};

export const getUserId = () => {
	return getJWT()?.sub;
};

export const isTokenValid = () => {
  let currentDateTime = Date.now();    
  return getTokenExpiryTime() > currentDateTime;
}

export const validateToken = () => {
  let token = localStorage.getItem(appConstants.LoginSessionKey);
  let isTokenValid = false;
  let userId = '';
  if (token) {
    token = JSON.parse(token);
    const jwt = JSON.parse(atob(token.split('.')[1]));
    let expirationDateTime = jwt && jwt.exp ? jwt.exp * 1000 : null;
    let currentDateTimeinms = Date.now();
    userId = jwt.sub;
    if (expirationDateTime > currentDateTimeinms) {
      isTokenValid = true;
    }
  }
  return { isTokenValid , userId };
}