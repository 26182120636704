

import * as types from '../actions/actionTypes';

export default function saveValidationsReducer(state={}, action) {
    switch(action.type) {
        case types.SENDING_PARTY_VERIFICATION:
            return {
                ...state, 
                sendingPartyVerification: action.payload
            }

        case types.RECEIVING_PARTY_VERIFICATION:
            return {
                ...state, 
                receivingPartyVerification: action.payload
            }

        default: 
            return state;

    }

}