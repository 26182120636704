import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Card,
	Container,
	Grid,
	TextField,
	OutlinedInput,
	Button,
	Divider,
	InputLabel,
	FormControl,
	Snackbar
} from '@material-ui/core';
import {
	FormSelect,
	FormTypography,
	FormTextField
} from "../common/form";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { LocalizationProvider } from '@mui/x-date-pickers';

import {
	getContactFarm,
} from "../../common/services/contact.service";
import { postOnlineOrder, getOrderFarms, updateOrder } from "../../common/services/orderOnline.service";
import { getProductionData } from "../../common/services/production.service";
import { getCatalogFarmStatus, putUpdateCatalog } from "../../common/services/catalog.service"
import { formfields } from './initialState';
import { formatPhoneNumber } from '../../utils/phoneNumberUtil'
import { TextMaskCustom } from '../common/form/TextMaskCustom';
import { createInitiateTransferPayload } from './InitiateTransferPayload';
import { TransferSubmitPage } from './TransferSubmitPage';
import { sendEnvelopPayload } from './sendEnvelopPayload';
import { sendEnvelop } from '../../common/services/docusign.service';
import { FormDatepicker } from '../common/form';
import { appInsights, reactPlugin } from '../../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Alert } from '@mui/material';
import { handleError } from "../../utils/errorUtil";
import { getFormattedDate } from "../../utils/dateUtil";
import { numberWithCommas } from "../../utils/numberFormat";
import { usePopulateData } from "../../hooks/usePopulateData";
import { RECEIVE_BASE, RECEIVING_EXCEPTION_REQUEST, RECEIVING_FARM, SENDING_FARM, SEND_BASE } from '../../common/constants/constants';
import InitiateTransferHeadings from './InitiateTransferHeadings';
import {getLegalInfo} from './getLegalInfo';
import { validateIntiateTransferAmount } from '../CreateListCard/ValidationsCreateListCard';
import { getOrderFarmStatus } from '../../common/services/orderOnline.service';

const InitiateTransfer = () => {
	const { errorMsg, loading } = usePopulateData({ searchStr: '' });
	const history = useHistory();
	const location = useLocation();
	const {senderFarmDetails, baseType } = location?.state;

	const proposedTransferDate =
		senderFarmDetails?.catalogLine?.item?.specification.property
			?.effectiveTimePeriod?.startDateTime;
	const receiverFarms = useSelector(state => state?.farmDetails?.farmDetails);
	const contactIdentifier = useSelector(
		state => state?.contacts?.contact?.identifier
	);
	const userData = useSelector(state => state?.user);
	const [pageStatus, setPageStatus] = useState(true);
	const [formdata, setFormdata] = useState({
		...formfields,
		effectiveTransferDate: new Date(proposedTransferDate),
	});

	const { sendingPartyVerification, receivingPartyVerification } = useSelector(
		state => state.listingValidation
	);

	const [selectedContact, setSelectedContact] = useState({});
	const [formerror, setFormerror] = useState({});
	const [receiverDetails, setReceiverDetails] = useState({});
	const [selectedFarmContacts, setSelectedFarmContacts] = useState([]);
	const [postEnvelopRes, setPostEnvelopRes] = useState(null);
	const [formattedPhoneNo, setFormattedPhoneNo] = useState({ textmask: '' });
	const [defaultFarmGuid, setDefaultFarmGuid] = useState('');
	const [submitDisabled, setSubmitDisabled] = useState(false)
	let dollarUSLocale = Intl.NumberFormat('en-US');
	const [errorMessage, setErrorMsg] = useState({
		isOpen: false,
		message: '',
	});
	const [selectFarmDisabled, setSelectFarmDisabled] = useState(false)

	const allCatalog = useSelector(state => {
		return state?.allCatalog;
	});

	const [amountError, setAmmountError] = useState('');

	const receivingFarmNames = receiverFarms?.map((farm, index) => {
		const farmid = farm.data[0].partyMaster.party.identifier;
		return {
			partyIdentifier: farm.partyIdentifier,
			label: `${farmid} - ${farm.farmname}`,
			value: farmid,
		};
	});

	const getListAmount = async (farmPartyID) => {
			let listAmount = await getCatalogFarmStatus(
				farmPartyID,
				'Open;Pending;Signed;Submitted;Pending Cancel'
			)
				.then(response => {
					let listamount = 0;
					response.forEach(catalog => {
						if (catalog?.catalogLine?.typeCode === SEND_BASE) {
							listamount =
								listamount + parseInt(catalog.catalogLine.quantity.content);
						}
					});
					return listamount;

				})
				.catch(error => {
					handleError(error, history);
				});
			return listAmount > 0 ? listAmount : 0;
	}
	const validFarmTransfer = async farmGuid => {
		const orderResponse = await getOrderFarmStatus(
			farmGuid,
			'Open;Pending;Pending Cancel;Signed;Submitted'
		);

		let sendingPartyOrderData;

		let sumeOfAllTheValidOrder = 0
		if (orderResponse.length > 0) {
			sendingPartyOrderData = orderResponse.filter(res => {
				return (
					res?.onlineOrder.onlineOrderLine.supplierParty.identifier === farmGuid
				);
			});

			const validOrder = sendingPartyOrderData.filter(eachOrder => {
				const listingId = allCatalog?.allCatalog?.findIndex(
					eachCatalog =>
						eachCatalog?.catalogLine?.lineNumberIdentifier ===
							eachOrder?.onlineOrder.onlineOrderLine.item.identifier &&
						eachCatalog?.catalogLine?.typeCode === RECEIVE_BASE
				);

				return listingId === -1 ? false : true;
			});

			
			validOrder.forEach(eachOrder => {
				sumeOfAllTheValidOrder =
					sumeOfAllTheValidOrder +
					eachOrder?.onlineOrder?.onlineOrderLine?.quantity?.content;
			});

			return sumeOfAllTheValidOrder;

		} else {
			return 0;
		}

	};

	//TODO: when user comes directly to this URL, what needs to be done

	const onChangeFarm = async (farmId) => {
		setSelectFarmDisabled(true)
		if (farmId === "-1") {
			setSelectFarmDisabled(false);
			formerror.farm = 'Please select an option';
			return;
		}
		setFormerror({});
		const receiverFarm = receiverFarms?.find(
			data => data.data[0].partyMaster.party.identifier === farmId
		);


		const selFarm = {...receiverFarm?.data[0]};
		const formDataTemp = { ...formdata };
		formDataTemp.other_email = ""
		formDataTemp.receiverFarmName = farmId;
		formDataTemp.partyIdentifier =
			receiverFarm?.data[0].partyMaster.party.identifier;
		const contactsFarm = [];
		const allContactsFarm = await getContactFarm(selFarm.id)
		const contactNames = [];
		allContactsFarm
			.filter(item =>
				item?.contact?.extension?.farmRelationship?.find(
					i =>
						i?.identifier?.content === selFarm?.id &&
						i?.roleCode.find(
							role =>
								role === 'Authorized Representative' ||
								role === 'Member Owner'
						) && i?.status?.code === true
				)
			)
			.map(data => {
				contactNames.push({
					label: data.contact.personName.formattedName,
					value: data.contact.personName.formattedName,
					id: data.id,
				});
				contactsFarm.push(data);
			});
		let phoneNoArr = [];
		let emailArr = [];
		const rDetails = { ...receiverDetails };
		rDetails.farmName = selFarm.partyMaster.party.name;
		rDetails.farmId = selFarm.partyMaster.party.identifier;
		rDetails.currentBase = receiverFarm?.currentbase;
		rDetails.lbsToTransfer =
			senderFarmDetails?.catalogLine?.quantity?.content;
		if (contactNames.length > 0) {
			const currentContact = contactsFarm[0];
			currentContact.contact.telephoneCommunication.forEach(item => {
				phoneNoArr.push({
					label: formatPhoneNumber(item.identifier, item.typeCode),
					value: formatPhoneNumber(item.identifier, item.typeCode),
					typeCode: item.typeCode,
				});
			});
			phoneNoArr.push({
				label: 'Other',
				value: -1,
			});
			const email =
				currentContact.contact.emailAddressCommunication.identifier;
			if (email) {
				emailArr.push({
					label: email,
					value: email,
				});
			}
			emailArr.push({
				label: 'Other',
				value: -1,
			});
			rDetails.contactArr = contactNames;
			rDetails.phoneNoArr = phoneNoArr;
			rDetails.emailArr = emailArr;
			formDataTemp.phoneNo = phoneNoArr[0].label;
			formDataTemp.email = emailArr[0].label;

			setSelectedContact(currentContact);
		}
		formDataTemp.contactName = contactNames[0]?.label;
		formDataTemp.typeCode= `${baseType} Transfer`;
		let errors = {};

		const status = receiverFarm?.data[0]?.partyMaster?.financialAccount?.status;
		const exception = status?.find(
			i => i.identifier.typeCode === 'Marketplace Exception Request' 
			&& i.code === 'Receiving Exception Approved'
		);

		let farmError='';

		let currentFarmError;
		if (baseType === RECEIVE_BASE) {
			currentFarmError = sendingPartyVerification.find(
				farm => farm?.partyVerification?.identifier === receiverFarm?.farmPartyID
			);
		} else {
			currentFarmError = receivingPartyVerification.find(
				farm => farm?.partyVerification?.identifier === receiverFarm?.farmPartyID
			);
		}

		
		let listAmount = await getListAmount(receiverFarm?.farmPartyID);
		let sumOfActiveOrders = await validFarmTransfer(receiverFarm?.farmPartyID);

		const argumentsForValidateToInitiateTransfer = {
			amountToTransfer: senderFarmDetails?.catalogLine?.quantity?.content,
			currentbase: receiverFarm.currentbase,
			listAmount: listAmount,
			baseType: baseType,
			availableamount: currentFarmError?.partyVerification?.extension?.quantity,
			sumOfActiveOrderAmount: sumOfActiveOrders
		}

		if(baseType ===  RECEIVE_BASE && currentFarmError?.partyVerification?.extension?.indicator) {
			farmError = validateIntiateTransferAmount(argumentsForValidateToInitiateTransfer );
			setAmmountError(farmError)
			setFormerror({...errors, farm:farmError})
		} else {
			setFormerror({...errors, farm: currentFarmError?.partyVerification?.extension?.text?.content});
		}

		setFormdata(formDataTemp);
		setSelectedFarmContacts(contactsFarm);
		setReceiverDetails(rDetails);
		setSelectFarmDisabled(false)
	}

	const getContactDetails = contactName => {
		const selectedContact = selectedFarmContacts.find(
			item => item.contact.personName.formattedName === contactName
		);
		let phoneNoArr = [];
		let emailArr = [];
		selectedContact?.contact.telephoneCommunication.forEach(item => {
			phoneNoArr.push({
				label: formatPhoneNumber(item.identifier, item.typeCode),
				value: formatPhoneNumber(item.identifier, item.typeCode),
				typeCode: item.typeCode,
			});
		});
		phoneNoArr.push({
			label: 'Other',
			value: -1,
		});
		const email = selectedContact?.contact.emailAddressCommunication.identifier;
		if (email) {
			emailArr.push({
				label: email,
				value: email,
			});
		}
		emailArr.push({
			label: 'Other',
			value: -1,
		});
		return [phoneNoArr, emailArr, selectedContact];
	};

	const onChangeContact = contactName => {
		const data = getContactDetails(contactName);
		const rDetails = { ...receiverDetails };
		rDetails.phoneNoArr = data[0];
		rDetails.emailArr = data[1];
		const formDataTemp = { ...formdata };
		formDataTemp.contactName = contactName;
		//Bug 162011
		//formDataTemp.phoneNo = formatPhoneNumber(rDetails.phoneNoArr[0].label);
		formDataTemp.phoneNo = formatPhoneNumber(rDetails.phoneNoArr[0].label, rDetails.phoneNoArr[0].typeCode);
		formDataTemp.email = rDetails.emailArr[0].label;
		setReceiverDetails(rDetails);
		setSelectedContact(data[2]);
		setFormdata(formDataTemp);
	};

	const handleDateChange = value => {
		if (value) {
			value.setHours(0, 0, 0, 0);
			setFormdata({ ...formdata, effectiveTransferDate: value });
		}
	};

	const onPhoneNoChange = e => {
		// Not needed anymore
	};

	const onEmailChange = e => {
		//TODO: on email change if we need to anything
	};

	const handlePhoneNoChange = event => {
		setFormattedPhoneNo({ textmask: event.target.value });
	};

	const validateform = res => {
		let errors = {};
		const date = new Date();
		const validEffectiveTransferDate = new Date(formdata['effectiveTransferDate']);
		date.setDate(date.getDate() - 1);
		const getMonthForEffectiveTransferDate = validEffectiveTransferDate.getMonth()+1;
		const getDateForEffectiveTransferDate = validEffectiveTransferDate.getDate();
		// Needs to skip this errors 
		//when case type = “Marketplace Exception Request” and Status = “Receiving[AM1]  
		//exception request approved” then skip to checking #2
		if (date > new Date(formdata['effectiveTransferDate']) || !getMonthForEffectiveTransferDate || !getDateForEffectiveTransferDate) {
			errors.effectiveTransferDate = 'Enter a valid Effective Transfer Date';
		}
		if (formdata.contactName === 'Other' && !formdata.other_contact) {
			errors.other_contact = 'Please enter contact name';
		}
		if (
			formdata.email === 'Other' &&
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formdata.other_email)
		) {
			errors.other_email = 'Please enter a valid email address.';
		}
		if (formdata.phoneNo === 'Other') {
			if (formattedPhoneNo.textmask === undefined) {
				errors.other_phone_number =
					'Phone number must be 10 digits, do not enter any formatting characters.';
			}
			else {
				let otherNumber = formattedPhoneNo.textmask;
				if (otherNumber.length !== 14) {
					errors.other_phone_number = 'Phone number must be 10 digits, do not enter any formatting characters.';
				}
			}
		}


		let farmerrors = { ...formerror };
		if (farmerrors.farm) {
			errors.farm = farmerrors.farm;
		}

		return errors;
	};

	let onSubmit = async event => {
		setSubmitDisabled(true);
		event.preventDefault();
		const farm = receiverFarms.find(
			data =>
				data.data[0].partyMaster.party.identifier === formdata?.partyIdentifier
		);

		//TODO: handle if farm is not defined and show error
		//getProductionData(farm?.partyIdentifier)
		getProductionData(farm?.data[0].partyMaster.party.identifier)
			.then(res => {
				const errors = validateform(res);

				if(hasFarmOnlyWarnings(errors) ){
					submitform();
					setFormerror({});
				}
				if (
					!(Object.keys(errors).length === 0 && errors.constructor === Object)
				) {
					setSubmitDisabled(false);
					setFormerror(errors);
				} else {
					setFormerror({});
					submitform();
				}
			})
			.catch(err => {
				setErrorMsg({ isOpen: true, message: err?.response?.data?.message });
				appInsights.trackException({
					error: new Error(err),
					severityLevel: SeverityLevel?.Error,
				});
				history.push('/error-boundary');
				//TODO: when a error comes while fetching production data
			});
	};

	const submitform = () => {
		const partyIdentifier = formdata.partyIdentifier;
		const receivingFarm = receiverFarms.find(
			data => data.data[0].partyMaster.party.identifier === partyIdentifier
		);
		const receivingFarmUID = receivingFarm.data[0].id;
		// Bug 145477 - Comparing formatted phone # to unformatted phone #.
		const selMobile = receiverDetails?.phoneNoArr.find(
			i => i.label === formdata.phoneNo
		);

		let mobileTypeCode = 'Other';
		if (formdata.phoneNo !== 'Other') {
			mobileTypeCode = selMobile.typeCode;
		}
		//TODO
		//Reset the valyes when there is change in the email and phone number
		let otherNumber = formattedPhoneNo.textmask;
		let phoneNumber = formatPhoneNumber(formdata.phoneNo);
		const requestPayload = createInitiateTransferPayload({
			senderFarmDetails,
			receivingFarmUID,
			receivingFarmName: receivingFarm.farmname.trim(),
			selectedContact,
			mobileTypeCode,
			formdata,
			otherNumber,
			phoneNumber,
			contactIdentifier,
			formatPhoneNumber,
			typeCode: baseType
		});


		const catalogLine = senderFarmDetails?.catalogLine;
		const senderFarm = receiverFarms.find(
			i => i.farmPartyID === catalogLine?.party.identifier
		);
		const senderFarmId =
			senderFarm?.data[0].partyMaster.party.identifier;
		let senderDetails = {
			farmId: catalogLine?.party?.identifier,
			farmName: catalogLine?.party?.name,
			contactName: catalogLine?.party?.contact?.personName?.formattedName,
			email: catalogLine?.party?.contact?.emailAddressCommunication?.identifier,
			lbs: numberWithCommas(catalogLine?.quantity.content),
		};
		let receiverFarmDetails = {
			farmId: receivingFarm.data[0].partyMaster.party.identifier,
			farmName: receivingFarm.farmname,
			contactName: formdata?.contactName,
			email:
				formdata?.email === 'Other' ? formdata?.other_email : formdata?.email,
			lbs: numberWithCommas(catalogLine?.quantity.content),
		};

		const transferDate = getFormattedDate(formdata?.effectiveTransferDate);

		if(baseType === RECEIVE_BASE)
		{
			const temp = senderDetails;
			senderDetails = receiverFarmDetails;
			receiverFarmDetails = temp;
		}
		
		const envelopRequestBody = sendEnvelopPayload(
			senderDetails,
			receiverFarmDetails,
			transferDate
		);

		postOnlineOrder(requestPayload)
			.then(result => {
				appInsights?.trackException(userData);
				setPageStatus(false);
				const orderId = result?.id;
				const catalogId = result?.onlineOrder?.onlineOrderLine?.item?.identifier;
				putUpdateCatalog(catalogId, { status: 'Submitted' })
					.then(submitCatalogRes => {
						appInsights?.trackException(userData);
						sendEnvelop(envelopRequestBody)
							.then(envelopRes => {
								appInsights?.trackException(userData);
								//TODO: we are setting this in the state but are not using anywhere
								setPostEnvelopRes(envelopRes);
								const orderData = {
									status: 'Pending',
									docuSignEnvelopeID: envelopRes?.envelopeId,
								};
								updateOrder(orderId, orderData)
									.then(orderRes => {
										appInsights?.trackException(userData);
										putUpdateCatalog(catalogId, { status: 'Pending' })
											.then(catalogRes => { })
											.catch(err => {
												setErrorMsg({
													isOpen: true,
													message: err?.response?.data?.message,
												});
												appInsights?.trackException({
													error: new Error(err),
													severityLevel: SeverityLevel?.Error,
												});
												history?.push('/error-boundary');
											});
									})
									.catch(err => {
										setErrorMsg({
											isOpen: true,
											message: err?.response?.data?.message,
										});
										appInsights?.trackException({
											error: new Error(err),
											severityLevel: SeverityLevel?.Error,
										});
										history.push('/error-boundary');
									});
							})
							.catch(err => {
								setErrorMsg({
									isOpen: true,
									message: err?.response?.data?.message,
								});
								appInsights?.trackException({
									error: new Error(err),
									severityLevel: SeverityLevel?.Error,
								});
								history?.push('/error-boundary');
							});
					})
					.catch(err => {
						setErrorMsg({ isOpen: true, message: err.response.data.message });
						appInsights?.trackException({
							error: new Error(err),
							severityLevel: SeverityLevel?.Error,
						});
						history?.push('/error-boundary');
					});
			})
			.catch(err => {
				setErrorMsg({ isOpen: true, message: err?.response?.data?.message });
				appInsights.trackException({
					error: new Error(err),
					severityLevel: SeverityLevel?.Error,
				});
				history.push('/error-boundary');
			});
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setErrorMsg({ ...errorMessage, isOpen: false });
	};

	useEffect(() => {
		if (receivingFarmNames && receivingFarmNames.length === 0)
			setFormerror({ ...formerror, farm: 'No farms available.' });
		else setFormerror({ ...formerror, farm: '' });

		if (receivingFarmNames && receivingFarmNames?.length === 1) {
			setDefaultFarmGuid(receivingFarmNames[0]?.value);
			onChangeFarm(receivingFarmNames[0]?.value);
		} else setDefaultFarmGuid('');
	}, []);

	const hasWarning = (farmError) => {
		const errors = farmError || formerror
		return formerror?.farm?.includes('Warning');
	}

	
	const hasFarmOnlyWarnings = (farmError) => {
		const errors = farmError || formerror
		return Object.keys(errors)?.length === 1 && errors?.farm?.includes('Warning')
	}

	const formHasError = () => {
		return formerror?.farm?.length > 1 && formerror?.effectiveTransferDate?.length>1 && formerror?.other_email?.length> 0 && formerror?.other_phone_number?.length>1 && !hasFarmOnlyWarnings();
	}

	const canFormBeDisabled = () => {
		return selectFarmDisabled || !formdata?.partyIdentifier || submitDisabled || formHasError();
	}

	const initiateTransferPage = () => {
		return (
			<div>
				<Snackbar
					open={errorMessage?.isOpen}
					autoHideDuration={5000}
					onClose={handleClose}
					message={`Something went wrong !  Error : ${errorMessage?.message}`}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				/>

				<InitiateTransferHeadings baseType={baseType} />
				<Container className="box-container">
					<form onSubmit={onSubmit} noValidate>
						<Grid
							container
							className="nested-box-container"
							alignItems="flex-start"
							spacing={2}
						>
							<Grid item xs={12} md={6}>
								<Grid
									container
									className="nested-box-container"
									alignItems="flex-start"
									spacing={2}
								>
									<h3>{baseType === SEND_BASE && RECEIVING_FARM || SENDING_FARM}</h3>
									<Grid item xs={12}>
										<FormSelect
											name={baseType === SEND_BASE && RECEIVING_FARM || SENDING_FARM}
											className="select-menu"
											defaultValue={defaultFarmGuid}
											disabled={selectFarmDisabled ? selectFarmDisabled : receivingFarmNames?.length === 0}
											onChange={e => {
												onChangeFarm(e?.target?.value);
											}}
											data={{
												formdata,
												setformdata: setFormdata,
												id: 'receiverFarmName',
											}}
										>
											<option value="-1">--Select--</option>
											{receivingFarmNames?.map((item, index) => {
												return (
													<option key={index} value={item?.value}>
														{item?.label}
													</option>
												);
											})}
										</FormSelect>
										{
											formerror?.farm && (
											<FormTypography
												error={{ formerror, id: 'farm' }}
												color={hasWarning() ? '#E87722': '#B00020'}
											/>)
										}	
									</Grid>
									<br />
									<Grid item xs={12}>
										<Card className="mini-card" elevation={0}>
											<h4>{receiverDetails?.farmName}</h4>
											<Grid container className="details-list">
												<Grid item className="label">
													Current Base
												</Grid>
												<Grid item className="value">
													{receiverDetails?.currentBase} lbs/day
												</Grid>
												<Divider />
												<Grid item className="label">
													Farm ID
												</Grid>
												<Grid item className="value">
													{receiverDetails?.farmId}
												</Grid>
											</Grid>
										</Card>
									</Grid>
									<br />
									<Grid item xs={12}>
										<TextField
											id="outlined-disabled"
											label="Lbs to Transfer"
											variant="outlined"
											value={dollarUSLocale.format(
												senderFarmDetails?.catalogLine?.quantity?.content
											)}
											disabled
											className="text-field"
										/>
									</Grid>
									<br />
									<Grid item xs={12}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<FormDatepicker
												id="effectiveTransferDate"
												name="Effective Transfer Date"
												disabled={selectFarmDisabled ? selectFarmDisabled : false}
												onChange={handleDateChange}
												value={formdata?.effectiveTransferDate}
												minDate={new Date()}
											/>
										</LocalizationProvider>
										{!formerror.effectiveTransferDate ? (
											null
										) : <FormTypography
										error={{ formerror, id: 'effectiveTransferDate' }}
										color="#B00020"
									/>}
									</Grid>
									<Grid item xs={12}>
										<span className="legal-text">
											*Ensure effective transfer date is the date agreed upon by
											both parties.
										</span>
									</Grid>
								</Grid>
							</Grid>
							<br />

							<Grid item xs={12} md={6} className="show-for-large">
								<h3>{ baseType === SEND_BASE && SENDING_FARM || RECEIVING_FARM }</h3>
								<Grid item xs={12}>
									<Card className="mini-card grey" elevation={0}>
										<h4>{senderFarmDetails?.catalogLine?.party?.name}</h4>
										<Grid container className="details-list">
											<Grid item className="label">
												Contact
											</Grid>
											<Grid item className="value">

												{
													senderFarmDetails?.catalogLine?.party?.contact
														?.personName?.formattedName
												}
											</Grid>
											<Divider />

											<Grid item className="label">
												Phone
											</Grid>
											<Grid item className="value">
												{formatPhoneNumber(
													senderFarmDetails?.catalogLine?.party?.contact
														?.telephoneCommunication[0]?.identifier
												)}
											</Grid>
											<Divider />

											<Grid item className="label">
												Email
											</Grid>
											<Grid item className="value">
												{
													senderFarmDetails?.catalogLine?.party?.contact
														?.emailAddressCommunication?.identifier
												}
											</Grid>
											<Divider />

											<Grid item className="label">
												County
											</Grid>
											<Grid item className="value">
												{
													senderFarmDetails?.catalogLine?.party?.location
														.physicalAddress[0]?.countrySubDivisionCode[1]
														?.content
												}
											</Grid>
											<Divider />

											<Grid item className="label">
												State
											</Grid>
											<Grid item className="value">
												{
													senderFarmDetails?.catalogLine?.party?.location
														.physicalAddress[0]?.countrySubDivisionCode[0]
														?.content
												}
											</Grid>
										</Grid>
									</Card>
								</Grid>
							</Grid>

							<Grid item xs={12} md={6}>
								<FormSelect
									disabled={selectFarmDisabled ? selectFarmDisabled : false}
									name="Contact"
									className="select-menu"
									onChange={e => onChangeContact(e.target.value)}
									data={{
										formdata,
										setformdata: setFormdata,
										id: 'contactName',
									}}
								>
									
									{receiverDetails?.contactArr?.map((item, index) => {
										return (
											<option key={index} value={item?.index}>
												{item?.label}
											</option>
										);
									})}
								</FormSelect>
							</Grid>
							<Grid item xs={12} md={6}> </Grid>
							<Grid item xs={12} md={6}>
								<FormSelect
									name="Phone Number"
									disabled={selectFarmDisabled ? selectFarmDisabled : false}
									className="select-menu"
									onChange={onPhoneNoChange}
									data={{ formdata, setformdata: setFormdata, id: 'phoneNo' }}
								>
									
									{receiverDetails?.phoneNoArr?.map((item, index) => {
										return (
											<option key={index} value={item?.index}>
												{item?.label}
											</option>
										);
									})}
								</FormSelect>
							</Grid>
						
							<Grid item xs={12} md={6}>
								{formdata.phoneNo === 'Other' && (
									<>
										<FormControl variant="outlined" fullWidth size="small">
											<InputLabel
												htmlFor="formatted-text-mask-input"
												style={{ marginTop: '-2px' }}
											>
												Contact Phone Number
											</InputLabel>
											<OutlinedInput
												label={'Contact Phone Number'}
												value={formattedPhoneNo?.textmask}
												onChange={handlePhoneNoChange}
												name="textmask"
												id="formatted-text-mask-input"
												inputComponent={TextMaskCustom}
												fullWidth
												size="small"
												style={{
													borderColor: 'rgba(16, 32, 48, 0.13)',
													backgroundColor: '#fff',
													fontSize: '.96875rem',
													height: '44px',
												}}
											/>
										</FormControl>
										<>
											{formerror?.other_phone_number ? (
												<FormTypography
													error={{ formerror, id: 'other_phone_number' }}
													color={'#B00020'}
												/>
											) : null}
										</>
									</>
								)}
							</Grid>

							<Grid item xs={12} md={6}>
								<FormSelect
									name="Email"
									disabled={selectFarmDisabled ? selectFarmDisabled : false}
									className="select-menu"
									onChange={onEmailChange}
									data={{ formdata, setformdata: setFormdata, id: 'email' }}
								>
									{receiverDetails?.emailArr?.map((item, index) => {
										return (
											<option key={index} value={item.index}>
												{item.label}
											</option>
										);
									})}
								</FormSelect>
							</Grid>
							<Grid item xs={12} md={6}>
								{formdata.email === 'Other' && (
									<>
										<FormTextField
											data={{
												formdata,
												setformdata: setFormdata,
												id: 'other_email',
											}}
											disabled={selectFarmDisabled ? selectFarmDisabled : false}
											placeholder="Contact Email"
											className="text-field"
										/>
										{formerror?.other_email ? (
											<FormTypography
												error={{ formerror, id: 'other_email' }}
												color="#B00020"
											/>
										) : null}
									</>
								)}
							</Grid>

							<Grid
								item
								xs={12}
								md={6}
								className="hide-for-large show-for-medium"
								style={{ marginTop: '13px' }}
							>
								<Grid container spacing={2}>
									<h3>{ baseType === SEND_BASE && SENDING_FARM || RECEIVING_FARM }</h3>
									<Grid item xs={12}>
										<Card className="mini-card grey" elevation={0}>
											<h4>{senderFarmDetails?.catalogLine?.party?.name}</h4>
											<Grid container className="details-list">
												<Grid item className="label">
													Contact
												</Grid>
												<Grid item className="value">
													{
														senderFarmDetails?.catalogLine?.party?.contact
															?.personName.formattedName
													}
												</Grid>
												<Divider />

												<Grid item className="label">
													Phone
												</Grid>
												<Grid item className="value">
													{formatPhoneNumber(
														senderFarmDetails?.catalogLine?.party?.contact
															?.telephoneCommunication[0].identifier
													)}
												</Grid>
												<Divider />

												<Grid item className="label">
													Email
												</Grid>
												<Grid item className="value">
													{
														senderFarmDetails?.catalogLine?.party?.contact
															?.emailAddressCommunication.identifier
													}
												</Grid>
												<Divider />

												<Grid item className="label">
													County
												</Grid>
												<Grid item className="value">
													{
														senderFarmDetails?.catalogLine?.party?.location
															.physicalAddress[0]?.countrySubDivisionCode[1]
															.content
													}
												</Grid>
												<Divider />

												<Grid item className="label">
													State
												</Grid>
												<Grid item className="value">
													{
														senderFarmDetails?.catalogLine?.party?.location
															.physicalAddress[0]?.countrySubDivisionCode[0]
															.content
													}
												</Grid>
											</Grid>
										</Card>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<p className="legal-text">
									{getLegalInfo(baseType)}
								</p>
							</Grid>
						</Grid>
					</form>
				</Container>
				<Grid container className="form-button-container">
					<Grid item xs={12} sm={6}>
						<Button
							disabled={canFormBeDisabled()}
							className="button"
							onClick={onSubmit}
						>
							Submit
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} className="form-button-container-right">
						<Button
							className="button grey"
							variant="contained"
							onClick={() => {
								history.goBack();
							}}
						>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</div>
		);
	};

	let otherNumber = formattedPhoneNo.textmask;
	let phoneNumber = formatPhoneNumber(formdata.phoneNo);

	if (errorMsg) {
		<main>
			<section className="content page-initiate-transfer">
				<p>Error while retreiving data</p>
			</section>
		</main>;
	}


	let sender = senderFarmDetails?.catalogLine?.party?.name;
	let receiver = receiverFarms?.filter
	(item => item.data.map(item => item.partyMaster.party.identifier) == formdata?.receiverFarmName)[0]?.farmname
	
	let { contactName, phoneNo, email, other_email } = formdata;

	if(baseType === RECEIVE_BASE)
	{
		const temp = sender;
		sender = receiver;
		receiver = temp;
		contactName = senderFarmDetails?.catalogLine?.party?.contact?.personName?.formattedName;
		phoneNo = senderFarmDetails?.catalogLine?.party?.contact?.telephoneCommunication[0]?.identifier;
		email = senderFarmDetails?.catalogLine?.party?.contact?.emailAddressCommunication?.identifier;
		phoneNumber = phoneNo;
		other_email = email;
	}

	return (
		<section className="content page-initiate-transfer">
			{loading ? (
				<h1>Loading...</h1>
			) : pageStatus ? (
				initiateTransferPage()
			) : (
				<TransferSubmitPage
					content={senderFarmDetails?.catalogLine?.quantity?.content}
					contactName={contactName}
					phoneNo={phoneNo}
					email={email}
					other_email={other_email}
					effectiveTransferDate={formdata?.effectiveTransferDate}
					phoneNumber={phoneNumber}
					otherNumber={otherNumber}
					sender={sender}
					receiver={receiver}
				/>
			)}
		</section>
	);
}

export default withAITracking(reactPlugin, InitiateTransfer);