import { getOrdinalNum } from './numberFormat';

Date.shortMonths = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
Date.longMonths = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

/*
 * @returns current date in the format YYYY-MM-DD
 */
export const currentDay = () => {
	let today = new Date();
	let dd = String(today.getDate());
	let mm = String(today.getMonth() + 1);
	let yyyy = today.getFullYear();
	if (dd.length === 1) {
		dd = '0' + dd;
	}
	if (mm.length === 1) {
		mm = '0' + mm;
	}
	return mm + '-' + dd + '-' + yyyy;
};

/*
 * @returns date in the format YYYY-MM-DD
 */
export const DateFormatter = date => {
	let today = new Date(date);
	let dd = String(today.getDate());
	let mm = String(today.getMonth() + 1);
	let yyyy = today.getFullYear();
	if (dd.length === 1) {
		dd = '0' + dd;
	}
	if (mm.length === 1) {
		mm = '0' + mm;
	}
	return mm + '-' + dd + '-' + yyyy;
};

/*
 * @returns date provided in the format -> Nov 30, 2021
 */
export const getFormattedDate = date => {
	const day = new Date(date);
	return (
		`${
			Date.shortMonths[day.getMonth()]
		} ${day.getDate()}, ${day.getFullYear()}` || ''
	);
};

/*
 * @returns date provided in the format -> 2nd November 2021 at 18:06
 */
export const getFormattedDateAndTime = effectDate => {
	const dayUTC = new Date(effectDate + ' UTC');
	const day = new Date(dayUTC.toLocaleString());
	let ordinalday = getOrdinalNum(day?.getDate());
	return (
		`${ordinalday} ${Date.longMonths[day.getMonth()]} ${day.getFullYear()}  ` ||
		''
	);
};

/*
 * @returns current day in the ISO format -> "2021-11-18T15:57:47Z"
 */
export const getISOFormatCurrentDay = () => {
	const today = new Date();
	return `${today?.toISOString()?.split('.')[0]}Z`;
};

/*
 * @param date
 * @returns current day in the ISO format -> "2021-11-18T15:57:47Z"
 */
export const getISOFormatForProvidedDate = date => {
	if (date) {
		const today = new Date(date);
		return `${today?.toISOString()?.split('.')[0]}Z`;
	}
	return '';
};

/*
 * @param date
 * @returns true when date between 15-sep to 31-oct
 */
export const checkBlackOutDate = () => {
	let date = new Date();
	let day = date.getDate().toString();
	let month = (date.getMonth() + 1).toString();
	
	if (((month === '09' || month === '9') && day >= 15) || month === '10') {
		return true;
	} else {
		return false;
	}
};

/*
 * @param date1
 * @param date2
 * @returns the difference in minutes between date2 and date1
 */
export const diffInMinutes = ({ date1, date2 = new Date() }) => {
	var temp1 = new Date(date1);
	var temp2 = new Date(date2);
	var diffMs = temp2 - temp1;
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
	return diffMins;
};
