import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';
import axios from 'axios';

export async function getListingsValidations(payload, base) {
    let response = await NetworkService.postData(ApiUrl.getListingsValidationsUrl(base), payload);
    return response;
}



