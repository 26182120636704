import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FarmCardPage from '../../../containers/FarmCardPage/FarmCardPage.jsx';
import { checkBlackOutDate } from '../../../utils/dateUtil';
import { isTokenValid } from '../../../utils/tokenUtil.js';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router';

import { FormSelect } from '../../../components/common/form';

import { RECEIVE_BASE, SEND_BASE, CREATE_NEW_LISTING } from '../../../common/constants/constants.js';

const pageNavigations = [
	{
		title: 'Current Listings',
		url: '/home-page',
		icon: 'ag-icon ag-icon-card-group-one',
		subNavigation: null,
		_class_: '',
	},
	{
		title: 'Manage Transfers',
		url: '/manage-transfer',
		icon: 'ag-icon ag-icon-card-group-checked',
		subNavigation: null,
		_class_: '',
	},
	{
		title: 'Rules of Engagement',
		url: '/rules-of-engagement-sidebar',
		icon: 'ag-icon ag-icon-gavel',
		subNavigation: null,
	},
];

export const DrawerSidebar = props => {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [openSubNav, setOpenSubNav] = React.useState(true);
	const [showBlackOutMsg, setShowBlackOutMsg] = useState(checkBlackOutDate());
	const history = useHistory();
	const location = useLocation();
	const [formdata, setformdata] = useState({ selectBase: 'Create a Listing' });
	const [activeMenu, setActiveMenu] = useState('View All');

  const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleDrawerClose = () => {
		setMobileOpen(false);
	};

	const handleSubNavToggle = () => {
		setOpenSubNav(!openSubNav);
	};

	const handleOnClickOnLogo = () => {
		return history.push('/home-page');
	};

	useEffect(() => {
		if (!isTokenValid()) {
			history.push('/auth');
		}
	}, [location]);

	const onChangeBase = event => {
		const { value } = event.target;
		setActiveMenu(value);
		const listing = value.split(' ').join('-');
		setformdata({selectBase: "Create a Listing"})

		const url = '/create-listing-' + listing;
		if(url === "/create-listing-Create-New-Listing") {
			return ;
		} else {
			history.push({pathname: url, state: {base: value}});
		}
		
    
	};
	const SEND_BASE_LABEL = "» Send Base";
	const RECEIVE_BASE_LABEL = "» Receive Base";
	const baseSendAndRecieve = [
		{ value: CREATE_NEW_LISTING, label: CREATE_NEW_LISTING },
		{ value: SEND_BASE, label: SEND_BASE_LABEL },
		{ value: RECEIVE_BASE, label: RECEIVE_BASE_LABEL },
	];

	const navContent = (
		<div className="nav-content-wrap">			
			<FormSelect
						data={{ formdata, setformdata, id: 'selectBase' }}
						disabled={showBlackOutMsg}
						onChange={onChangeBase}
					>
						{baseSendAndRecieve.map((item, index) => {
							return (
								<option key={item.value + index} value={item.value}>
									{item.label}{' '}
								</option>
							);
						})}
					</FormSelect>				

			<nav>
				<h5>Pages</h5>
				<List className="nav-list">
					{pageNavigations.map((text, index) => (
						<ListItemButton
							key={text.title + index}
							className="nav-list-item"
							to={text.url}
							selected={text.url === window.location.pathname}
							component={Link}
							onClick={handleDrawerClose}
						>
							<i className={text.icon}></i>
							<ListItemText primary={text.title} />
						</ListItemButton>
					))}

					<ListItemButton
						key="Resources"
						className="nav-list-tree"
						selected={false}
						onClick={handleSubNavToggle}
					>
						<i className="ag-icon ag-icon-list-left"></i>

						<ListItemText primary="Resources" />
						{openSubNav ? (
							<i className="ag-icon ag-icon-chevron-right collapse-icon"></i>
						) : (
							<i className="ag-icon ag-icon-chevron-right ag-icon-rotate-90 collapse-icon"></i>
						)}
					</ListItemButton>

					<Collapse in={!openSubNav} timeout="auto" unmountOnExit>
						<List disablePadding className="nav-nested-list">
							<ListItemButton
								className="nav-nested-list-item"
								to={`/faqs-sidebar`}
								selected={'/faqs-sidebar' === window.location.pathname}
								component={Link}
								onClick={handleDrawerClose}
							>
								<span className="bullet-point"></span>
								<ListItemText primary="FAQs" />
							</ListItemButton>

							<ListItemButton
								to={`/terms-and-conditions-sidebar`}
								selected={
									'/terms-and-conditions-sidebar' === window.location.pathname
								}
								className="nav-nested-list-item"
								component={Link}
								onClick={handleDrawerClose}
							>
								<span className="bullet-point"></span>
								<ListItemText primary="Terms & Conditions" />
							</ListItemButton>
						</List>
					</Collapse>
				</List>
			</nav>

			<FarmCardPage />
		</div>
	);

	return (
		<>
			<span className="mobile-menu-trigger">
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerToggle}
				>
					<MenuIcon />
				</IconButton>
			</span>

			{/* Both drawers are being loaded in DOM which leads to duplicate API calls in FarmCardPage, need to optimize */}
			<Drawer
				variant="temporary"
				className="drawer-sidebar mobile"
				elevation={0}
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				{navContent}
			</Drawer>
		

			<Drawer variant="permanent" className="drawer-sidebar desktop" open>
				{navContent}
			</Drawer>
		</>
	);
};
