import * as types from "../actions/actionTypes";

export default function saveContactsReducer(state = {}, action) {
  switch (action.type) {
    case types.SAVE_CONTACTS:
      return {
        ...state,
        id: action.payload.id,
        contact: action.payload.contact
      }
    default:
      return state;
  }
}
