import React from "react";
import { Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent} from "@mui/material";

const ConfirmationModel = (props) => {

    return (
      <Dialog 
        open={props.open}
        disablePortal
        className="dialog-wrap"
        >
        <DialogTitle>
          {props.title}
          <IconButton className="close" onClick={props.closeWindow}><i className="ag-icon ag-icon-close-x"></i></IconButton>
        </DialogTitle>
        <DialogContent>
          <p style={{width:'400px'}}>{props.content}</p>          
        </DialogContent>
        <DialogActions>
          <Button className="button-text" variant='contained' color='primary' onClick={props.closeWindow}>Ok, close this window</Button>
        </DialogActions>
      </Dialog>
    )
}

export default ConfirmationModel;