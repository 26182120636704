import * as types from "../actions/actionTypes";

export default function saveAllCatalogReducer(state = {}, action) {

  switch (action.type) {
    case types.SAVE_ALL_CATALOG:
      return {
        ...state,
        allCatalog: action?.allCatalog
      }
    default:
      return state;
  }
}
