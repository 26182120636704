/*eslint-disable*/
import React from "react";
import Logo from "../../assets/images/logo-marketplace.svg";


export default function PublicLogoBoxes(props) {

  return (
    <aside className="logo-boxes slide-left">          
        <div className="box-top">
        <img src={Logo} className="logo" />
        </div> 
        <div className="box-mid"></div>
        <div className="box-bottom"></div> 
    </aside>
  );
}
