import React from "react";
import { Link } from 'react-router-dom';
import PublicLogoBoxes from "../components/PublicLogoBoxes/PublicLogoBoxes";

const Authorize = () => {
  return (
    <div className="public-view login-container">
      <PublicLogoBoxes />
      <div className="content" >
        <h1>Not Authorized</h1>
        <p>Looks like you are not currently authorized to view the marketplace.</p>
        <p>If you believe you should have access, <Link to="/contact-us-signin">Send Us a Message</Link> and request access.</p>
      </div>
    </div>
  )

}

export default Authorize;