import React from "react";
import { TextField } from "@mui/material";

export default function FormTextField({
  name,
  label = name,
  placeholder,  
  variant = "outlined",
  type,
  disabled,
  onBlur=()=>{},
  ...props
}) {

  const getDisabled = val => {
    if (val) return { disabled: true };
    return {};
  }; 

  return (
    <TextField
      name={name}
      label={name}
      placeholder={placeholder}      
      variant={variant}
      type={type}
      {...getDisabled(disabled)}
      className="text-field"
      onChange={(event) => {
        let temp = { ...props.data.formdata };
        temp[props.data.id] = event.target.value;
        props.data.setformdata(temp);
      }}
      value={props.data.formdata[props.data.id]}
      onBlur={onBlur}
    />

  );
}
