import * as React from 'react';
import { useState } from "react";
import HeaderChild from '.';
import { Box, Drawer, Divider, Grid, Button, IconButton } from "@material-ui/core";
import { InitiateTransferDrawer } from '../../InitiateTransfer/InitiateTransferDrawer';
import { getFormattedDate } from '../../../utils/dateUtil';
import { SEND_BASE } from '../../../common/constants/constants';

const ListingDetails = ({ open, closeDrawer, data, date, page, baseType }) => {

  const [initTransferDrawerOpen, setinitTransferDrawerOpen] = useState(false);
  let showTransferPending = data?.catalogLine?.extension[0]?.status.code !== 'Open';

  const toggleTransDrawer = (status) => {
    setinitTransferDrawerOpen(status)
  }
  let formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };
  let dollarUSLocale = Intl.NumberFormat('en-US');

  let getSplitTransfer = () => {
    let value = 'No';
    data?.catalogLine?.item?.specification?.property?.extension?.indicator.map(item => {
      if (item.typeCode === 'Split Transfer') {
        value = item.content ? 'Yes' : 'No';
      }
    })
    return value;
  }

  // Text Variables
  let textH1Location = data?.catalogLine.party.location.classification[0].codes.code[0].content + " " + data?.catalogLine.party.location.classification[0].codes.code[0].typeCode;
  let textH1 = dollarUSLocale.format(data?.catalogLine.quantity.content) + ' ' + data?.catalogLine.quantity.unitCode.toLowerCase() + 's.';
  let textLocationCounty = data?.catalogLine.party.location.physicalAddress[0].countrySubDivisionCode[0].content;
  let textLocationeState = data?.catalogLine.party.location.physicalAddress[0].countrySubDivisionCode[1].content;
  let textEmail = data?.catalogLine?.party?.contact?.emailAddressCommunication?.identifier;
  let textPhone = formatPhoneNumber(data?.catalogLine?.party?.contact?.telephoneCommunication[0]?.identifier);
  let textContactName = data?.catalogLine?.party?.contact?.personName?.formattedName;

  return (
		<>
			<InitiateTransferDrawer
				anchor="right"
				open={initTransferDrawerOpen}
				toggleTransDrawer={toggleTransDrawer}
				data={data}	
				baseType={baseType}			
			/>
			<Drawer
				anchor={'right'}
				open={open}
				onClose={closeDrawer}
				className="modal-flyout-wrapper"
			>
				<IconButton
					className="close"
					onClick={() => {
						closeDrawer();
					}}
				>
					<i className="ag-icon ag-icon-close-x"></i>
				</IconButton>

				{showTransferPending && (
					<Box className="modal-flyout-status pending">
						<h4 className="headline">Transfer Pending</h4>
					</Box>
				)}

				{/*<HeaderChild className="modal-flyout-header" />*/}				

				<Box className="modal-flyout-body">
					<h1>
						<small>{textH1Location}</small>
						{textH1} {baseType === SEND_BASE &&  " Available" || " Requested"} 
					</h1>
					<h2 className="h2-lite">From {data?.catalogLine.party.name}</h2>
					<p className="sub-headline-mini">
						Posted Date:{' '}
						{getFormattedDate(
							data?.catalogLine?.lastModificationDateTime || ''
						)}
					</p>

					<p className="legal-text">
						Contact the listing member-owner by phone or email provided below to
						review and discuss details of a potential transfer. Once both
						parties have agreed to terms of the transfer, click on the initiate
						a Transfer button below to start the transfer process.
					</p>
					<Grid container className="listing-details-list">
						<Grid item className="label">
							Contact Name
						</Grid>
						<Grid item className="value">
							{textContactName || '-'}
						</Grid>
						<Divider />

						<Grid item className="label">
							County, State
						</Grid>
						<Grid item className="value">
							{textLocationeState || '-'}, {textLocationCounty || '-'}
						</Grid>
						<Divider />

						<Grid item className="label">
							Allow Base Split
						</Grid>
						<Grid item className="value">
							{getSplitTransfer()}
						</Grid>
						<Divider />

						<Grid item className="label">
							Proposed Transfer
						</Grid>
						<Grid item className="value">
							{date}
						</Grid>
						<Divider />

						<Grid item className="label">
							Email
						</Grid>
						<Grid item className="value">
							{textEmail || '-'}
						</Grid>
						<Divider />

						<Grid item className="label">
							Phone
						</Grid>
						<Grid item className="value">
							{textPhone || '-'}{' '}
						</Grid>
					</Grid>

					{/* // Need to think if I can pull this into new component  */}
					{page !== 'CreateListCard' ? (
						<Grid container className="form-button-container">
							<Grid item xs={12} sm={6}>
								<Button
									className="button"
									disabled={
										data?.catalogLine?.extension[0]?.status?.code !== 'Open'
									}
									onClick={() => {
										toggleTransDrawer(true);
									}}
								>
									Initiate a Transfer
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="form-button-container-right"
							></Grid>
						</Grid>
					) : null}
				</Box>
			</Drawer>
		</>
	);
}

export default ListingDetails;