import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {useDispatch,useSelector} from "react-redux"
import { saveTermsAndConditionsCheck } from "../../store/actions/termsAndRulesActions";

export default function CheckboxLabels() {
    const isTermsAndConditionsChecked = useSelector(state => state?.termsRules?.checked);
    const [checked, setChecked] = useState(isTermsAndConditionsChecked?isTermsAndConditionsChecked:false)
    const dispatch = useDispatch();
    const handleChange = async () => {
    await setChecked(prevchecked=>!prevchecked);
    await dispatch(saveTermsAndConditionsCheck(!checked));

    }
    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox
                    checked={checked}
                    onChange={handleChange}
                    className="checkbox"
                />}
                label="I agree to the above consent to use this application"
            />
        </FormGroup>
    );
}
