import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Drawer, Divider, Grid, Button, IconButton, Link } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import ConsentModal from '../common/ConsentModal/ConsentModal';
import ConfirmationModel from '../common/ConsentModal/ConfirmationModel';
import { updateOrder, getReceivingFarm } from "../../common/services/orderOnline.service";
import { putUpdateCatalog } from "../../common/services/catalog.service"
import { updateEnvelop } from "../../common/services/docusign.service"
import { handleError } from '../../utils/errorUtil';
import { getFormattedDate } from '../../utils/dateUtil';
import { formatPhoneNumber } from '../../utils/phoneNumberUtil';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { appInsights } from '../../AppInsights';
import { getPartyMaster } from "../../common/services/partyMaster.service";
import { SEND_BASE } from '../../common/constants/constants';


const ACTIVE = 'Open';
const PENDING = 'Pending';
const LAPSED = 'Lapsed';
const SIGNED = 'Signed';
const COMPLETE = 'Completed';
const CANCELLED = 'Cancelled';
const EXPIRED = 'Expired';
const DELETED = 'Deleted';
const PENDING_CANCEL = "Pending Cancel"
const SUBMITTED = 'Submitted'

const ManageTransferDrawer = ({ open, closeDrawer, data, tab, onChangeFarmStatus }) => {
	const history = useHistory();
	const [openModal, setOpenModal] = React.useState(false);
	const [senderCounty, setsenderCounty] = React.useState("");
	const [senderState, setsenderState] = React.useState("");
	const [receiverCounty, setreceiverCounty] = React.useState("");
	const [receiverState, setreceiverState] = React.useState("");
	const [confirmationModel, setConfirmationModel] = React.useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const { userMilkShedName } = useSelector(state => state.userMilkShedName);

	useEffect(() => {
		getFarmsListing();
	}, []);

	const getFarmsListing = () => {		
		if (data && data?.senderAddress?.senderFarmId) {
			getPartyMaster(data?.senderAddress?.senderFarmId).then(milkSheds => {
				const senderLocation =
					milkSheds[0]?.partyMaster.party.location.physicalAddress[0]
						.countrySubDivisionCode;
				const milkShed =
					milkSheds[0]?.partyMaster?.partyClassification?.codes?.code[0]
						?.content;
				const county =
					senderLocation ? senderLocation[1]?.content : null;
				const state =
					senderLocation ? senderLocation[0]?.content : null;
				setsenderCounty(county);
				setsenderState(state);
			});
		}
		if (data && data?.receiverAddress?.receiverFarmId) {
			getPartyMaster(data?.receiverAddress?.receiverFarmId).then(milkSheds => {
				const receiverLocation =
					milkSheds[0]?.partyMaster.party.location.physicalAddress[0]
						.countrySubDivisionCode;
				const milkShed =
					milkSheds[0]?.partyMaster?.partyClassification?.codes?.code[0]
						?.content;
				const county =
					receiverLocation ? receiverLocation[1]?.content : null;
				const state =
					receiverLocation  ? receiverLocation[0]?.content : null;
				setreceiverCounty(county);
				setreceiverState(state);
			});
		}
	};

	const onConfirmationSuccess = () => {
		const orderData = {
			status: 'Pending Cancel',
		};
		if (data.status !== PENDING) {
			orderData.status = 'Deleted';
		}
		let orderId = data.orderId;
		const catalogId = data.catalogId;

		if (data.status === ACTIVE) {
			putUpdateCatalog(catalogId, orderData)
				.then(catalogRes => {
					handleClose();
					setConfirmationModel(true);
				})
				.catch(error => {
					handleError(error, history);
					appInsights.trackException({
						error: new Error(error),
						severityLevel: SeverityLevel.Error,
					});
				});
		} else {
			if (!orderId) {
				getReceivingFarm(catalogId).then(res => {
					orderId = res[0]?.id;
					updateOrder(orderId, orderData)
						.then(orderRes => {
							putUpdateCatalog(catalogId, orderData)
								.then(catalogRes => {
									if (data.envelopId) {
										updateEnvelop(data.envelopId, 'voided', 'User Cancelled')
											.then(res => { })
											.catch(error => {
												handleError(error, history);
												appInsights.trackException({
													error: new Error(error),
													severityLevel: SeverityLevel.Error,
												});
											});
									}
									handleClose();
									setConfirmationModel(true);
								})
								.catch(error => {
									handleError(error, history);
									appInsights.trackException({
										error: new Error(error),
										severityLevel: SeverityLevel.Error,
									});
								});
						})
						.catch(error => {
							handleError(error, history);
							appInsights.trackException({
								error: new Error(error),
								severityLevel: SeverityLevel.Error,
							});
						});
				});
			} else {
				updateOrder(orderId, orderData)
					.then(orderRes => {
						putUpdateCatalog(catalogId, orderData)
							.then(catalogRes => {
								if (data.envelopId) {
									updateEnvelop(data.envelopId, 'voided', 'User Cancelled')
										.then(res => { })
										.catch(error => {
											handleError(error, history);
											appInsights.trackException({
												error: new Error(error),
												severityLevel: SeverityLevel.Error,
											});
										});
								}
								handleClose();
								setConfirmationModel(true);
							})
							.catch(error => {
								handleError(error, history);
								appInsights.trackException({
									error: new Error(error),
									severityLevel: SeverityLevel.Error,
								});
							});
					})
					.catch(error => {
						handleError(error, history);
						appInsights.trackException({
							error: new Error(error),
							severityLevel: SeverityLevel.Error,
						});
					});
			}
		}
	};

	const closeConfirmationWindow = () => {
		setConfirmationModel(false);
		onChangeFarmStatus(data);
		setTimeout(() => closeDrawer());
	};

	const handleOnClickOnFAQ = () => {
		return history.push('/faqs-sidebar');
	};

	const getTransferText = (status, transferDate) => {
		switch (status) {
			case ACTIVE: {
				return 'This is a current open listing. If no transfer is initiated after 60 days, this listing will be cancelled and status updated to expired.';
			}
			case PENDING: {
				return 'This transfer agreement is contingent on DocuSign completed documentation.';
			}
			case SIGNED: {
				return `Both parties have signed the DocuSign agreement and Base is being updated in the Land O’Lakes tracking system.`;
			}
			case COMPLETE: {
				return (
					<p>
						This transfer agreement has been completed.See{' '}
						<Link onClick={handleOnClickOnFAQ}>FAQ</Link> document for
						additional information.
					</p>
				);
			}
			case CANCELLED: {
				return (
					<p>
						This transfer agreement has been cancelled by one of the
						transferring parties. See{' '}
						<Link onClick={handleOnClickOnFAQ}>FAQ</Link> document for
						additional information.
					</p>
				);
			}
			case DELETED: {
				return 'This transfer agreement has been deleted by the listing party.';
			}
			case PENDING_CANCEL: {
				return 'This transfer has been submitted for cancellation.';
			}
			case EXPIRED: {
				return (
					<p>
						This listing has been on the Marketplace for 60 days with no
						activity. See <Link onClick={handleOnClickOnFAQ}>FAQ</Link> document
						for additional information.
					</p>
				);
			}
			case SUBMITTED: {
				return 'This transfer agreement has been submitted to DocuSign.';
			}
			case LAPSED: {
				return 'Either one or both parties did not sign the DocuSign agreement within the 7-day time period, therefore this transfer agreement has lapsed. See FAQ document for additional information.';
			}
			default:
				return '';
		}
	};

	const getTransferHeaderText = status => {
		switch (status) {
			case EXPIRED: {
				return 'Expiration Details';
			}
			case DELETED: {
				return 'Deletion Details';
			}
			case ACTIVE: {
				return 'Listing Details';
			}
			case PENDING_CANCEL: {
				return 'Pending Cancel Details';
			}
			case CANCELLED: {
				return 'Cancellation Details';
			}
			default: {
				return 'Transfer Details';
			}
		}
	};

	const getDateHeaderText = status => {
		switch (status) {
			case EXPIRED: {
				return 'Date Expired';
			}
			case SIGNED: {
				return 'Date Signed';
			}
			case DELETED: {
				return 'Date Deleted';
			}
			case ACTIVE: {
				return 'Posted Date';
			}
			case COMPLETE: {
				return 'Date Completed';
			}
			case CANCELLED: {
				return 'Cancel Date';
			}
			case LAPSED: {
				return 'Date Lapsed';
			}
			case PENDING_CANCEL: {
				return 'Cancel Initiated Date';
			}
			case PENDING:
			case SUBMITTED:
				return 'Date Initiated';

			default: {
				return 'Date';
			}
		}
	};

	const getFormatStatusText = status => {
		switch (status) {
			case PENDING: {
				return 'Transfer Pending';
			}
			case ACTIVE: {
				return 'Open Listing';
			}
			case SIGNED: {
				return `Agreements Signed`;
			}
			case COMPLETE: {
				return `Transfer Completed`;
			}
			case LAPSED: {
				return `Lapse of Agreement`;
			}
			case CANCELLED: {
				return `Transfer Cancelled `;
			}
			case EXPIRED: {
				return 'Expired Listing';
			}
			case DELETED: {
				return 'Listing Deleted';
			}
			case PENDING_CANCEL:
				return 'Transfer Pending Cancel';
			case SUBMITTED:
				return 'Transfer Submitted';

			default: {
				return '';
			}
		}
	};

	const getButtonText = status => {
		switch (status) {
			case PENDING:
			case SUBMITTED:
				return 'Cancel Agreement & Stop Pending Transfer';

			case SIGNED:
			case COMPLETE:
			case DELETED:
			case LAPSED:
			case EXPIRED:
			default: {
				return '';
			}
		}
	};

	const displayButton = status => {
		switch (status) {
			case PENDING:
			case SUBMITTED:
				return true;
			case SIGNED:
			case DELETED:
			case COMPLETE:
			case LAPSED:
			case EXPIRED:
				return false;
			default:
				return false;
		}
	};

	return (
		<>
			<Drawer
				anchor={'right'}
				open={open}
				onClose={closeDrawer}
				className="modal-flyout-wrapper"
				key={'manage-transfer-drawer-' + data.key + data}
				BackdropProps={{ invisible: true }}
			>
				<IconButton
					className="close"
					onClick={() => {
						closeDrawer();
					}}
				>
					<i className="ag-icon ag-icon-close-x"></i>
				</IconButton>

				<Box className={`modal-flyout-status ${data?.status?.toLowerCase()}`}>
					<h4 className="headline">{getFormatStatusText(data?.status) + " : " +data.listingType}</h4>
				</Box>
				<Box className="modal-flyout-body">
					<Grid container spacing={2}>
						<Grid item></Grid>
						<Grid item>
							<h1>
								<small>{userMilkShedName}</small>
								{data?.listingDetails} lbs. {data?.listingType === SEND_BASE ? "Available" : "Requested"}
							</h1>
							<h2 className="h2-lite">{data?.listingType === SEND_BASE ?  `From ${data?.sender}`: `To ${data?.receiver}` } </h2>
						</Grid>
					</Grid>
					<h3 className="label">{getTransferHeaderText(data?.status)}</h3>
					<p>{getTransferText(data?.status, data?.proposedtransferDate)}</p>
					{data?.status === ACTIVE ||
						data?.status === DELETED ||
						data?.status === EXPIRED ? (
						<Grid container className="listing-details-list">
							<Grid item xs={12} sm={4} className="label">
								Proposed Transfer
							</Grid>
							<Grid item xs={12} sm={8} className="value">
								{getFormattedDate(data?.proposedtransferDate)}
							</Grid>

							<Grid item xs={12} sm={4} className="label">
								Allow Split
							</Grid>
							<Grid item xs={12} sm={8} className="value">
								{data?.splitTransfer ? 'Yes' : 'No'}{' '}
							</Grid>
							<Grid item></Grid>
							<Divider />
							<Grid item></Grid>
							<Grid item xs={12} sm={4} className="label">
								Contact
							</Grid>
							<Grid item xs={12} sm={8} className="value">
								{data?.senderAddress.contactName}
							</Grid>

							<Grid item xs={12} sm={4} className="label">
								Phone
							</Grid>
							<Grid item xs={12} sm={8} className="value">
								{formatPhoneNumber(data?.senderAddress?.mobile)}
							</Grid>

							<Grid container className="listing-details-list">
								<Grid item xs={12} sm={4} className="label">
									Email
								</Grid>
								<Grid item xs={12} sm={8} className="value">
									{data?.senderAddress.email}
								</Grid>
							</Grid>
							<Grid item></Grid>
							<Divider />

							<Grid item className="label">
								{getDateHeaderText(data?.status)}
							</Grid>
							<Grid item className="value">
								{getFormattedDate(data?.postedDate)}
							</Grid>
							<Grid item></Grid>
							{data?.status === ACTIVE ? (
								<Grid container justifyContent="space-between">
									<Grid item>
										<Button
											className="button grey"
											onClick={() => {
												if (data?.status === 'Open')
													return history.push({
														pathname: '/create-listing-'+data?.listingType?.replace(' ', '-'),
														search: '?update=true', // query string
														state: {
															update: true,
															data,
															base: data?.listingType
														},
													});
											}}
										>
											Edit Listing
										</Button>
									</Grid>
									<Grid item>
										<Button className="button grey" onClick={handleOpen}>
											Delete Listing{' '}
										</Button>
									</Grid>
								</Grid>
							) : null}
						</Grid>
					) : (
						<>
							{
								<Grid container className="listing-details-list">
									<Grid item className="label">
										Transfering
									</Grid>
									<Grid item className="value">
										{data?.listingDetails} of base lbs.
									</Grid>
									<Divider />

									<Grid item className="label">
										Sender
									</Grid>
									<Grid item className="value">
										{data?.sender}
										<h4 className="legal-text">
											{data?.senderAddress?.contactName}
										</h4>
										<h4 className="legal-text">
											{formatPhoneNumber(data?.senderAddress?.mobile)}
										</h4>
										<h4 className="legal-text">{data?.senderAddress?.email}</h4>
									</Grid>
									<Divider />

									<Grid item className="label">
										Receiver
									</Grid>
									<Grid item className="value">
										{data?.receiver}
										<h4 className="legal-text">
											{data?.receiverAddress?.contactName}
										</h4>
										<h4 className="legal-text">
											{formatPhoneNumber(data?.receiverAddress?.mobile)}
										</h4>
										<h4 className="legal-text">
											{data?.receiverAddress?.email}
										</h4>
									</Grid>
									<Divider />

									<Grid item className="label">
										{getDateHeaderText(data?.status)}
									</Grid>
									<Grid item className="value">
										{getFormattedDate(data?.effectivetransferDate)}
									</Grid>
								</Grid>
							}
						</>
					)}
					{displayButton(data?.status) && (
						<Grid container className="form-button-container center-buttons">
							<Grid item xs={12} sm={12} className="form-button-container">
								<Button className={data.status === SUBMITTED ? `button` : 'button grey'} onClick={handleOpen} disabled={data.status === SUBMITTED ? true : false}>
									{getButtonText(data?.status)}
								</Button>
							</Grid>
						</Grid>
					)}
				</Box>

				{openModal && (
					<ConsentModal
						open={openModal}
						data={data}
						handleCancelListingModalClose={handleClose}
						onConfirmationSuccess={onConfirmationSuccess}
					/>
				)}
				{confirmationModel && (
					<ConfirmationModel
						open={confirmationModel}
						closeWindow={closeConfirmationWindow}
						title={
							data.status === PENDING ? 'Confirm Cancelation' : 'Delete Listing'
						}
						content={
							data.status === PENDING
								? 'This transfer has been cancelled'
								: 'This listing has been deleted'
						}
					/>
				)}
			</Drawer>
		</>
	);
}

export default ManageTransferDrawer;