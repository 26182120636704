import React from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { OutlinedInput } from '@mui/material';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default function Maskinput({
  name,
  label = name,
  id,  
  ...props
}) {


  return (
    <FormControl variant="outlined" fullWidth size='small'>
    <InputLabel htmlFor="formatted-text-mask-input" style={{ marginTop: '-2px' }}>{label}</InputLabel>
    <OutlinedInput
      label="Contact Phone Number"
      onChange={(event) => {        
      
                  props.onChange(event)                       
      }}          
      value={props.data.formdata[props.data.id]}
      inputComponent={TextMaskCustom}
      className="text-field"
      size='small'
      style={{ height: '44px' }}
    />
  </FormControl>
  );
}
