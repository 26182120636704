import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Typography } from '@mui/material';

class ErrorBoundary extends Component {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		/* You can also change the `hasError` state to this method also
		  this.setState({hasError: true})
	 /* no need to use `getDerivedStateFromError */
		/* This information will be send into error reporting service */
		/* this.props.customErrorReportingService(error, info) */
		console.log(error, info);
	}

	onReload = () => {
		window.location.href = '/';
	};

	render() {
		if (this.state.hasError) {
			return (
				<>
					<Grid
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justify="center"
						style={{ minHeight: '100vh', color: "red" }}
					>
						<Grid item xs={6}>
							<Typography variant="h5" gutterBottom component="div">
								Oops Something went wrong.
							</Typography>
							{/* <div>
								<Box display="flex" m={1} p={3} alignItems="center">
									<div>
										<button
											onClick={() => this.onReload()}
										>
											HOME
										</button>
									</div>
								</Box>
							</div> */}
						</Grid>
						<Grid item xs={6}>
							<Typography variant="h5" gutterBottom component="div">
								Click <b style={{ textDecoration: "underline red", cursor: "pointer" }}
									onClick={() => this.onReload()}>here</b> to go the homepage
							</Typography>
						</Grid>
					</Grid>
				</>
			);
		} else {
			return this.props.children;
		}
	}
}

export default ErrorBoundary;
