import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getTokenId(authCode) {

  let accessToken = 
    await NetworkService.getData
    ( ApiUrl.getTokenId(authCode), 
    { contentType: 'application/x-www-form-urlencoded' });
  return accessToken;
}