import { APP_BASE_URL, AUTH_CONFIG } from "../configs/auth.config";

export const ApiUrl = {
    getAuthTokenURL() {
        return AUTH_CONFIG.AUTH_TOKEN_URL;
    },

    getTokenId(authCode) {
        return APP_BASE_URL + 'login/v1/api/login?code=' + authCode;
    },

    getPartymasterUrl(partyMasterId) {
        return APP_BASE_URL + 'partymaster/v1/api/partyMaster/' + partyMasterId;
    },

    // farmsWithGUID -> comma seperated farmsWithGUID values and status
    getActiveFarmsUrl(farmsWithGUID, status) {
        return APP_BASE_URL + 'partymaster/v1/api/partyMaster/PartyMasters/' + farmsWithGUID + "/" + status;
    },

    getContact(federationId) {
        return APP_BASE_URL + 'contact/v1/api/contact/FederationId/' + federationId;
    },

    getContactFarm(farmGuid) {
        return APP_BASE_URL + 'contact/v1/api/contact/FarmGuid/' + farmGuid;
    },


    getContactFarmAuthorizations(contactId) {
        return APP_BASE_URL + 'contact/v1/api/contact/ContactFarmAuthorizations/' + contactId;
    },

    getCatalogFarmStatusURL(farmId, status) {
        return APP_BASE_URL + 'catalog/v1/api/catalog/FarmGuid/' + farmId + '/' + status;
    },
    getCatalogFarmsStatusURL(farmIds, status) {
        return APP_BASE_URL + 'catalog/v1/api/catalog/FarmGuids/' + farmIds + '/' + status;
    },
    getCatalogURL() {
        return APP_BASE_URL + 'catalog/v1/api/catalog';
    },
    sendEmailURL() {
        return APP_BASE_URL + 'email/v1/api/email';
    },
    getGeoCode(countyAndState) {
        return APP_BASE_URL + 'geocode/v1/api/geocode/' + countyAndState;
    },
    postCreateLiting() {
        return APP_BASE_URL + 'catalog/v1/api/catalog';
    },
    putCreateLiting(id) {       
        return APP_BASE_URL + 'catalog/v1/api/catalog/' + id;
    },

    getFarmOrderHistory(farmID) {
        return APP_BASE_URL + 'order/v1/api/order' + farmID;
    },

    postOnlineOrderURL() {
        return APP_BASE_URL + 'order/v1/api/order';
    },
    
    postLisitingIdsURL() {
        return APP_BASE_URL + 'order/v1/api/order/ListingIdsFromBody';
    },
    putUpdateOrderURL(id){
        return APP_BASE_URL+'order/v1/api/order/UpdateOrder/'+id
    },
    putUpdateCatalogURL(id){
        return APP_BASE_URL + 'catalog/v1/api/catalog/UpdateCatalog/' + id;
    },
    getUserConsentURL(userId) {
        return APP_BASE_URL + 'userconsent/v1/api/userconsent/' + userId;
    },
    postUserConsentURL() {
        return APP_BASE_URL + 'userconsent/v1/api/userconsent/';
    },
    postRequiredDocuments() {
        return APP_BASE_URL + 'dynamicpage/v1/api/dynamicpage/getRequiredDocuments';
    },
    getOrderFarmStatusURL(farmId, status) {
        return APP_BASE_URL + 'order/v1/api/order/FarmGuid/' + farmId + '/' + status;
    },
    getProuctionDataURL(id) {
        return APP_BASE_URL + 'production/v1/api/Production/' + id
    },

    getOrderFarmsStatusURL(farmIds, status) {
        return APP_BASE_URL + 'order/v1/api/order/FarmGuids/' + farmIds + '/' + status;
    },

    getEnvelopeIdURL(envelopId) {
        return APP_BASE_URL + 'api/DocusignEnvelope/' + envelopId;
    },

    postEnvelopURl() {
        return APP_BASE_URL + '/docusignEnvelope/v1/api/docusignEnvelope/sendenvelope'
    },
    postUpdateEnvelopURl(envelopeId,status,reason) {
        return `${APP_BASE_URL}/docusignEnvelope/v1/api/docusignEnvelope/updateenvelope/envelopeId/${envelopeId}/status/${status}/reason/${reason}`
    },
    getReceivingFarm(listingIds) {
        return APP_BASE_URL + 'order/v1/api/order/ListingIds/' + listingIds;
    },
    getUserConsentVersion(userid, title, versionIdentifier) {
        return APP_BASE_URL + 'userconsent/v1/api/userconsent/' + userid + '/' + title + '/' + versionIdentifier
    },
    getSubscriptionUrl (userId){
        return APP_BASE_URL + 'subscription/v1/api/subscription/' + userId;
    },

    postSubscriptionUrl () {
        return APP_BASE_URL + 'subscription/v1/api/subscription/';
    },

    updateSubscriptionsUrl(userId) {
        return APP_BASE_URL + 'subscription/v1/api/subscription/' + userId;
    },
    getListingsValidationsUrl(base) {
        return APP_BASE_URL + "partyverification/v1/api/partyverification/"+base;
    }  
}
