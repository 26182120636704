import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';
import axios from 'axios';

export async function postOnlineOrder(payload) {
    let response = await NetworkService.postData(ApiUrl.postOnlineOrderURL(), payload);
    return response;
}

export async function getFarmOrderHistory(farmID) {
    let response = await NetworkService.getData(ApiUrl.getFarmOrderHistory(farmID));
    return response;
}

export async function getOrderFarmStatus(farmId, status) {
    let response = await NetworkService.getData(ApiUrl.getOrderFarmStatusURL(farmId, status));
    return response;
}

export const getCatalogAndOrderStatus = async(farmsIdentifiers, statusOrder, statusCatalog) => {
    return await axios.all([
        NetworkService.getData(ApiUrl.getOrderFarmsStatusURL(farmsIdentifiers, statusOrder)),
        NetworkService.getData(ApiUrl.getCatalogFarmsStatusURL(farmsIdentifiers, statusCatalog)),
      ])
      .then(axios.spread((data1, data2) => {
        return [...data1, ...data2];
      }));
}

export async function getOrderFarms(farmIds, status) {
    let response = await NetworkService.getData(ApiUrl.getOrderFarmsStatusURL(farmIds, status));
    return response;
}

export async function getReceivingFarm(farmIds) {
    let response = await NetworkService.getData(ApiUrl.getReceivingFarm(farmIds));
    return response;
}
export async function postLisitingIds(payload) {
    let response = await NetworkService.postData(ApiUrl.postLisitingIdsURL(), payload);
    return response;
}

export async function updateOrder(id,payload) {
    let response = await NetworkService.putData(ApiUrl.putUpdateOrderURL(id), payload);
    return response;
}