import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import ListingDetails from '../components/common/ListingHeaderChild/ListingDetails';
import { getFormattedDate } from '../utils/dateUtil';

function SuccessAndManageTransfer(props) {
	const [openListingDetails, setOpenListingDetails] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const item = location.state.item;

	const handleOnClickOnManageTransfer = props => {
		return history.push('/manage-transfer');
	};

	const closeDrawer = () => {
		setOpenListingDetails(false);
	};

	return (
		<main className="create-listing-page">
			<section className="content">
				<h1>{location?.state?.update ? 'Edit Listing' : 'Create a Listing'}</h1>
				<p>Complete all fields below for your Marketplace listing.</p>

				<Container className="box-container">
					<h2>
						{location?.state?.update
							? 'Success! Your Listing Was Edited'
							: 'Success! Your Listing Was Created'}
					</h2>
					<p>
						{location?.state?.update
							? <>The listing you just updated will expire in 60 days if a transfer has not been completed. To see how the listing will be displayed, click on <b>Preview Listing</b>. To change or cancel the listing, go to the <b>Manage Transfers</b> page.</>
							: <>The listing has been posted on the Current Listings page for all members to view. To see how the listing will be displayed, click on <b>Preview Listing</b>.
								<>
									<br />
									<br />
								</>
								To change or cancel the listing go to the <b>Manage Transfers</b> page. If the transfer process has not been initiated or the listing has not been changed within 60 days, the listing will expire.</>}
					</p>

					<Grid container className="form-button-container center-buttons">
						<Grid item xs={12} sm={6}>
							<Button
								variant="outlined"
								className="button outlined lite"
								onClick={handleOnClickOnManageTransfer}
							>
								Manage Transfers
							</Button>
						</Grid>
						<Grid item xs={12} sm={6} className="form-button-container-right">
							<Button
								variant="outlined"
								className="button outlined lite"
								onClick={() => {
									setOpenListingDetails(true);
								}}
							>
								Preview Listing
							</Button>
						</Grid>
					</Grid>
				</Container>

				<ListingDetails
					page="CreateListCard"
					open={openListingDetails}
					closeDrawer={closeDrawer}
					data={item}
					date={getFormattedDate(
						item?.catalogLine.item.specification.property?.effectiveTimePeriod
							?.startDateTime || ''
					)}
				/>
			</section>
		</main>
	);
}

export default SuccessAndManageTransfer;

