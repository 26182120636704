import * as types from "../actions/actionTypes";

export default function saveRulesAndEngagementCheck(state = {}, action) {
  switch (action.type) {
      case types.SAVE_RULES_ENGAGEMENTC_CHECK:
      return { ...state, checked: action.checked }
    default:
      return state;
  }
}
