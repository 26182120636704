import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useHistory, useLocation } from 'react-router-dom';

import { validateToken } from '../../../utils/tokenUtil';
import { TIME_OUT_FOR_REDIRECT_TO_HOME_PAGE } from '../../../common/constants/constants';

function IdleTimerContainer() {
	const idleTimerRef = useRef(null);
	const sessionTimeOutRef = useRef(null);
	const location = useLocation();

	const history = useHistory();
	const { isTokenValid } = validateToken();

	const onIdle = () => {
		const CreateListingOrIntiateTransferpage = [
			'/create-list-card',
			'/initiate-transfer',
		];
		if(!isTokenValid) {
				history.push('/auth');
		}

		if (CreateListingOrIntiateTransferpage.includes(location.pathname)) {
			history.push('/home-page');

		}
	};


	return (
		<section>
			<IdleTimer
				ref={idleTimerRef}
				timeout={TIME_OUT_FOR_REDIRECT_TO_HOME_PAGE} // 15 minutes of timeout when user does not intract with the page
				onIdle={onIdle}
			></IdleTimer>
		</section>
	);
}

export default IdleTimerContainer;
