import React from 'react';
import { DrawerSidebar } from "../components/common/sidebar/DrawerSidebar";
import { useLocation } from 'react-router-dom';

export const useDrawerSideBar = () => {
  const location = useLocation();
  const withoutHeaderRoutes = ['/', '/auth', '/term-and-condition', '/contact-us-signin', '/authorize', '/sign-in-help','/rules-engagement','/message-sent-signin'];
  if (withoutHeaderRoutes.includes(location.pathname)) {
    return null;
  }

  return <DrawerSidebar/>
}