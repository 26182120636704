import * as types from "../actions/actionTypes";

export default function saveContactsReducer(state = {}, { type, farmDetails }) {
  switch (type) {
    case types.SAVE_FARM_DETAILS:
      return {
        ...state,
        farmDetails
      }
    default:
      return state;
  }
}
