import { combineReducers } from "redux";
//import apiCallsInProgress from "./apiStatusReducer";
import sessionReducer from './sessionReducer';
import userReducer from "./userReducer";
import saveContactsReducer from "./saveContactsReducer";
import userMilkShedName from "./saveMilkShedNameReducer";
import saveFarmWithGUIDReducer from "./saveFarmWithGUIDReducer";
import saveMilkShedsReducer from "./saveMilkShedsReducer";
import termsAndRulesReducer from './termsAndRulesReducer';
import rulesAndEngagementReducer from "./rulesAndEngagementReducer"
import contactsListReducer from './contactsListReducer';
import farmDetailsReducer from './farmDetailsReducer';
import senderFarmDetailsReducer from './senderFarmDetailsReducer';
import initiateTransferCounterReducer from './farmCardReducer';
import refreshDataReducer from './refreshDataReducer';
import saveAllCatalogReducer from "./saveAllCatalogReducer";
import saveValidationsReducer from "./saveListingValidationsReducer";

const rootReducer = combineReducers({
  // apiCallsInProgress,
  session: sessionReducer,
  user: userReducer,
  contacts: saveContactsReducer,
  userMilkShedName,
  farmsWithGUID: saveFarmWithGUIDReducer,
  milkSheds: saveMilkShedsReducer,
  termsRules: termsAndRulesReducer,
  rulesEngagement: rulesAndEngagementReducer,
  contactsList: contactsListReducer,
  farmDetails: farmDetailsReducer,
  senderFarmDetails: senderFarmDetailsReducer,
  initiateTransferCounter: initiateTransferCounterReducer,
  lastRefreshDateTime: refreshDataReducer,
  allCatalog: saveAllCatalogReducer,
  listingValidation: saveValidationsReducer
});

export default rootReducer;
