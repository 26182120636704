import * as types from "../actions/actionTypes";

export default function senderFarmDetailsReducer(state = {}, { type, senderFarmDetails }) {
  switch (type) {
    case types.SAVE_SENDER_FARM_DETAILS:
      return {
        ...state,
        senderFarmDetails
    }
    default:
      return state;
  }
}
