import * as types from "../actions/actionTypes";

export default function sessionReducer(state = {}, action) {
  switch (action.type) {
    case types.SAVE_TOKEN:
      return { ...state, token: action.payload }
    default:
      return state;
  }
}
