import * as types from "../actions/actionTypes";

export default function contactsListReducer(state = {}, { type, contactsList }) {
  switch (type) {
    case types.SAVE_CONTACTS_LIST:
      return {
        ...state,
        contactsList
      }
    default:
      return state;
  }
}

