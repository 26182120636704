import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import { numberWithCommas } from '../../../utils/numberFormat';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

/**
 * @author Dhivya
 * @description Display Farm Details
 * @param {*} props 
 * @returns 
 */
const FarmCard = (props) => {
    let item = props.farms;
    return (
      <Card key={item.farmid} className="farm-card" variant="outlined">
        <CardContent className="farm-root">
            <Box mt={2} ml={2}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} className="farm-griditem">
                        <Typography variant="body2" component="span" gutterBottom className="farm-title"> {item.farmname}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} ml={2}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={8} className="farm-griditem">
                        <Typography variant="body2" component="span" gutterBottom className="farm-row"> CURRENT BASE</Typography>
                    </Grid>
                    <Grid item xs={4} className="farm-grid-rightitem">
                        <Typography variant="body2" component="span" gutterBottom className="farm-row">{numberWithCommas(item.currentbase)}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider variant="middle" />
            <Box mt={2} ml={2}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={8} className="farm-griditem" >
                        <Typography variant="body2" component="span" gutterBottom className="farm-row">ACTIVE LISTINGS </Typography>
                    </Grid>
                    <Grid item xs={4} className="farm-grid-rightitem" >
                        <Typography variant="body2" component="span" gutterBottom className="farm-row"> {(item.activelisting)} </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Divider variant="middle" />
            <Box mt={2} ml={2}>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={8} className="farm-griditem" >
                        <Typography variant="body2" component="span" gutterBottom className="farm-row">PENDING TRANSFERS</Typography>
                    </Grid>
                    <Grid item xs={4} className="farm-grid-rightitem">
                        <Typography variant="body2" component="span" gutterBottom className="farm-row"> {(item.pendingtransfer)}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </CardContent>
      </Card>
    )
}

export default FarmCard;