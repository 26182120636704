
// https://apidv.digitalag.farm/subscription/v1/api/subscription/{userid}/{enabledIndicator}/{channelCode}/{topicIdentifier}


// getSubscription

import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';

export async function getSubscriptions(userId) {
    let response = await NetworkService.getData(ApiUrl.getSubscriptionUrl(userId));
    return response;
}

export async function postSubscriptions (subscriptions){
    let response = await NetworkService.postData(ApiUrl.postSubscriptionUrl(), subscriptions);
    return response;
}

export async function  updateSubscriptions (userId, payload) {
    let response  = await NetworkService.putData(ApiUrl.updateSubscriptionsUrl(userId), payload)
}