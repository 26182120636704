import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDocument } from '../hooks/useDocument.js';

const RulesOfEngagementSidebar = () => {
  const { document, isUserError, isApiError } = useDocument("Marketplace Rules of Engagement");

  if (isUserError) {
    return <Redirect to={{ pathname: '/auth' }} />
  }

  if (isApiError) {
    return (      
        <section className="content page-rules-of-engagement">
          <div>Unable to retreive information,please try again</div>
        </section>      
    )
  }

  return (    
      <section className="content page-rules-of-engagement">
        <h1>Rules of Engagement</h1>
        {
          document?.date ? <div>
            <p><i>{'Agreed on ' + document.date}</i></p>
            <div dangerouslySetInnerHTML={{ __html: document.content }}></div>
            </div> : 
          <div> Loading... </div>
         }
      </section>    
  )
}

export default RulesOfEngagementSidebar;