import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from './utils/tokenUtil';
import { saveUserId } from './store/actions/userActions'; 

const ProtectedRoute = (props) => {
    const dispatch = useDispatch();
    const { isTokenValid, userId } = validateToken();
    const contactId = useSelector(state => state?.contacts?.id);
    if (isTokenValid && contactId) {
      dispatch(saveUserId(userId));
      return (<Route path={props.path} component={props.component} />)
    } else {
      return <Redirect to={{ pathname: '/auth' }} />
    }
}

export default ProtectedRoute;