import React,{useState} from "react";
import { Button,
  IconButton,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid } from "@mui/material";

const PENDING_STATUS='Pending';

const ConsentModal = (props) => {

    const [confirmationValue,setConfirmationValue] = useState('');
    const {status} = props.data;

    return (
      <Dialog 
        open={props.open}
        disablePortal
        className="dialog-wrap"
        >
        <DialogTitle>
          {status===PENDING_STATUS ? 'Confirm Cancelation' : 'Delete Listing'}
          <IconButton className="close" onClick={props.handleCancelListingModalClose}><i className="ag-icon ag-icon-close-x"></i></IconButton>
        </DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove your listing{status === PENDING_STATUS &&', cancel the agreement and stop the pending transfer of base pounds'}?</p>
          <Grid container spacing={2} justifyContent='center' >
            <Grid item xs={12} sm={6}>
              <p style={{textAlign: "left"}}>Type 'Yes' to confirm cancelation</p>
            </Grid>
            <Grid item xs={12} sm={6} container direction='column'>
              <TextField
              id="consent-id-if-needed"              
              className="text-field"
              variant="outlined" 
              value={confirmationValue}
              size='small'
              onChange={(e)=>setConfirmationValue(e.target.value)}/>
              <p style={{textAlign: "left",color:'red'}}>You must type 'Yes' into the box above to {status === PENDING_STATUS ? 'cancel this transfer agreement.' :'delete this listing'}</p>
            </Grid>
          </Grid>          
        </DialogContent>
        <DialogActions>
          <Button className="button-text" disabled={confirmationValue.toLowerCase() !== 'yes'} onClick={props.onConfirmationSuccess}>Yes,{status === PENDING_STATUS ? ' I confirm this cancelation':' delete listing'}</Button>
          <Button className="button-text" onClick={props.handleCancelListingModalClose}>No, close this window</Button>
        </DialogActions>
      </Dialog>
    )
}

export default ConsentModal;