import NetworkService from './networkService';
import { ApiUrl } from '../configs/path.config';
import axios from 'axios';

export async function getCatalogData() {
    let response = await NetworkService.getData(ApiUrl.getCatalogURL());
    return response;
}

export async function getCatalogFarmStatus(farmId, status) {
    let response = await NetworkService.getData(ApiUrl.getCatalogFarmStatusURL(farmId, status));
    return response;
}

export async function getAllCatalogFarmStatus(farmIds, status) {
    let response = await NetworkService.getData(ApiUrl.getCatalogFarmsStatusURL(farmIds, status));
    return response;
}

export async function postCreateListing(payload) {
    let response = await NetworkService.postData(ApiUrl.postCreateLiting(), payload);
    return response;
}
export async function putCreateListing(id,payload) {
    let response = await NetworkService.putData(ApiUrl.putCreateLiting(id), payload);
    return response;
}

export const getMultiCatalogFarmStatus = async(farmIds, status) => {
    const farmAPIs=[]
    farmIds.forEach(farmId=>{
        farmAPIs.push(
            NetworkService.getData(ApiUrl.getCatalogFarmStatusURL(farmId, status))
        )
    })
    return await axios.all(farmAPIs)
      .then(response => {
        return response;
      });
}

export async function putUpdateCatalog(id,payload) {
    let response = await NetworkService.putData(ApiUrl.putUpdateCatalogURL(id), payload);
    return response;
}

export async function getCatalogById(id) {
    let response = await NetworkService.getData(ApiUrl.putCreateLiting(id));
    return response;
}