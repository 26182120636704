import * as types from "../actions/actionTypes";

export default function initiateTransferCounterReducer(state = { initiateTransferCounter : 0 }, { type }) {
  switch (type) {
    case types.CREATE_INITIATE_TRANSFER:
      return {
        ...state,
        initiateTransferCounter: state.initiateTransferCounter + 1
      }
    default:
      return state;
  }
}