import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePopulateData } from '../hooks/usePopulateData';

const LoginScreen = props => {
	const { errorMsg, pathToRoute, loading } = usePopulateData({
		searchStr: props?.location?.search,
	});
	const history = useHistory();

	useEffect(() => {
		if (pathToRoute) {
			history.push(pathToRoute);
		}
	}, [pathToRoute, history, loading]);

	if (!errorMsg) {
		return null;
	}

	if (loading) {
		return <h1>Loading...</h1>;
	}
	return (
		<p>
			<h1>Error</h1>
			<span>Something went wrong while getting farms.</span>
		</p>
	);
};

export default LoginScreen;