import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Link } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';

import * as types from '../../store/actions/actionTypes';

import {
	FormTextField,
	FormCheckbox,
	FormSelect,
	FormSwitch,
	FormTypography,
	FormDatepicker,
	Maskinput,
} from '../common/form';
import {
	postCreateListing,
	putCreateListing,
	getCatalogFarmStatus,
} from '../../common/services/catalog.service';
import { getOrderFarmStatus } from '../../common/services/orderOnline.service';
import { getContactFarm } from '../../common/services/contact.service';
import { createListCardPayload } from './CreateListCardPayload';
import { formfields } from './initialState';
import {
	formatPhoneNumber,
	formatPhoneNumberWithoutTypeCode,
} from '../../utils/phoneNumberUtil';
import { handleError } from '../../utils/errorUtil';

import {
	ValidateAmount,
	ValidateCreateListForm,
	ValidateFarmError,
} from './ValidationsCreateListCard';
import { usePopulateData } from '../../hooks/usePopulateData';
import ListingHeadings from './ListingHeading';
import FormButtons from './FormButtons';
import {
	RECEIVE_BASE,
	SEND_BASE,
	formatCurrentBase,
	getCreateListingTermAndConditionText1,
} from '../../common/constants/constants';
import TermAndConditionText from './TermAndConditionText';
import RenderLoading from '../common/RenderLoading';
import transformFarmDetails from '../../utils/transformFarmDetails';
import RenderErrorMessage from '../common/RenderErrorMessage';
import { getListingsValidations } from '../../common/services/getValidations';
import { useFarmDetails } from '../../hooks/useFarmDetails';

const CreateListCard = () => {
	const { errorMsg, loading } = usePopulateData({ searchStr: '' });
	const { farmDetails } = useFarmDetails();

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const farmDetailsFromStore = useSelector(state => {
		return state?.farmDetails?.farmDetails || [];
	});
	const lastModificationByUserIdentifier = useSelector(
		state => state?.contacts?.id
	);

	const allCatalog = useSelector(state => {
		return state?.allCatalog;
	});

	const { sendingPartyVerification, receivingPartyVerification } = useSelector(
		state => state.listingValidation
	);

	let dollarUSLocale = Intl.NumberFormat('en-US');
	const [switchname, setSwitchname] = useState(
		'Not willing to split offered base pounds into multiple transfers.'
	);
	const [success, setSuccess] = useState(false);
	const [pageStatus, setPageStatus] = useState(false);
	const [farms, setFarms] = useState([]);
	const [defaultFarmGuid, setDefaultFarmGuid] = useState('');
	const [phoneNo, setPhoneNo] = useState([]);
	const [email, setEmail] = useState([]);
	const [contacts, setContacts] = useState([]);
	const [selectedFarm, setSelectedFarm] = useState({});
	const [item, setItem] = useState();
	const [formdata, setformdata] = useState({ ...formfields });
	const [formerror, setformerror] = useState({});
	const [listAmount, setListAmount] = useState(0);
	const [selectedContact, setSelectedContact] = useState({});
	const [selectFarmDisabled, setSelectFarmDisabled] = useState(false);
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [validateException, setValidateException] = useState(false);
	const [loadValidations, setLoadValidations] = useState(true);
	const [sumOfActiveOrderAmount, setSumOfActiveOrderAmount] = useState(0);

	const resetStatesForReciveBaseCreateListing = () => {
		onChangeSwitch();
		setformerror({});
		setSubmitDisabled(false);
		setSelectedFarm({});
		setformdata({ ...formfields });
		setSelectFarmDisabled(false);
	};

	useEffect(() => {
		resetStatesForReciveBaseCreateListing();
	}, [location?.state?.base]);

	const validateAmount = e => {
		const value = e.target.value.replace(/,/g, '');
		setformdata({
			...formdata,
			amount_to_transfer: dollarUSLocale.format(value),
		});


		const { amountError } = ValidateAmount({
			amountToTransfer: formdata['amount_to_transfer'],
			currentbase: selectedFarm?.currentbase,
			listAmount,
			isEditListing: location?.state?.update,
			editListingAmountTransfer: location?.state?.data?.listingDetails,
			baseType: location?.state?.base,
			availableamount: selectedFarm?.error?.quantity,
			sumOfActiveOrderAmount,
		});
		// setSuccess(false);
		if(!amountError?.error?.indicator) {
			setformdata({...formdata, accept_term: false});
		}
		setformerror({ ...formerror, amount_to_transfer: amountError });
	};

	const handleDateChange = value => {
		if (value) {
			value.setHours(0, 0, 0, 0);
			setformdata({ ...formdata, proposed_transfer_date: value });
		}
	};

	const handleOtherPhoneNoChange = event => {
		const typeCode = event.target.value.includes('Phone') && 'Phone' || event.target.value.includes('Mobile') && 'Mobile' || 'Other';
		const match = phoneNo.find((ph) => ph?.value === event.target.value);
		setformdata({ ...formdata, other_phone_number: event?.target?.value, type_code: match?.typeCode });
	};

	const onChangePhoneNumber = event => {
		const typeCode = event.target.value.includes('Phone') && 'Phone' || event.target.value.includes('Mobile') && 'Mobile' || 'Other';
		const match = phoneNo.find((ph) => ph?.value === event.target.value);
		setformdata({ ...formdata, phone_number: match?.label || event?.target?.value, type_code: match?.typeCode });
	};

	const onChangeEmail = event => {
		setformdata({ ...formdata, email: event?.target?.value });
	};

	const onChangeSwitch = event => {
		const switchno =
			location?.state?.base === SEND_BASE
				? 'Not willing to split offered base pounds into multiple transfers.'
				: 'Not willing to receive multiple transfers';
		const switchyes =
			location?.state?.base === SEND_BASE
				? 'Yes, willing to split offered base pounds into multiple transfers.'
				: 'Willing to receive multiple transfers';
		event ? setSwitchname(switchyes) : setSwitchname(switchno);
	};

	const onAcceptChange = event => {
		event ? setSuccess(true) : setSuccess(false);
	};

	const validFarmTransfer = async farmGuid => {
		const orderResponse = await getOrderFarmStatus(
			farmGuid,
			'Open;Pending;Pending Cancel;Signed;Submitted'
		);

		let sendingPartyOrderData;

		if (orderResponse.length > 0) {
			sendingPartyOrderData = orderResponse.filter(res => {
				return (
					res?.onlineOrder.onlineOrderLine.supplierParty.identifier === farmGuid
				);
			});

			const validOrder = sendingPartyOrderData.filter(eachOrder => {
				const listingId = allCatalog?.allCatalog?.findIndex(
					eachCatalog =>
						eachCatalog?.catalogLine?.lineNumberIdentifier ===
							eachOrder?.onlineOrder.onlineOrderLine.item.identifier &&
						eachCatalog?.catalogLine?.typeCode === RECEIVE_BASE
				);

				return listingId === -1 ? false : true;
			});

			let sumeOfAllTheValidOrder = 0;
			validOrder.forEach(eachOrder => {
				sumeOfAllTheValidOrder =
					sumeOfAllTheValidOrder +
					eachOrder.onlineOrder.onlineOrderLine.quantity.content;
			});

			setSumOfActiveOrderAmount(sumeOfAllTheValidOrder);
		}

		let errors = {};
		const today = new Date();
		today.setDate(today.getDate() - 180);
		today.setHours(0, 0, 0, 0);

		orderResponse.forEach(order => {
			if (
				order?.onlineOrder?.onlineOrderLine?.party?.identifier === farmGuid &&
				new Date(
					order?.onlineOrder?.onlineOrderHeader?.lastModificationDateTime
				) >= today
			) {
				errors.farm_name =
					'Farm ineligible: received base within past 18 months';
			}
		});

		return errors;
	};

	const handleChangeFarm = async event => {
		setSelectFarmDisabled(true);
		setformerror({});
		onAcceptChange();

		const farmGuid = event.target.value;

		const searchedFarm = farms?.find(item => item.farmPartyID === farmGuid);

		let currentFarmError;
		if (location?.state?.base === SEND_BASE) {
			currentFarmError = sendingPartyVerification?.find(
				farm => farm?.partyVerification?.identifier === farmGuid
			);
		} else {
			currentFarmError = receivingPartyVerification?.find(
				farm => farm?.partyVerification?.identifier === farmGuid
			);
		}

		const status = searchedFarm?.data[0]?.partyMaster?.financialAccount?.status;

		const exception = status?.find(
			i =>
				i?.identifier?.typeCode === 'Marketplace Exception Request' &&
				i?.code === 'Sending Exception Approved'
		);

		setValidateException(exception);

		if (searchedFarm) {
			let selectedFarmtemp = {
				...searchedFarm,
				error: currentFarmError?.partyVerification?.extension,
				label: `${searchedFarm?.data[0]?.partyMaster?.party?.identifier} - ${searchedFarm?.farmname}`,
			};

			setSelectedFarm({ ...selectedFarmtemp });
			let error = {};

			if (!exception) {
				error = await validFarmTransfer(farmGuid);
			}

			if (!currentFarmError?.partyVerification?.extension?.identifier) {
				setformerror(prevState => {
					return {
						...prevState,
						farm_name:
							currentFarmError?.partyVerification?.extension?.text?.content,
					};
				});
			} else if (error?.farm_name) {
				setformerror(error);
			}

			//Gather the content which is in the Open;Pending;Signed;Submitted;Pending Cancel for the selected farm
			let listamount = 0;
			getCatalogFarmStatus(
				selectedFarmtemp?.farmPartyID,
				'Open;Pending;Signed;Submitted;Pending Cancel'
			)
				.then(response => {
					response.forEach(catalog => {
						if (catalog?.catalogLine?.typeCode === SEND_BASE) {
							listamount =
								listamount + parseInt(catalog?.catalogLine?.quantity?.content);
						}
					});

					setListAmount(listamount);
				})
				.catch(error => {
					handleError(error, history);
				});

			getContactFarm(selectedFarmtemp?.farmPartyID)
				.then(contactsFarm => {
					let contacts = [];
					contactsFarm
						?.filter(item =>
							item?.contact?.extension?.farmRelationship?.find(
								i =>
									i?.identifier?.content === farmGuid &&
									i?.roleCode.find(
										role =>
											role === 'Authorized Representative' ||
											role === 'Member Owner'
									) &&
									i?.status?.code === true
							)
						)
						?.map(data => {
							contacts?.push({
								farmPartyID: selectedFarmtemp?.farmPartyID,
								contactId: data?.contact?.identifier,
								farmName: selectedFarmtemp?.farmname,
								farmId: selectedFarmtemp?.partyIdentifier,
								personName: data?.contact?.personName?.formattedName,
								phoneNumber: data?.contact.telephoneCommunication?.filter(
									item => item.identifier
								),
								email: data?.contact?.emailAddressCommunication?.identifier,
							});
						});
					setContacts(contacts);

					if (location?.state?.update) {
						contacts = contacts?.filter(
							contact =>
								contact?.contactId ===
								location?.state?.data?.senderAddress?.contactId
						);
					}

					const item = contacts[0];
					let phoneNo = [];
					let email = [];
					let selectedcontactdata = {
						render: true,
						farmid: item?.farmId,
						contactId: item?.contactId,
						personName: item?.personName,
						email: item?.email,
						phoneNumber: item?.phoneNumber,
						id: item?.id,
						selectedContact: item,
					};

					setSelectedContact(selectedcontactdata);

					selectedcontactdata?.phoneNumber?.forEach(item => {
						phoneNo?.push({
							value:'(' +
							item?.typeCode +
							') ' +
							formatPhoneNumberWithoutTypeCode(item?.identifier),
							
							label:
								'(' +
								item?.typeCode +
								') ' +
								formatPhoneNumberWithoutTypeCode(item?.identifier),
							typeCode: item?.typeCode,
						});
					});

					if (selectedcontactdata?.email !== '') {
						email?.push({
							value: item?.email,
							label: item?.email,
						});
					}

					setPhoneNo(phoneNo);
					setEmail(email);

					if (!location?.state?.update) {
						let temp = { ...formdata };
						temp['amount_to_transfer'] = '';
						temp['other_email'] = '';
						temp['other_phone_number'] = '';
						temp['accept_term'] = '';
						temp['farm_name'] = farmGuid;
						temp['primary_contact'] = selectedcontactdata?.personName;
						if (phoneNo.length !== 0) {
							temp['phone_number'] = phoneNo[0]?.value;
							temp['type_code'] = phoneNo[0]?.typeCode;
						} else {
							temp['phone_number'] = 'Other';
							temp['type_code'] = 'Other';
							temp['other_phone_number'] = phoneNo[0]?.value;
						}
						temp['email'] = selectedcontactdata.email || 'Other';
						setformdata(temp);
					} else {
						//If user is coming from the Manage Transfer
						let temp = { ...formdata };
						temp['farm_name'] = farmGuid;
						temp['amount_to_transfer'] = location?.state?.data?.listingDetails;
						temp['proposed_transfer_date'] =
							location?.state?.data?.proposedtransferDate;

						onChangeSwitch(location?.state?.data?.indicator?.splitTransfer);
						temp['split_transfer'] =
							location?.state?.data?.indicator?.splitTransfer;

						onAcceptChange(location?.state?.data?.indicator?.acceptTerms);
						temp['accept_term'] = location?.state?.data?.indicator?.acceptTerms;
						//temp['primary_contact'] = location?.state?.data?.senderAddress?.contactName;
						temp['primary_contact'] = item.personName;
						temp['type_code'] = location?.state?.data?.senderAddress?.typecode;

						const phoneTypeCode = location?.state?.data?.senderAddress?.typecode;
						const phoneNumber = location?.state?.data?.senderAddress?.mobile;
						if(phoneTypeCode !== 'Other')
						{		
						temp['phone_number'] = '(' +
						phoneTypeCode +
						') ' +
						formatPhoneNumberWithoutTypeCode(phoneNumber);
						}
						else
						{
							temp['phone_number'] = 'Other';
							temp['other_phone_number'] = formatPhoneNumberWithoutTypeCode(phoneNumber);
						}
					
						const emails = email.find(
							item => item.label === location?.state?.data?.senderAddress?.email
						);
						if (emails !== undefined) temp['email'] = emails.label;
						else {
							temp['email'] = 'Other';
							temp['other_email'] = location?.state?.data?.senderAddress?.email;
						}
						setformdata(temp);
					}
				})
				.catch(error => {
					setSelectFarmDisabled(false);
					handleError(error, history);
				});
		}
		setSelectFarmDisabled(false);
	};

	const onChangePrimaryContact = event => {
		let phoneNo = [];
		const contactList = contacts.find(
			item => item.personName === event.target.value
		);
		contactList?.phoneNumber.forEach(item => {
			phoneNo.push({
				value: item.identifier,
				label:
					'(' +
					item.typeCode +
					') ' +
					formatPhoneNumberWithoutTypeCode(item.identifier),
				typeCode: item.typeCode,
			});
		});
		let selectedcontactdata = {
			render: true,
			farmid: contactList.farmId,
			contactId: contactList.contactId,
			personName: contactList.personName,
			email: contactList.email,
			phoneNumber: contactList.phoneNumber,
			id: contactList.id,
			selectedContact: contactList,
		};

		setSelectedContact(selectedcontactdata);
		setPhoneNo(phoneNo);
		let emails = [];
		if (contactList?.email !== '') {
			emails.push({
				value: contactList?.email,
				label: contactList?.email,
			});
		}
		let temp = { ...formdata };
		temp['primary_contact'] = event?.target?.value;
		temp['phone_number'] = phoneNo[0]?.value || 'Other';
		temp['type_code'] = phoneNo[0]?.typeCode || 'Other';
		temp['other_phone_number'] =
			temp['phone_number'] === 'Other' ? phoneNo[0]?.value : '';
		temp['email'] = emails[0]?.value || 'Other';
		setEmail(emails);
		setformdata(temp);
	};

	const onSubmit = async event => {
		event.preventDefault();
		let farmguid = selectedFarm?.farmPartyID;
		const { errors } = ValidateCreateListForm({
			proposedTransferDate: formdata['proposed_transfer_date'],
			selectedFarmValue: formdata['farm_name'],
			selectedFarm,
			amountToTransfer: formdata['amount_to_transfer'],
			listAmount,
			email: formdata?.email,
			otherEmail: formdata?.other_email,
			phoneNumber: formdata?.phone_number,
			otherPhonenumber: formdata?.other_phone_number,
			farmDetailsFromStore,
			isEditListing: location?.state?.update,
			editListingAmountTransfer: location?.state?.data?.listingDetails,
			exception: validateException,
		});

		if (farmguid) {
			let allErrors = { ...errors };
			if (
				allErrors?.amount_to_transfer &&
				allErrors?.amount_to_transfer?.includes('Warning')
			) {
				delete allErrors.amount_to_transfer;
			}
			if (
				!(
					Object.keys(allErrors).length === 0 &&
					allErrors?.constructor === Object
				)
			) {
				setformerror(errors);
			} else {
				setformerror({});
				submitRequest();
			}
		} else {
			if (
				!(Object.keys(errors).length === 0 && errors?.constructor === Object)
			) {
				console.error('Farm Guid not defined');
				setformerror(errors);
			}
		}
	};

	const submitRequest = () => {
		setSubmitDisabled(true);
		let values = { ...formdata };
		const today = new Date();
		values['amount_to_transfer'] = parseInt(
			values['amount_to_transfer'].replace(/,/g, '')
		);
		values['primary_contact'] =
			values['primary_contact'] !== 'Other'
				? values['primary_contact']
				: values['other_contact'];
		values['type_code'] =
			values['phone_number'] !== 'Other' ? values['type_code'] : 'Other';
		values['phone_number'] =
			values['phone_number'] !== 'Other'
				? formatPhoneNumber(values['phone_number'])
				: values['other_phone_number'];
		values['email'] =
			values['email'] !== 'Other' ? values['email'] : values['other_email'];
		values['countrySubDivisionCode'] =
			selectedFarm?.data[0]?.partyMaster?.party?.location?.physicalAddress[0]?.countrySubDivisionCode;
		values['milkshed'] =
			selectedFarm?.data[0]?.partyMaster?.partyClassification?.codes?.code[0].content;
		const requestPayload = createListCardPayload({
			id: location?.state?.update ? `${location?.state?.data?.catalogId}` : '',
			today,
			values,
			contactId: selectedContact?.contactId,
			farmName: selectedFarm?.farmname,
			farmGuid: selectedFarm?.farmPartyID,
			lastModificationByUserIdentifier,
			typeCode: location?.state?.base,
		});

		if (location?.state?.update) {
			putCreateListing(location?.state?.data?.catalogId, requestPayload).then(
				res => {
					setSubmitDisabled(false);
					setPageStatus(true);
					setItem(requestPayload);
					history.push({
						pathname: '/success-and-manage-transfer',
						state: {
							update: location?.state?.update,
							item: requestPayload,
						},
					});
				}
			);
		} else {
			postCreateListing(requestPayload).then(res => {
				setPageStatus(true);
				setItem(requestPayload);
				history.push({
					pathname: '/success-and-manage-transfer',
					state: { item: requestPayload },
				});
			});
		}
	};

	useEffect(() => {
		let farmData = transformFarmDetails(farmDetailsFromStore);
		setFarms(farmData);
		farmData.length === 0
			? setformerror({ ...formerror, farm_name: 'No farms available.' })
			: setformerror({ ...formerror, farm_name: '' });

		if (farmData.length === 1) {
			setDefaultFarmGuid(farmData[0].farmguid);
			const event = { target: { value: farmData[0].farmguid } };
			handleChangeFarm(event);
		} else setDefaultFarmGuid('');

		if (location?.state?.update) {
			//TODO: need to revisit if we can handle it in better way
			
			const event = { target: { value: location?.state?.data?.senderId } };
			handleChangeFarm(event);
		}
	}, [loading, farmDetailsFromStore, location?.state?.base]);

	const canTermAndConditionBeDisabled = () => {
		return !selectedFarm?.error?.indicator || !location?.state?.update && selectFarmDisabled || !formerror?.amount_to_transfer?.includes('Warning') && formerror?.amount_to_transfer?.length > 1;
	};

	const hasFormError = () => {
		return !success;
	}

	const termAndConditionText = [
		{
			key: '1',
			text: getCreateListingTermAndConditionText1(location?.state?.base),
			hasLink: false,
		},
		{
			key: '2',
			text: 'Rules of Engagement',
			hasLink: true,
			handleOnLinkClick: () => history.push('/rules-of-engagement-sidebar'),
		},
		{ text: ' and ', hasLink: false },
		{
			key: '3',
			text: 'Terms & Conditions',
			hasLink: true,
			handleOnLinkClick: () => history.push('/terms-and-conditions-sidebar'),
		},
	];

	const canTextFieldBeDisabled = (location, selectFarmDisabled) => {
		return !location?.state?.update
			? selectFarmDisabled
				? selectFarmDisabled
				: false
			: false;
	};

	if (loading) {
		return <RenderLoading loading={loading || loadValidations} />;
	}
	if (errorMsg) {
		return <RenderErrorMessage error={errorMsg} />;
	}

	return (
		<main className="create-listing-page">
			<section className="content">
				<ListingHeadings
					updateListing={location?.state?.update}
					baseType={location?.state?.base}
				/>

				{!pageStatus && (
					<Container className="box-container">
						<form onSubmit={onSubmit} noValidate>
							<Grid
								container
								className="nested-box-container"
								alignItems="flex-start"
								spacing={2}
							>
								<Grid item xs={12}>
									<FormSelect
										data={{ formdata, setformdata, id: 'farm_name' }}
										disabled={
											(farms.length == 0 && !loading) || location?.state?.update
										}
										label="Select a Farm"
										defaultValue={defaultFarmGuid}
										onChange={handleChangeFarm}
										name="farm_name"
									>
										<option value="-1"></option>
										{farms.map((item, index) => {
											return (
												<option
													key={item.partyIdentifier + index}
													value={item.farmguid}
												>
													{item.label}{' '}
												</option>
											);
										})}
									</FormSelect>
									{!selectedFarm?.error?.indicator && (
										<Typography
											style={{
												fontSize: '14px',
												color: formerror?.amount_to_transfer?.includes(
													'Warning'
												)
													? '#E87722'
													: '#B00020',
												margin: '0px 0px 0px 0px',
											}}
										>
											{selectedFarm?.error?.text?.content}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12} md={6}>
									<FormTextField
										onBlur={validateAmount}
										data={{ formdata, setformdata, id: 'amount_to_transfer' }}
										disabled={canTextFieldBeDisabled()}
										type="string"
										inputProps={{ inputMode: 'numeric', pattern: '[0-9]' }}
										name={
											(location?.state?.base === SEND_BASE &&
												'Amount to transfer') ||
											'Amount to receive'
										}
										placeholder={
											'Amount to ' +
											(location?.state?.base === SEND_BASE
												? 'Transfer'
												: 'Receive') +
											' Lbs/Day'
										}
									/>
									{formerror.amount_to_transfer && (
										<FormTypography
											error={{ formerror, id: 'amount_to_transfer' }}
											color={
												formerror?.amount_to_transfer?.includes('Warning')
													? '#E87722'
													: '#B00020'
											}
										/>
									)}
								</Grid>

								<Grid item xs={12} md={6}>
									<TextField
										native
										variant="outlined"
										className="text-field"
										value={
											selectedFarm?.currentbase >= 0
												? 'Current Base           ' +
												  dollarUSLocale.format(selectedFarm.currentbase) +
												  ' Lbs/Day'
												: ''
										}
										placeholder="Current Base Lbs/Day"
										disabled
									/>
									<Typography>
										*Does not include open listings and in process transfers.
									</Typography>
								</Grid>

								<Grid item xs={12} md={6}>
									<FormSwitch
										data={{ formdata, setformdata, id: 'split_transfer' }}
										onChange={onChangeSwitch}
										name={switchname}
									/>
								</Grid>
								<Grid item xs={12} md={6}></Grid>

								<Grid item xs={12} md={6}>
									<FormDatepicker
										id="proposedTransferDate"
										disabled={canTextFieldBeDisabled()}
										name="Proposed Transfer Date"
										onChange={handleDateChange}
										value={formdata?.proposed_transfer_date instanceof Date ?  formdata?.proposed_transfer_date: Date.parse(formdata?.proposed_transfer_date)}
										minDate={new Date()}
									/>

									{formerror.proposed_transfer_date ? (
										<FormTypography
											error={{
												formerror: formerror,
												id: 'proposed_transfer_date',
											}}
											color="#B00020"
										/>
									) : null}
								</Grid>

								<Grid item xs={12} md={6}></Grid>
								<Grid item xs={12} md={6}>
									<FormSelect
										data={{ formdata, setformdata, id: 'primary_contact' }}
										disabled={
											!location?.state?.update
												? selectFarmDisabled || !formdata.farm_name
													? selectFarmDisabled
													: false
												: false
										}
										name="Primary Contact"
										onChange={onChangePrimaryContact}
									>
										
										{contacts.map((item, index) => {
											return (
												<option
													key={item.personName + index}
													value={item.personName}
												>
													{item.personName}{' '}
												</option>
											);
										})}
									</FormSelect>
								</Grid>

								<Grid item xs={12} md={6}></Grid>
								<Grid item xs={12} md={6}>
									<FormSelect
										data={{ formdata, setformdata, id: 'phone_number' }}
										disabled={
											!location?.state?.update
												? selectFarmDisabled || !formdata.farm_name
													? selectFarmDisabled
													: false
												: false
										}
										name="Phone Number"
										onChange={onChangePhoneNumber}
									>
										
										{phoneNo.map((item, index) => {
											return (
												<option key={item.value + index} value={item.value}>
													{item.label}{' '}
												</option>
											);
										})}
										<option value="Other">Other</option>
									</FormSelect>
								</Grid>
								<Grid item xs={12} md={6}>
									{formdata.phone_number === 'Other' && (
										<Maskinput
											name="Contact Phone Number"
											onChange={handleOtherPhoneNoChange}
											data={{
												formdata,
												setformdata,
												id: 'other_phone_number',
											}}
										/>
									)}
									{formdata.phone_number === 'Other' &&
										formerror.other_phone_number && (
											<FormTypography
												error={{ formerror, id: 'other_phone_number' }}
												color="#B00020"
											/>
										)}
								</Grid>

								<Grid item xs={12} md={6}>
									<FormSelect
										data={{ formdata, setformdata, id: 'email' }}
										disabled={
											!location?.state?.update
												? selectFarmDisabled || !formdata.farm_name
													? selectFarmDisabled
													: false
												: false
										}
										name="Email"
										onChange={onChangeEmail}
									>
										{email.map((item, index) => {
											return (
												<option key={item.value + index} value={item.value}>
													{item.label}{' '}
												</option>
											);
										})}
										<option value="Other">Other</option>
									</FormSelect>
								</Grid>
								<Grid item xs={12} md={6}>
									{formdata.email === 'Other' && (
										<FormTextField
											disabled={
												!location?.state?.update
													? selectFarmDisabled || !formdata.farm_name
														? selectFarmDisabled
														: false
													: false
											}
											data={{ formdata, setformdata, id: 'other_email' }}
											name="Contact Email"
											placeholder="Contact Email"
										/>
									)}
									{formdata.email === 'Other' && formerror.other_email && (
										<Typography
											style={{
												fontSize: '14px',
												color: '#B00020',
												margin: '0px 0px 0px 0px',
											}}
										>
											{formerror?.other_email}
										</Typography>
									)}
								</Grid>
								<TermAndConditionText texts={termAndConditionText} />
								<Grid item xs={12}>
									<Box className="legal-acceptance-box">
										<FormCheckbox
											className="checkbox"
											disabled={!selectedFarm?.error?.indicator || !location?.state?.update && selectFarmDisabled || !formerror?.amount_to_transfer?.includes('Warning') && formerror?.amount_to_transfer?.length > 1}
											data={{ formdata, setformdata, id: 'accept_term' }}
											name=" By checking this box you are reconfirming acceptance of the Rules of Engagement and Terms & Conditions of the Marketplace."
											onChange={onAcceptChange}
										/>
									</Box>
								</Grid>
							</Grid>
							<FormButtons
								disabled={!success || location?.state?.update && selectFarmDisabled || !selectedFarm?.error?.indicator || !formerror?.amount_to_transfer?.includes('Warning') && formerror?.amount_to_transfer?.length > 1}
								updateListing={location?.state?.update}
								handleCancel={history.goBack}
							/>
						</form>
					</Container>
				)}
			</section>
		</main>
	);
};

export default CreateListCard;
