import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link, useHistory } from 'react-router-dom';
import logoMarketplace from '../../../assets/images/logo-marketplace.svg';


function stringAvatar(currentUser) {
    if (typeof (currentUser) !== 'undefined' && currentUser != null) {
        var nameParts = currentUser.split(' ');
        var namepartLength = nameParts.length;
        var fisrtandLastName = nameParts[0].charAt(0).toUpperCase() + nameParts[namepartLength - 1].charAt(0).toUpperCase();

        return fisrtandLastName
    }
    return null;
}

const navigationsFromProfile = [
    { title: 'Contact Us', url: '/contact-us', icon: 'ag-icon ag-icon-card-group-checked' },
    { title: 'Profile', url: '/profile', icon: 'ag-icon ag-icon-user-solo', subNavigation: null },
    { title: 'Sign Out', url: '/', icon: 'ag-icon ag-icon-sign-out', subNavigation: null },
]

export const GlobalHeader = ({ currentUser, milkShedName }) => {
    const history = useHistory();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const isMobileMenuOpen = Boolean(menuAnchorEl);

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, title) => {
        if (title === 'Sign Out') {

            localStorage.clear();
            history.push('/auth');
        }
    };

    const handleOnClickOnLogo = () => {
		return history.push('/home-page');
	};
    
    //Todo: this needs to replace with components/common/RenderMenu
    const renderProfileMenu = (
        <Menu
            anchorEl={menuAnchorEl}                           
            className="profile-menu"
            keepMounted                      
            open={isMobileMenuOpen}
            onClose={handleMenuClose}            
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            style={{}}
        >            
            {
                navigationsFromProfile.map((nav, index) => {
                    return <ListItemButton
                        key={nav.title + index}
                        className="profile-menu-item"
                        to={nav.url}
                        component={Link}
                        onClick={event => handleMenuItemClick(event, nav.title)}
                    >
                        <i className={nav.icon}></i>
                        <ListItemText primary={nav.title} />
                    </ListItemButton>
                })
            }

        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                color="transparent"
                elevation={0}
                className="global-header"
            >                
                <Toolbar disableGutters={true} className="global-toolbar">
                    <figure className="logo-container" onClick={handleOnClickOnLogo}>
                        <img
                            src={logoMarketplace}
                            alt="Land O'Lakes Marketplace Logo"
                            className="logo"
                        />
                    </figure>
                   
                    <Box className="user-container" onClick={handleMenuOpen}>
                        <Box className="initials" > {stringAvatar(currentUser)}</Box>
                        <Box className="info">
                            <span className="name">{currentUser}</span>
                            <span className="aux">{milkShedName}</span>
                        </Box>
                        
                    </Box>
                    {renderProfileMenu}
                </Toolbar>                
            </AppBar>
            
        </Box>
    );
}