import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  isIOS, isAndroid, isChrome, isIE, isEdge,
  isEdgeChromium, isSafari, isMobileSafari,
  isWindows, isMacOs, isFirefox
} from 'react-device-detect';

import SignInHelp from './components/common/contactUsLogin/SignInHelp';
import ContactUsSignin from './components/common/contactUsLogin/ContactUsSignin';
import ContactUs from './components/common/contactUsLogin/ContactUs';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoute from './ProtectedRoute';
import CreateListCard from './components/CreateListCard';
import PageNotFound from './PageNotFound';
import { HomePage } from './pages/HomePage';
import ManageTransferPage from './pages/ManageTransferPage'
import RulesandEngagement from './pages/RulesandEngagement'
import TermandConditions from './pages/TermandConditions'
import InitiateTransfer from './components/InitiateTransfer/InitiateTransfer';
import RulesOfEngagementSidebarPage from './pages/RulesOfEngagementSidebar';
import ResourcesPage from './pages/ResourcesPage'
import LoginScreen from './pages/LoginScreen';
import Authorize from './pages/Authorize';
import { useHeader } from './hooks/useHeader';
import { useDrawerSideBar } from './hooks/useDrawerSideBar';
import TermsAndConditionsSidebar from './pages/TermsAndConditionsSidebar';
import FAQSideBar from './pages/FAQSideBar';
import ContactUsSuccess from './pages/ContactUsSuccess';
import SuccessAndManageTransfer from './pages/SuccessAndManageTransfer';
import logoMarketplace from '../src/assets/images/logo-marketplace.svg';
import iconTurnDevice from '../src/assets/images/icon-turn-device.png';
import IdleTimerContainer from './components/common/IdleTimer/IdleTimerContainer';
import ContactUsSuccessSignin from './pages/ContactUsSuccessSignin';
import ErrorBoundary from './hoc/error_boundary/ErrorBoundary';


const getDeviceClasses = () => {
	let currentUri = window.location.pathname;
	currentUri = currentUri.replace('/', 'page-');
	
	let classes = 'App app-container ' + currentUri;

	isIOS && (classes += ' device-ios');
	isAndroid && (classes += ' device-android');
	isWindows && (classes += ' device-windows');
	isMacOs && (classes += ' device-mac');
	isChrome && (classes += ' browser-chrome');
	isMobileSafari && (classes += ' browser-mobile-safari');
	isSafari && !isMobileSafari && (classes += ' browser-safari');
	isEdgeChromium && (classes += ' browser-edge-chromium');
	isEdge && !isEdgeChromium && (classes += ' browser-edge');
	isIE && (classes += ' browser-ie');
	isFirefox && (classes += ' browser-firefox');
	
	return classes;
};

const AppRoute = () => {
	const header = useHeader();
	const drawerSideBar = useDrawerSideBar();
	return (
		<div className={getDeviceClasses()}>
			<div className="notification-turn-mobile-portrait">				
				<p>
				<img
					src={iconTurnDevice}
					alt="ignore"
					className="notification-turn-device"
				/>
					For the best experience, turn your device to portrait.</p>
			</div>
			{header}

			<div className="main-section">
				{drawerSideBar}
				<img
					src={logoMarketplace}
					alt="Land O'Lakes Marketplace Logo"
					className="mobile-logo"
				/>
				<Switch>
					<ProtectedRoute path="/home-page" component={HomePage} />
					<Route exact path="/auth" component={LoginScreen} />
					<Route exact path="/authorize" component={Authorize} />
					<Route path="/create-listing-Receive-Base" component={CreateListCard} />

					<Route path="/create-listing-Send-Base" component={CreateListCard} />
					<Route path="/manage-transfer" component={ManageTransferPage} />
					<Route path="/term-and-condition" component={TermandConditions} />
					<Route
						path="/terms-and-conditions-sidebar"
						component={TermsAndConditionsSidebar}
					/>
					<Route path="/faqs-sidebar" component={FAQSideBar} />
					<Route path="/rules-engagement" component={RulesandEngagement} />
					<Route path="/initiate-transfer" component={InitiateTransfer} />
					<Route path="/sign-in-help" component={SignInHelp} />
					<Route path="/contact-us-signin" component={ContactUsSignin} />
					<Route path="/contact-us" component={ContactUs} />
					<Route path="/profile" component={ProfilePage} />
					<Route
						path="/rules-of-engagement-sidebar"
						component={RulesOfEngagementSidebarPage}
					/>
					<Route path="/resources" component={ResourcesPage} />
					<Route path="/message-sent" component={ContactUsSuccess} />
					<Route path="/message-sent-signin" component={ContactUsSuccessSignin} />
					<Route
						path="/success-and-manage-transfer"
						component={SuccessAndManageTransfer}
					/>
					<Route path="/error-boundary" component={ErrorBoundary}/>
					<Route path="/">
						<Redirect to="/auth" />
					</Route>
					<Route component={PageNotFound} />
				</Switch>
			</div>
			<IdleTimerContainer></IdleTimerContainer>
		</div>
	);
};

export default AppRoute;