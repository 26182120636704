import React from "react";

import Grid from '@material-ui/core/Grid';
import { Box, Button, Link } from '@material-ui/core';


function TermAndConditionText({texts}) {

	return (
		<Grid item xs={12}>
			<p className="legal-text">
                { texts.map((text) => {
                    if(text.hasLink){
                        return <Link key={text?.key} onClick={text.handleOnLinkClick}>{text.text}</Link>
                    } else {
                        return text.text;
                    }
                })
                }
			</p>
		</Grid>
	);
}

export default TermAndConditionText;
